import React, { useState } from 'react';
import { CardBody, Card, Col, Row, Label, Button, Form, Modal } from "reactstrap";
import apiGlobal from '../../../global/api.global';
import { Field, FieldArray, FieldProps, Formik } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { useSelector } from 'react-redux';
import { RootState } from '../../..';
import { loadMachineryOptions, loadVesselMachineries } from '../../../VesselMaster/vesselMaster.hooks';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { queryClient } from 'react-query/queryClient';

const MachineryMaster = () => {
    /** State variables */
    const [machinery, setMachinery] = useState(0);
    const [modal_xlarge, setmodal_xlarge] = useState(false);
    const { recordState, recordID } = useSelector((state: any) => state.Generic);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    /** State variables end */

    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_xlarge() {
        setmodal_xlarge(!modal_xlarge);
        removeBodyCss();
    }

    /** Queries */
    /** Load Vessel Machinary */
    const { data: vesselMachineries, isLoading: vesselMachineriesLoading, isError: vesselMachineriesError } = useQuery(
        [queryKeyes.vessel.Machinary.key, VesselID],
        async () => {
            return await loadVesselMachineries(VesselID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Machinery Master's formik object */
    const MachineryMasterFormik = {
        initialValues: {
            machines: [{
                vessel_machinery_name: '',
                machinery_name: '',
                vessel_name: VesselID,
            }]
        },
        validationSchema: Yup.object().shape({
            machines: Yup.array(
                Yup.object({
                    vessel_machinery_name: Yup.string().matches(/^[a-zA-Z0-9/\s]{1,50}$/, "Please enter upto 50 alphabets only")
                        .required(commonValidationMessages.required),
                })
            )
        })
    }

    const deleteVesselMachinery = (id: number) => {
        apiGlobal.delete(`/vessel_machinery_list/${id}/`,).then(res => {
            if (res.status === 200 || res.status === 204) {
                successToast("Data deleted successfully!");
                queryClient.invalidateQueries(queryKeyes.vessel.Machinary.key);
            }
        }).catch(err => {
            if (errResponse.includes(err.response.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }

    return (
        <>
            <React.Fragment>
                <div className='ele_row'>
                    <h4 className='mb-3 navbar_menu'>Vessel Machinery List</h4>
                    <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-3"
                        data-toggle="modal"
                        data-target=".bs-example-modal-xl"
                        onClick={() => {
                            tog_xlarge();
                        }}
                    >
                        <i className="dripicons-plus font-size-16 align-middle me-2"></i>
                        Add New
                    </button>
                    <Modal
                        size="xl"
                        isOpen={modal_xlarge}
                        toggle={() => {
                            tog_xlarge();
                        }}
                    >
                        <div className="modal-header">
                            <h5
                                className="modal-title mt-0"
                                id="myExtraLargeModalLabel"
                            >
                                Add New
                            </h5>
                            <button
                                onClick={() => {
                                    setmodal_xlarge(false);
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Card>
                                <CardBody>
                                    {vesselMachineriesLoading && <Loading message='Loading required data!' />}
                                    {vesselMachineriesError && <ErrorComponent message='Unable to load required data!' />}
                                    {!vesselMachineriesLoading && !vesselMachineriesError &&
                                        <Formik
                                            initialValues={MachineryMasterFormik.initialValues}
                                            validationSchema={MachineryMasterFormik.validationSchema}
                                            onSubmit={(values, actions) => {
                                                actions.setSubmitting(false);
                                                /** Post submit actions based on response */
                                                const handleResponse = (response: any) => {
                                                    if (response.status === 201 || response.status === 200) {
                                                        successToast("Data saved successfully!");
                                                        queryClient.invalidateQueries(queryKeyes.vessel.Machinary.key);
                                                        setmodal_xlarge(false);
                                                    }
                                                }
                                                values?.machines?.map((machine: any) => {
                                                    machine.machinery_name = machinery
                                                    return machine;
                                                })
                                                apiGlobal.post(`/vessel_machinery_list/`, values?.machines).then(res => {
                                                    handleResponse(res);
                                                }).catch(err => {
                                                    if (errResponse.includes(err.response.status)) {
                                                        errorToast("Internal error occured, please contact the admin");
                                                    }
                                                });
                                            }}
                                        >
                                            {props => (
                                                <Form onSubmit={props.handleSubmit} autoComplete='false' noValidate>
                                                    <Row className='mb-3'>
                                                        <Col lg={4}>
                                                            <Label className='asteric mb-0'>Select machinery</Label>
                                                            <Field name={`machines.${0}.machinery_name`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name="machinery_name"
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={loadMachineryOptions}
                                                                        getOptionLabel={(e: any) => e.machinery_name}
                                                                        getOptionValue={e => e.id}
                                                                        onChange={(e: any) => {
                                                                            setMachinery(e?.id);
                                                                            props?.values.machines.forEach((machine: any, index: number) => {
                                                                                form.setFieldValue(`machines.${index}.machinery_name`, e?.id);
                                                                            })
                                                                        }}
                                                                        className='select-height'
                                                                        menuPortalTarget={document.querySelector('.MuiDialog-root')}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Col>
                                                    </Row>
                                                    <FieldArray name="machines">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {props?.values && props?.values?.machines?.map((value: any, index: number) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <Row className="mb-3">
                                                                                <Col lg={4}>
                                                                                    <Label className='asteric mb-0'>Vessel machinery name</Label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        id={`machines.${index}.vessel_machinery_name`}
                                                                                        name={`machines.${index}.vessel_machinery_name`}
                                                                                        className='form-control'
                                                                                    />
                                                                                </Col>
                                                                                <Col lg={1}>
                                                                                    <div className='ele_row1'>
                                                                                        {index === (props?.values?.machines.length - 1) &&
                                                                                            <Button type="button" className="btn ml-1 mt-3" color='primary'>
                                                                                                <i className='dripicons-plus icon_s18 navbar_menu'
                                                                                                    onClick={(actions) => push({
                                                                                                        vessel_machinery_name: '',
                                                                                                        machinery_name: '',
                                                                                                        vessel_name: VesselID,
                                                                                                    })} />
                                                                                            </Button>
                                                                                        }
                                                                                        {props?.values?.machines?.length === 1 ? null :
                                                                                            <button type="button" className="btn ml-1 mt-3">
                                                                                                <i className='dripicons-trash icon_s18'
                                                                                                    onClick={(actions) => remove(index)} />
                                                                                            </button>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                    <Row>
                                                        <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                                            <Button type="submit" color="primary" className='btn_size4_5_cstm'>Save</Button>
                                                            <Button type="reset" color="danger" className='btn_size4_5_cstm'>Reset</Button>
                                                        </div>
                                                    </Row>
                                                    <FormValuesDebug values={[props?.values, props?.errors, MachineryMasterFormik.initialValues]} />
                                                </Form>
                                            )}
                                        </Formik>
                                    }
                                </CardBody>
                            </Card>
                        </div>
                    </Modal>
                </div>
                <div className="table-responsive mb-5">
                    <table className="table">
                        <thead className="table-light">
                            <tr>
                                <th className='p-2'>Sr. No.</th>
                                <th className='p-2'>Vessel machinery name</th>
                                <th className='p-2'>Machinery</th>
                                <th className='p-2'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vesselMachineries ? vesselMachineries?.sort((a: any, b: any) => a.machinery_name - b.machinery_name)
                                .map((vesselMachinery: any, index: number) => {
                                    return (
                                        <tr key={vesselMachinery.id}>
                                            <td className='p-2 ps-2'>{index + 1}</td>
                                            <td className='p-2'>{vesselMachinery.vessel_machinery_name}</td>
                                            <td className='p-2'>{vesselMachinery.machinery}</td>
                                            <td className='p-2'><i id={`deleteVesselMachinery${index}`} className='dripicons-trash icon_s18'
                                                onClick={() => deleteVesselMachinery(vesselMachinery.id)} />
                                            </td>
                                        </tr>
                                    )
                                })
                                : <tr>
                                    <td colSpan={4} className='text-center'>Please add machinery to this vessel</td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
            {recordState === 'EDIT' && <MachineryMaster key={`form-${recordID}`} />}
        </>
    )
}

export default MachineryMaster