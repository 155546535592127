import Loading from 'Components/Loading';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { loadPilotArrivalDetailsObject, loadPilotDepartureDetailsObject } from 'VesselMaster/vesselMaster.hooks';
import { Field, FieldProps, Formik } from 'formik';
import apiGlobal from 'global/api.global';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Row, Col, Input, Label, Button, Form, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { EventPrecedence, ReportingTypeConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';

interface PilotDetailsComponentType {
    record: any,
    toggleAddTab: any,
    activeAddTab: number,
    toggleTab: any,
    activeTab: number,
    VesselID: number,
    VoyageID: number,
    ReportID: number
}

const PilotDetailsComponent = ({
    record,
    toggleAddTab,
    activeAddTab,
    toggleTab,
    activeTab,
    VesselID,
    VoyageID,
    ReportID
}: PilotDetailsComponentType) => {
    /** State varibles */
    const [formikKey, setFormikKey] = useState(0);
    /** State variables end */

    /** Queries */
    /** Pilot Arrival Details Object used for edit */
    const { data: PilotArrivalDetailsObject, isLoading: PilotArrivalDetailsObjectLoading, isError: PilotArrivalDetailsObjectError } = useQuery(
        [queryKeyes.vessel.PilotArrivalDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadPilotArrivalDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Pilot Departure Details Object used for edit */
    const { data: PilotDepartureDetailsObject, isLoading: PilotDepartureDetailsObjectLoading, isError: PilotDepartureDetailsObjectError } = useQuery(
        [queryKeyes.vessel.PilotDepartureDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadPilotDepartureDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Assign values to initial object of Pilot Details' object */
    const getInitialPilotDetailsValues = () => {
        if (PilotArrivalDetailsObject && PilotArrivalDetailsObject[0]?.id > 0) {
            return PilotArrivalDetailsObject[0];
        } else {
            return ({
                pilot_onboard: null,
                pilot_disembarked: null,
                etb: null,
                first_line_ashore: null,
                all_made_fast: null,
                nor: null,
                vessel_name: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
        }
    }

    /** Assign values to initial object of Pilot Details' object */
    const getInitialPilotDetailsDepartureValues = () => {
        let obj: any;
        if (PilotDepartureDetailsObject && PilotDepartureDetailsObject[0]?.id > 0) {
            return PilotDepartureDetailsObject[0];
        } else {
            obj = ({
                is_this_departure_report: false,
                water_density: 0,
                gm: 0,
                shearing_force: 0,
                shearing_force_frame: 0,
                bending_moment: 0,
                bending_moment_frame: 0,
                torsional_moment: 0,
                all_cast_off: null,
                vessel_name: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
            return obj;
        }
    }

    /** UseEffect */
    useEffect(() => {
        PilotDetailsFormik.initialValues.arrivalDetails = getInitialPilotDetailsValues();
        PilotDetailsFormik.initialValues.departureDetails = getInitialPilotDetailsDepartureValues();
    }, [PilotArrivalDetailsObject, PilotDepartureDetailsObject])
    useEffect(() => {
        setFormikKey(formikKey + 1);
    }, [PilotArrivalDetailsObject, PilotDepartureDetailsObject])
    /** UseEffect end */

    /** Pilot Details' formik object */
    const PilotDetailsFormik = {
        initialValues: {
            arrivalDetails: getInitialPilotDetailsValues(),
            departureDetails: getInitialPilotDetailsDepartureValues()
        }
    }
    return (
        <Card className='p-0 mb-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Pilot Details</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(PilotArrivalDetailsObjectLoading || PilotDepartureDetailsObjectLoading) && <Loading message='Loading required data!' />}
            {PilotArrivalDetailsObjectError && getInitialPilotDetailsValues()}
            {PilotDepartureDetailsObjectError && getInitialPilotDetailsDepartureValues()}
            {!PilotArrivalDetailsObjectLoading &&
                <Formik
                    key={formikKey}
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                            }
                        }
                        if (PilotArrivalDetailsObject && PilotArrivalDetailsObject[0]?.id > 0) {
                            apiGlobal.put(`/lfonds_pilot_details/${values?.arrivalDetails?.id}/`, values?.arrivalDetails).then(res => {
                                handleResponse(res)
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                        } else {
                            apiGlobal.post(`/lfonds_pilot_details/`, values?.arrivalDetails).then(res => {
                                handleResponse(res)
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                        }
                        if (PilotDepartureDetailsObject && PilotDepartureDetailsObject[0]?.id > 0) {
                            apiGlobal.put(`/lfonds_pilot_departure_detail/${values?.departureDetails?.id}/`, values?.departureDetails).then(res => {
                                handleResponse(res)
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                        } else {
                            apiGlobal.post(`/lfonds_pilot_departure_detail/`, values?.departureDetails).then(res => {
                                handleResponse(res)
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                        }
                    }}
                    initialValues={PilotDetailsFormik.initialValues}
                >
                    {({ values, errors, handleSubmit, handleChange }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='px-2 py-0 mt-2'>
                                <Row className='mb-2'>
                                    <Col lg={3}>
                                        <Label className='mb-0'>Pilot onboard(UTC)</Label>
                                        <Field name="arrivalDetails.pilot_onboard">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="datetime-local"
                                                    max="9999-12-31T23:59"
                                                    className="form-control mt-0 text-uppercase"
                                                    id={field.name}
                                                    onChange={(e: any) => handleChange(e)}
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col lg={3}>
                                        <Label className='mb-0'>Pilot disembarked(UTC)</Label>
                                        <Field name="arrivalDetails.pilot_disembarked">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="datetime-local"
                                                    max="9999-12-31T23:59"
                                                    className="form-control mt-0 text-uppercase"
                                                    id={field.name}
                                                    onChange={(e: any) => handleChange(e)}
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col lg={3}>
                                        <Label className='mb-0'>ETB(UTC)</Label>
                                        <Field name="arrivalDetails.etb">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="datetime-local"
                                                    max="9999-12-31T23:59"
                                                    className="form-control mt-0 text-uppercase"
                                                    id={field.name}
                                                    onChange={(e: any) => handleChange(e)}
                                                    disabled={record && ((record?.reporting_type === ReportingTypeConstant.INPORT ||
                                                        record?.event_precedence_id === EventPrecedence.END_OF_SEA_PASSAGE)
                                                        && record?.event_precedence_id !== EventPrecedence.AT_BERTH ? false : true)}
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col lg={3}>
                                        <Label className='mb-0'>First line ashore(UTC)</Label>
                                        <Field name="arrivalDetails.first_line_ashore">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="time"
                                                    className="form-control"
                                                    id={field.name}
                                                    onChange={(e: any) => handleChange(e)}
                                                />
                                            )}
                                        </Field>
                                        {errors &&
                                            <Label className='error'>{errors?.first_line_ashore}</Label>
                                        }
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col lg={3}>
                                        <Label className='mb-0'>All made fast(UTC)</Label>
                                        <Field name="arrivalDetails.all_made_fast">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="time"
                                                    className="form-control"
                                                    id={field.name}
                                                    onChange={(e: any) => handleChange(e)}
                                                />
                                            )}
                                        </Field>
                                        {errors &&
                                            <Label className='error'>{errors?.all_made_fast}</Label>
                                        }
                                    </Col>
                                    <Col lg={3}>
                                        <Label className='mb-0'>NOR(UTC)</Label>
                                        <Field name="arrivalDetails.nor">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="time"
                                                    className="form-control"
                                                    id={field.name}
                                                    onChange={(e: any) => handleChange(e)}
                                                />
                                            )}
                                        </Field>
                                        {errors &&
                                            <Label className='error'>{errors?.nor}</Label>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>
                                        <Label className="mb-0 w-5 mt-3">
                                            Is this a departure from port report?
                                        </Label>
                                    </Col>
                                    <Col>
                                        <Field name={'departureDetails.is_this_departure_report'}>
                                            {({ field, form }: FieldProps) => (
                                                <div className="square-switch sqswitch mt-3">
                                                    <Input
                                                        type="checkbox"
                                                        switch="none"
                                                        name={field.name}
                                                        id="is_this_departure_report"
                                                        onChange={handleChange}
                                                        defaultChecked={values?.departureDetails?.is_this_departure_report}
                                                    />
                                                    <Label
                                                        htmlFor="is_this_departure_report"
                                                        data-on-label='Yes'
                                                        data-off-label='No'>
                                                    </Label>
                                                </div>
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                                {values && values?.departureDetails?.is_this_departure_report === true &&
                                    <>
                                        <Row className='mb-2'>
                                            <Col lg={3}>
                                                <Label className='mb-0'>Water density</Label>
                                                <div className='input-group'>
                                                    <Field
                                                        type="text"
                                                        id="water_density"
                                                        name="departureDetails.water_density"
                                                        className="form-control"
                                                    />
                                                    <div className='input-group-text'>gm/ml</div>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <Label className='mb-0'>GM</Label>
                                                <div className='input-group'>
                                                    <Field
                                                        type="text"
                                                        id="gm"
                                                        name="departureDetails.gm"
                                                        className="form-control"
                                                    />
                                                    <div className='input-group-text'>m</div>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <Label className='mb-0'>Shearing force</Label>
                                                <div className='ele_row1'>
                                                    <div className='input-group'>
                                                        <Field
                                                            type="text"
                                                            id="shearing_force"
                                                            name="departureDetails.shearing_force"
                                                            className="form-control"
                                                        />
                                                        <div className='input-group-text'>%</div>
                                                    </div>
                                                    <Label className='mt-2 ml-1 mr-1'>@Frame </Label>
                                                    <Field
                                                        type="text"
                                                        id="shearing_force_frame"
                                                        name="departureDetails.shearing_force_frame"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <Label className='mb-0'>Bending moment</Label>
                                                <div className='ele_row1'>
                                                    <div className='input-group'>
                                                        <Field
                                                            type="text"
                                                            id="bending_moment"
                                                            name="departureDetails.bending_moment"
                                                            className="form-control"
                                                        />
                                                        <div className='input-group-text'>%</div>
                                                    </div>
                                                    <Label className='mt-2 ml-1 mr-1'>@Frame </Label>
                                                    <Field
                                                        type="text"
                                                        id="bending_moment_frame"
                                                        name="departureDetails.bending_moment_frame"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <Label className='mb-0'>Torsional moment</Label>
                                                <div className='input-group'>
                                                    <Field
                                                        type="text"
                                                        id="torsional_moment"
                                                        name="departureDetails.torsional_moment"
                                                        className="form-control"
                                                    />
                                                    <div className='input-group-text'>%</div>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <Label className='mb-0'>All cast off</Label>
                                                <Field name="departureDetails.all_cast_off">
                                                    {({ field }: FieldProps) => (
                                                        <Input
                                                            name={field.name}
                                                            type="time"
                                                            className="form-control"
                                                            id={field.name}
                                                            onChange={(e: any) => handleChange(e)}
                                                        />
                                                    )}
                                                </Field>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </CardBody>
                            <CardFooter className='p-2'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                            toggleAddTab(activeAddTab + 1);
                                        }}>Next <i className="bx bx-chevron-right" /></Button>
                                        <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                            (record && record?.event_precedence_id === EventPrecedence.DROP_ANCHOR) ||
                                                (record && record?.event_precedence_id === EventPrecedence.HEAVE_ANCHOR) ?
                                                toggleAddTab(activeAddTab - 1) :
                                                toggleTab(activeTab - 1)
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, PilotDetailsFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </Card>
    )
}

export default PilotDetailsComponent