import { Field } from 'formik';
import React from 'react'
import { Button, Col, Modal, Row } from 'reactstrap';
import "../global/GlobalCSS.css"

const SpOpFuelBatchConsumption = ({
    state,
    setState,
    values,
    id,
    machinery,
    totalConsumption,
    error,
    array
}: any) => {
    const handlePopUpState = () => {
        setState(!state);
    }
    return (
        <Modal
            size='md'
            isOpen={state}
            toggle={() => {
                handlePopUpState();
            }}
            backdrop={"static"}
            id={id}
        >
            <div className="modal-header">
                <strong className="modal-title" id={id}>{machinery[0]?.vessel_machinery_fc_group_name} fuel consumption by batch</strong>
            </div>
            <div className="modal-body">
                <Row>
                    <Row className='mb-2'>
                        <div className='ele_row1'><p style={{ fontSize: 12.5, marginBottom: 1 }}>Total fuel consumption:&nbsp;</p><strong style={{ fontSize: 12.5, marginBottom: 1 }}>{totalConsumption} mt</strong></div>
                    </Row>
                    {array.filter((item: any) => item.vessel_machinery_fc_group === machinery[0]?.vessel_machinery_fc_group).map((batch: any, index: number) => {
                        return (
                            <div key={index}>
                                <strong style={{ fontSize: 12, marginBottom: 1 }}>{batch?.fuel_consumption_batch}</strong>
                                <div className='input-group mb-2'>
                                    <Field
                                        type='text'
                                        name={`fuelSubTypeConsumption.${values?.fuelSubTypeConsumption.indexOf(batch)}.fuel_consumption`}
                                        className='form-control  max-width-7 text-right'
                                    />
                                    <div className='input-group-text'>mt</div>
                                </div>
                            </div>
                        )
                    })}
                </Row>
                {error && error?.fuelSubTypeConsumption &&
                    <Row>
                        <p className='error p-0'>{error?.fuelSubTypeConsumption?.fuel_consumption}</p>
                    </Row>
                }
                <Row>
                    <Col lg={{ size: 2, offset: 10 }}>
                        <Button type='button' className='btn justify_right' color='primary' onClick={() => {
                            if (Object.keys(error).length === 0) {
                                setState(false);
                            }
                        }}>Save</Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default SpOpFuelBatchConsumption