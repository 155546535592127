import React, { useState, useEffect } from 'react'
import { VesselMachineryList } from '../VesselConfiguration.model';
import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { Button } from 'reactstrap';
import { successToast } from '../../../Components/Toasts';

export default function CargoCooling() {
    const [cargoCoolingMachineries, setCargoCoolingMachineries] = useState<Array<VesselMachineryList>>([]);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [cargoCoolingKwhMeter] = useState<Array<any>>([]);

    useEffect(() => {
        const loadCargoCoolingMachineries = async () => {
            try {
                const response = await apiGlobal.get(`special_operation_energy_setting/cargo_cooling_true_for_vessel/?vessel_id=${VesselID}`);
                setCargoCoolingMachineries(response.data);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        }; loadCargoCoolingMachineries();
    }, [VesselID]);

    const handleCargoCoolingCheckbox = (e: any, machine: any, idx: number) => {
        machine.cargo_cooling_kwh_meter = e.target.checked;
        cargoCoolingKwhMeter.splice(idx, 1);
        cargoCoolingKwhMeter.splice(idx, 0, machine)
    }

    const CoolingKwhMetersSubmit = async (e: any) => {
        cargoCoolingKwhMeter.map(async (meter: any) => {
            await apiGlobal.put(`/special_operation_energy_setting/${meter.id}/`, meter).then(res => {
                successToast("Data saved successfully!");
            })
                .catch(err => {
                    console.log(err);
                });
        })
    }

    return (
        <>
            <div className="table-responsive mb-5">
                <table className="table">
                    <thead className="table-light">
                        <tr>
                            <th>Sr. No.</th>
                            <th>Machinery name</th>
                            <th>Is kWh meter fitted on power pack/electrical motor panel?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cargoCoolingMachineries &&
                            cargoCoolingMachineries.map((vesselMachinery: any, index: number) => {
                                return (
                                    <tr key={vesselMachinery.id}>
                                        <td>{index + 1}</td>
                                        <td>{vesselMachinery.vessel_machinery_ec_group_name}</td>
                                        <td>
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="chckbx1"
                                                    onChange={(e: any) => handleCargoCoolingCheckbox(e, vesselMachinery, index)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                <div className="d-flex flex-wrap btn_Margin">
                    <Button type="button" color='primary' className='pos-end' onClick={CoolingKwhMetersSubmit}>Save</Button>
                </div>
            </div>
        </>
    )
}