import Loading from 'Components/Loading';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { loadTugsUsageObject } from 'VesselMaster/vesselMaster.hooks';
import { Field, Formik } from 'formik';
import apiGlobal from 'global/api.global';
import React from 'react';
import { useQuery } from 'react-query';
import { Row, Col, Form, Label, Button, CardHeader, Card, CardBody, CardFooter } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';

interface TugsUsageComponentType {
    activeAddTab: number,
    toggleAddTab: any,
    VesselID: number,
    VoyageID: number,
    ReportID: number
}

const TugsUsageComponent = ({
    activeAddTab,
    toggleAddTab,
    VesselID,
    VoyageID,
    ReportID
}: TugsUsageComponentType) => {
    /** Queries */
    /** Tugs Used object used for edit */
    const { data: TugsUsageObject, isLoading: TugsUsageObjectLoading, isError: TugsUsageObjectError } = useQuery(
        [queryKeyes.vessel.TugsUsageObject.key, VesselID, ReportID],
        async () => {
            return await loadTugsUsageObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Assign values to initial object of Tugs Usage */
    const getInitialTugsUsageValues = () => {
        if (TugsUsageObject && TugsUsageObject[0]?.id > 0) {
            return TugsUsageObject[0];
        } else {
            return ({
                number_of_tugs: 0,
                remarks: '',
                vessel_name: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
        }
    }

    /** Tugs Usage formik object */
    const TugsUsageFormik = {
        initialValues: getInitialTugsUsageValues(),
    }
    return (
        <Card className='p-0 mb-0'>
            <CardHeader className='p-2'>

                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Tugs Usage</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {TugsUsageObjectLoading && <Loading message='Loading required data!' />}
            {TugsUsageObjectError && getInitialTugsUsageValues()}
            {!TugsUsageObjectLoading &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                            }
                        }
                        if (TugsUsageObject && TugsUsageObject[0]?.id > 0) {
                            apiGlobal.put(`/lfonds_tug_usage/${values?.id}/`, values).then(res => {
                                handleResponse(res);
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                        } else {
                            apiGlobal.post(`/lfonds_tug_usage/`, values).then(res => {
                                handleResponse(res);
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                        }
                    }}
                    initialValues={TugsUsageFormik.initialValues}
                >
                    {({ values, errors, handleSubmit, handleChange }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='px-2 py-0 mt-2'>
                                <Row className='mb-3'>
                                    <Col lg={4}>
                                        <Label className='mb-0'>Number of tugs used</Label>
                                        <Field
                                            type="text"
                                            name="number_of_tugs"
                                            className="form-control"
                                        />
                                    </Col>
                                    <Col lg={4}>
                                        <Label className='mb-0'>Remarks</Label>
                                        <Field
                                            as="textarea"
                                            name="remarks"
                                            className="form-control"
                                            rows={3}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                            toggleAddTab(activeAddTab + 1);
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                            toggleAddTab(activeAddTab - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, TugsUsageFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </Card>
    )
}

export default TugsUsageComponent