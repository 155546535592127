import ErrorComponent from 'Components/ErrorComponent'
import Loading from 'Components/Loading'
import { loadTimePeriod } from 'VesselMaster/vesselMaster.hooks'
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Input } from 'reactstrap'
import { queryKeyes } from 'shared/queryKeys'

interface FourHrTableComponentType {
    vesselMachinery: any,
    lastRecord: any,
    record: any,
    tableHeader: string,
    fieldName: string,
    handleChange: any,
    valueName: string,
    valueSubName: any[],
    showMachineryName: boolean,
    energyConsumedField: string
}

const FourHrTableComponent = ({
    vesselMachinery,
    lastRecord,
    record,
    tableHeader,
    fieldName,
    handleChange,
    valueName,
    valueSubName,
    showMachineryName,
    energyConsumedField
}: FourHrTableComponentType) => {

    /** State variables */
    const [timePeriodId, setTimePeriodId] = useState<Array<any>>([]);
    const { values }: { values: any } = useFormikContext();
    /** State variables end */

    /** Queries */
    /** Time periods for 4 hours table */
    const { data: TimePeriod, isLoading: TimePeriodLoading, isError: TimePeriodError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.TimePeriodObject.key],
            async () => {
                return await loadTimePeriod();
            },
            { staleTime: Infinity }
        )
    /** Queries end */

    /** useEffect */
    /** Show applicable time periods */
    useEffect(() => {
        let array = [...timePeriodId];
        TimePeriod && TimePeriod?.map((time: any) => {
            if (lastRecord && lastRecord.reporting_time_utc && time.period) {
                const lastRecordTime = lastRecord.reporting_time_utc.toString().substring(11, 13)
                const currRecordTime = record.reporting_time_utc.toString().substring(11, 13)
                const periodStart: number = time.period.toString().substring(0, 2);
                const periodEnd: number = time.period.toString().substring(5, 7);
                if (timePeriodId.length <= 6) {
                    if (time.id <= 3) {
                        if (lastRecordTime <= periodStart && currRecordTime <= periodEnd) {
                            if (array.includes(time.id) === false) {
                                array.push(time.id);
                            }
                        }
                    } else {
                        if (currRecordTime >= periodEnd) {
                            if (array.includes(time.id) === false) {
                                array.push(time.id);
                            }
                        }
                    }
                    setTimePeriodId(array);
                }
            }
            return time;
        })
    }, [lastRecord, record, TimePeriod])
    /** useEffect end */

    /** Calculate W */
    const calculateW = (form: any, values: any, name: any, e: any, index: number) => {
        if (name === fieldName) {
            form.setFieldValue(`${valueName}.${index}.w`,
                (1.73205080757 *
                    e.target.value *
                    valueSubName[index]?.v *
                    valueSubName[index]?.i *
                    valueSubName[index]?.pf *
                    0.001).toFixed(2));
        } else if (name === 'v') {
            form.setFieldValue(`${valueName}.${index}.w`,
                (1.73205080757 *
                    valueSubName[index][fieldName] *
                    e.target.value *
                    valueSubName[index]?.i *
                    valueSubName[index]?.pf * 0.001).toFixed(2));
        } else if (name === 'i') {
            form.setFieldValue(`${valueName}.${index}.w`,
                (1.73205080757 *
                    valueSubName[index][fieldName] *
                    valueSubName[index]?.v *
                    e.target.value *
                    valueSubName[index]?.pf *
                    0.001).toFixed(2));
        } else if (name === 'pf') {
            form.setFieldValue(`${valueName}.${index}.w`,
                (1.73205080757 *
                    valueSubName[index][fieldName] *
                    valueSubName[index]?.v *
                    valueSubName[index]?.i *
                    e.target.value *
                    0.001).toFixed(2));
        }
    }

    /** Calculate sum of w */
    const loadwsum = (values: any[], field: string, form: any) => {
        let sum = 0;
        values.map((val: any) => {
            sum += parseFloat(val?.w ?? 0);
            return '';
        })
        form.setFieldValue(field, sum.toFixed(2));
    }

    return (
        <>
            {TimePeriodLoading && <Loading message='Loading required data!' />}
            {TimePeriodError && <ErrorComponent message='Unable to load required data!' />}
            {showMachineryName === true &&
                <h6 className='mb-3 mt-3 ps-3'>{vesselMachinery.vessel_machinery_ec_group_name}</h6>
            }
            <div className="table-responsive p-0 ">
                <table className="table mb-0">
                    <thead className="table-light">
                        <tr>
                            <th className="text-center">Period</th>
                            <th className="asteric text-center">{tableHeader}</th>
                            <th className="asteric text-center">V</th>
                            <th className="asteric text-center">I</th>
                            <th className="asteric text-center">Pf</th>
                            <th className="asteric text-center">W=kWh</th>
                        </tr>
                    </thead>
                    <FieldArray name={`${valueName}`}>
                        {() => (
                            <>
                                {valueSubName && valueSubName !== undefined && valueSubName?.filter((item: any) => timePeriodId?.includes(item?.period) &&
                                    item.vessel_machinery_name === vesselMachinery.vessel_machinery_ec_group).map((time: any, index: number) => {
                                        return (
                                            <tbody key={index}>
                                                <tr>
                                                    <td className='align-middle p-2 text-center'>{time.time_period}</td>
                                                    <td className='text-center'>
                                                        <div className="d-inline-block">
                                                            <div className="input-group">
                                                                <Field name={`${valueName}.${valueSubName.indexOf(time)}.${fieldName}`}>
                                                                    {({ field, form }: FieldProps) => (
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control text-right max-width-7"
                                                                            id={time.id}
                                                                            name={field.name}
                                                                            onBlur={(e: any) => {
                                                                                handleChange(e);
                                                                                calculateW(form, values, fieldName, e, valueSubName.indexOf(time));
                                                                            }}
                                                                            defaultValue={valueSubName[valueSubName.indexOf(time)][fieldName]}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <div className="input-group-text">hr</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        <div className="d-inline-block">
                                                            <Field name={`${valueName}.${valueSubName.indexOf(time)}.v`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control text-right max-width-7"
                                                                        id={time.id}
                                                                        name={field.name}
                                                                        onBlur={(e: any) => {
                                                                            handleChange(e);
                                                                            calculateW(form, values, 'v', e, valueSubName.indexOf(time));
                                                                        }}
                                                                        defaultValue={valueSubName[valueSubName.indexOf(time)].v}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        <div className="d-inline-block">
                                                            <Field name={`${valueName}.${valueSubName.indexOf(time)}.i`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control text-right max-width-7"
                                                                        id={time.id}
                                                                        name={field.name}
                                                                        onBlur={(e: any) => {
                                                                            handleChange(e);
                                                                            calculateW(form, values, 'i', e, valueSubName.indexOf(time));
                                                                        }}
                                                                        defaultValue={valueSubName[valueSubName.indexOf(time)].i}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-inline-block">
                                                            <Field name={`${valueName}.${valueSubName.indexOf(time)}.pf`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control text-right max-width-7"
                                                                        id={time.id}
                                                                        name={field.name}
                                                                        onChange={(e: any) => {
                                                                            handleChange(e);
                                                                            calculateW(form, values, 'pf', e, valueSubName.indexOf(time));
                                                                            loadwsum(valueSubName, energyConsumedField, form)
                                                                        }}
                                                                        onBlur={(e: any) => {
                                                                            handleChange(e);
                                                                            calculateW(form, values, 'pf', e, valueSubName.indexOf(time));
                                                                            loadwsum(valueSubName, energyConsumedField, form)
                                                                        }}
                                                                        defaultValue={valueSubName[valueSubName.indexOf(time)].pf}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        <div className="d-inline-block">
                                                            <Field
                                                                type="text"
                                                                className="form-control text-right max-width-7"
                                                                id={time.id}
                                                                name={`${valueName}.${valueSubName.indexOf(time)}.w`}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                            </>
                        )}
                    </FieldArray>
                </table>
            </div>
        </>
    )
}

export default FourHrTableComponent