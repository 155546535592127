import React, { useState, useEffect } from 'react';
import { VesselMachineryList } from '../VesselConfiguration.model';
import apiGlobal from '../../../global/api.global';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';

export default function CargoDischarging() {
    const [cargoDischargingMachineries, setCargoDischargingMachineries] = useState<Array<VesselMachineryList>>([])
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [cargoDischargingKwhMeter] = useState<Array<any>>([]);

    useEffect(() => {
        const loadCargoDischargingMachineries = async () => {
            return apiGlobal(`/special_operation_energy_setting/cargo_discharging_true_for_vessel/?vessel_id=${VesselID}`)
                .then((response) => {
                    setCargoDischargingMachineries(response.data);
                    return response.data;
                })
        }; loadCargoDischargingMachineries();
    }, [VesselID]);

    const handleCargoDischargingCheckbox = (e: any, machine: any, idx: number) => {
        machine.cargo_discharging_kwh_meter = e.target.checked;
        cargoDischargingKwhMeter.splice(idx, 1);
        cargoDischargingKwhMeter.splice(idx, 0, machine)
    }

    const DischargingKwhMetersSubmit = (e: any) => {
        cargoDischargingKwhMeter.map((meter: any) => {
            apiGlobal.put(`/special_operation_energy_setting/${meter.id}/`, meter).then(res => {
                successToast("Data saved successfully!");
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            });
            return '';
        })
    }

    return (
        <>
            <div className="table-responsive mb-5">
                <table className="table">
                    <thead className="table-light">
                        <tr>
                            <th>Sr. No.</th>
                            <th>Machinery name</th>
                            <th>Is kWh meter fitted on power pack/electrical motor panel?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cargoDischargingMachineries &&
                            cargoDischargingMachineries.map((vesselMachinery: any, index: number) => {
                                return (
                                    <tr key={vesselMachinery.id}>
                                        <td>{index + 1}</td>
                                        <td>{vesselMachinery.vessel_machinery_ec_group_name}</td>
                                        <td>
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="chckbx1"
                                                    onChange={(e: any) => handleCargoDischargingCheckbox(e, vesselMachinery, index)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                <div className="d-flex flex-wrap btn_Margin">
                    <Button type="button" color='primary' className='pos-end' onClick={DischargingKwhMetersSubmit}>Save</Button>
                </div>
            </div>
        </>
    )
}
