import React, { useEffect, useState } from 'react'
import { CardBody, Card, Col, Row, Input, Label, Button, Form, CardHeader, CardFooter } from "reactstrap";
import apiGlobal from '../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../';
import { errorToast, successToast } from '../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { fetchMachinaryOptions, loadAuxillaryEngineSetting, loadMainEngineSettings } from 'VesselMaster/vesselMaster.hooks';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import * as Yup from "yup";
import { VesselMachineryConstant } from 'shared/constants';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment/env';
import { queryClient } from 'react-query/queryClient';


const Engines = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [count, setCount] = useState(0);
    /** Queries */
    /** Load main engine setting */
    const { data: MainEngine, isLoading: MainEngineLoading, isError: MainEngineError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.vesselFuelType.key, VesselID],
            async () => { return await loadMainEngineSettings(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** Load machinery data*/
    const { data: VesselMachinery, isLoading: VesselMachineryLoading, isError: VesselMachineryError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.Machinary.key, VesselID],
            async () => { return await fetchMachinaryOptions(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** load auxillary engine data*/
    const { data: AuxillaryEngine, isLoading: AuxillaryEngineLoading, isError: AuxillaryEngineError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.AuxillaryMachineSetting.key, VesselID],
            async () => { return await loadAuxillaryEngineSetting(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /**Inital values start */
    /** get specific mahinaries from vessel_machinery_list */
    const VesselMachineryList = (engine_type: any) => {
        const machinary: number[] = [];
        VesselMachinery?.forEach((machineObj: any) => {
            if (machineObj.precedence_id === engine_type) {
                machinary.push(machineObj);
            }
        });

        return machinary;
    };
    /** Assign initial values to Engines formik object */
    const getMainEngineInitialValues = () => {
        let mainEngineArr: any[] = [];
        let mainEngineIds: any[] = [];
        const EngineListArr = VesselMachineryList(VesselMachineryConstant.MAIN_ENGINE);
        if (MainEngine?.length === EngineListArr.length) {
            return MainEngine
        } else if (MainEngine?.length !== EngineListArr.length) {
            MainEngine?.forEach((engineObj) => {
                mainEngineArr.push(engineObj)
                mainEngineIds.push(engineObj.machinery_name)
            })
            EngineListArr.forEach((MachinaryObj: any) => {
                if (!mainEngineIds.includes(MachinaryObj.id)) {
                    mainEngineArr.push({
                        machinery: MachinaryObj.vessel_machinery_name,
                        engine_type: '2_stroke',
                        propeller_type: 'controllable_pitch_propeller',
                        shaft_power: 0,
                        propeller_pitch: 0,
                        eedi: 0,
                        eexi: 0,
                        vessel_name: VesselID,
                        machinery_name: MachinaryObj.id
                    })
                }
            })
        }
        return mainEngineArr
    }
    const getAuxillaryEngineInitialValues = () => {
        let AuxillaryEngineArr: any[] = [];
        let auxIds: any[] = [];
        const VesselMachineryListArr = VesselMachineryList(VesselMachineryConstant.AUXILIARY_ENGINE);
        if (AuxillaryEngine?.length === VesselMachineryListArr.length) {
            return AuxillaryEngine
        } else if (AuxillaryEngine?.length !== VesselMachineryListArr.length) {
            AuxillaryEngine?.forEach((engineObj) => {
                AuxillaryEngineArr.push(engineObj)
                auxIds.push(engineObj.vessel_machinery_name)
            })
            VesselMachineryListArr.forEach((MachinaryObj: any) => {
                if (!auxIds.includes(MachinaryObj.id)) {
                    AuxillaryEngineArr.push({
                        vessel_machinery: MachinaryObj.machinery,
                        power_output_rating: 0,
                        eedi: 0,
                        rated_voltage: 0,
                        rated_frequency: 0,
                        rated_rpm: 0,
                        alternator_power_rating: 0,
                        vessel: VesselID,
                        vessel_machinery_name: MachinaryObj.id
                    })
                }
            })
        }
        return AuxillaryEngineArr;
    }
    /**Inital values end */
    useEffect(() => {
        EngineFormik.initialValues.auxillaryEngine = getAuxillaryEngineInitialValues()
    }, [AuxillaryEngine, VesselMachinery, count])
    // General Settings Formik Object
    const EngineFormik = {
        initialValues: {
            mainEngine: getMainEngineInitialValues(),
            auxillaryEngine: getAuxillaryEngineInitialValues(),
        },
        validationSchema: Yup.object().shape({
            mainEngine: Yup.array(
                Yup.object().shape({
                    engine_type: Yup.string()
                        .oneOf(['2_stroke', '4_stroke', 'steam_turbine_type']),
                    propeller_type: Yup.string()
                        .oneOf(['controllable_pitch_propeller', 'fixed_pitch_propeller']),
                    shaft_power: Yup.number()
                        .min(0, 'Shaft power must be a positive number')
                        .nullable(),
                    propeller_pitch: Yup.string().matches(
                        /^\d{1,5}(\.\d{2,10})?$/,
                        commonValidationMessages.before5after10
                    ).nullable(),
                    eedi: Yup.string().matches(
                        /^\d{1,2}(\.\d{0,2})?$/,
                        commonValidationMessages.before2after2
                    ).nullable(),
                    eexi: Yup.string().matches(
                        /^\d{1,2}(\.\d{0,2})?$/,
                        commonValidationMessages.before2after2
                    ).nullable(),
                })
            ),
            auxillaryEngine: Yup.array(
                Yup.object().shape({
                    power_output_rating: Yup.string().matches(
                        /^\d{1,5}(\.\d{0,2})?$/,
                        commonValidationMessages.before5after2
                    ).required(commonValidationMessages.required),
                    eedi: Yup.string().matches(
                        /^\d{1,5}(\.\d{0,2})?$/,
                        commonValidationMessages.before5after2
                    ).required(commonValidationMessages.required),
                    rated_voltage: Yup.string().matches(
                        /^\d{1,5}(\.\d{0,2})?$/,
                        commonValidationMessages.before5after2
                    ).required(commonValidationMessages.required),
                    rated_frequency: Yup.string().matches(
                        /^\d{1,5}(\.\d{0,2})?$/,
                        commonValidationMessages.before5after2
                    ).required(commonValidationMessages.required),
                    rated_rpm: Yup.string().matches(
                        /^\d{1,5}(\.\d{0,2})?$/,
                        commonValidationMessages.before5after2
                    ).required(commonValidationMessages.required),
                    alternator_power_rating: Yup.string().matches(
                        /^\d{1,5}(\.\d{0,2})?$/,
                        commonValidationMessages.before5after2
                    ).required(commonValidationMessages.required),
                })
            ),
        }),
    };
    const handleEngineSubmit = (values: any, url: any, engineType: any) => {
        const handleResponse = (response: any) => {
            if (response.status === 200 || response.status === 201) {
                successToast("Data saved successfully!");
                if (engineType === 'AuxillaryEngine') {
                    queryClient.invalidateQueries(queryKeyes.vessel.AuxillaryMachineSetting.key);
                    setCount(count + 1);
                }
            }
        }
        if (values.id) {
            apiGlobal.put(`/${url}/${values.id}/`, values).then(res => {
                handleResponse(res);

            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin")
                }
            })
        } else {
            apiGlobal.post(`/${url}/`, values).then(res => {
                handleResponse(res);
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin")
                }
            })
        }
    }
    return (
        <>
            <Card className='p-0 m-0 border-0'>
                {(AuxillaryEngineLoading || VesselMachineryLoading || MainEngineLoading) && <Loading message="Loading required data!" />}
                {(VesselMachineryError) && <ErrorComponent message="Error loading required data!" />}
                {!(AuxillaryEngineLoading || VesselMachineryLoading || MainEngineLoading) &&
                    !(VesselMachineryError) &&
                    <Formik
                        key={count}
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);

                        }}
                        initialValues={EngineFormik.initialValues}
                        validationSchema={EngineFormik.validationSchema}
                    >
                        {(props: any) => (
                            <Form onSubmit={props.handleSubmit} noValidate autoComplete='off'>
                                <CardBody className='p-0'>
                                    <FieldArray name='Engine'>
                                        {() => (
                                            <>
                                                <Card>
                                                    <CardHeader className='p-2'>
                                                        <h4 className='mb-0'>Main Engines</h4>
                                                    </CardHeader>
                                                    {/* Handle Main engine form */}
                                                    {props?.values &&
                                                        props?.values?.mainEngine.map((engine: any, index: number) => {
                                                            return (

                                                                <Card className='m-2'>
                                                                    <CardHeader className='p-2'>
                                                                        <h5 className='mb-0'>{engine.machinery}</h5>
                                                                    </CardHeader>
                                                                    <CardBody className='p-2'>
                                                                        <Row>
                                                                            <Col lg={8} className='flex-column'>
                                                                                <Col>
                                                                                    <Row>
                                                                                        <Label className='bold_lbl asteric'>Engine Type</Label>
                                                                                        <div className='ele_row1'>
                                                                                            <div className="form-check">
                                                                                                <Field name={`mainEngine.${index}.engine_type`}>
                                                                                                    {({ field }: FieldProps) => (
                                                                                                        <Input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name={field.name}
                                                                                                            id={`stroke2_${index}`}
                                                                                                            value="2_stroke"
                                                                                                            checked={field.value === "2_stroke"}
                                                                                                            onChange={(e: any) => {
                                                                                                                field.onChange(e);
                                                                                                                props?.handleChange(e);
                                                                                                            }}
                                                                                                            defaultValue={props.values?.mainEngine.engine_type}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                {props?.errors?.mainEngine && props?.errors?.mainEngine?.is_egcs_in_use && props?.env?.form_validation === true &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`is_egcs_in_use`}
                                                                                                        message={props?.errors?.mainEngine?.is_egcs_in_use}
                                                                                                        open={(props?.errors?.mainEngine && props?.errors?.mainEngine?.is_egcs_in_use) ? true : false}
                                                                                                    />
                                                                                                }
                                                                                                <Label className="form-check-label mr-1" htmlFor={`stroke2_${index}`}>
                                                                                                    2 stroke
                                                                                                </Label>
                                                                                            </div>
                                                                                            <div className="form-check">
                                                                                                <Field name={`mainEngine.${index}.engine_type`}>
                                                                                                    {({ field }: FieldProps) => (
                                                                                                        <Input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name={field.name} id={`stroke4_${index}`}
                                                                                                            value="4_stroke"
                                                                                                            checked={field.value === "4_stroke"}
                                                                                                            onChange={(e: any) => {
                                                                                                                field.onChange(e);
                                                                                                                props?.handleChange(e);
                                                                                                            }}
                                                                                                            defaultValue={props.values?.mainEngine.engine_type}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <Label htmlFor={`stroke4_${index}`}>4 stroke</Label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Row>
                                                                                </Col>
                                                                                <Col>
                                                                                    <Col>
                                                                                        <Row>
                                                                                            <Col sm={4} className=''>
                                                                                                <Label className='bold_lbl mb-2 asteric'>Propeller Type</Label>
                                                                                                <div className="form-check">
                                                                                                    <Field name={`mainEngine.${index}.propeller_type`}>
                                                                                                        {({ field, form }: FieldProps) => (
                                                                                                            <Input
                                                                                                                className="form-check-input"
                                                                                                                type="radio"
                                                                                                                id={`ccpp_${index}`}
                                                                                                                value={"controllable_pitch_propeller"}
                                                                                                                checked={props?.values?.mainEngine[index]?.propeller_type === "controllable_pitch_propeller"}
                                                                                                                onChange={(e: any) => form.setFieldValue(field.name, e.target.value)}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <Label className="form-check-label mr-1" htmlFor={`ccpp_${index}`}>
                                                                                                        Controllable pitch propeller
                                                                                                    </Label>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col sm={4} className='d-flex align-items-center'>
                                                                                                <div className="form-check">
                                                                                                    <Field name={`mainEngine.${index}.propeller_type`}>
                                                                                                        {({ field, form }: FieldProps) => (
                                                                                                            <Input
                                                                                                                className="form-check-input"
                                                                                                                type="radio"
                                                                                                                id={`fpp_${index}`}
                                                                                                                value={"fixed_pitch_propeller"}
                                                                                                                checked={props?.values?.mainEngine[index]?.propeller_type === "fixed_pitch_propeller"}
                                                                                                                onChange={(e: any) => form.setFieldValue(field.name, e.target.value)}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <Label className="form-check-label" htmlFor={`fpp_${index}`}>
                                                                                                        Fixed pitch propeller
                                                                                                    </Label>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col sm={4} className='d-flex align-items-center'>
                                                                                                <Col>
                                                                                                    {props?.values?.mainEngine[index]?.propeller_type === 'fixed_pitch_propeller' ?
                                                                                                        <>
                                                                                                            <Label className='mb-0'>Pitch</Label>
                                                                                                            <div className="input-group mb-3">
                                                                                                                <Field name={`mainEngine.${index}.propeller_pitch`}>
                                                                                                                    {({ field }: FieldProps) => (
                                                                                                                        <Input
                                                                                                                            type="text"
                                                                                                                            className="form-control max-width-7"
                                                                                                                            {...field}
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </Field>
                                                                                                                <div className='input-group-text round_border'>m</div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <div style={{ minHeight: '75px' }}></div>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col lg={4}>
                                                                                <div className='ele_row1'>
                                                                                    <div>
                                                                                        <Label className='mb-0'>EEDI</Label>
                                                                                        <div className="input-group mb-3">
                                                                                            <Field name={`mainEngine.${index}.eedi`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        className="form-control max-width-7"
                                                                                                        {...field}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                            <div className='input-group-text round_border'>m</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='ms-3'>
                                                                                        <Label className='mb-0'>EEXI</Label>
                                                                                        <div className="input-group mb-3">
                                                                                            <Field name={`mainEngine.${index}.eexi`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        className="form-control max-width-7"
                                                                                                        {...field}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                            <div className='input-group-text round_border'>m</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <Col>
                                                                                    <Label className='mb-0'>Shaft Power</Label>
                                                                                    <div className="input-group mb-3">
                                                                                        <Field name={`mainEngine.${index}.shaft_power`}>
                                                                                            {({ field }: FieldProps) => (
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    className="form-control max-width-7"
                                                                                                    {...field}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                        <div className='input-group-text round_border'>m</div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                    <CardFooter className='p-2'>
                                                                        <Button type="button" color='primary' className='justify_right ' onClick={() => handleEngineSubmit(props?.values?.mainEngine[index], 'main_engine_setting', 'MainEngine')}>Save</Button>
                                                                    </CardFooter>
                                                                </Card>
                                                            )
                                                        })
                                                    }
                                                </Card>
                                                <Card>
                                                    <CardHeader className='p-2'>
                                                        <h4 className='mb-0'>Auxiliary Engine</h4>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            {props?.values?.auxillaryEngine &&
                                                                props?.values?.auxillaryEngine.map((auxEngine: any, index: number) => (
                                                                    <Col md="6" key={index}>
                                                                        <Card>
                                                                            <CardHeader className='p-2'>
                                                                                <h5 className='mb-0'>{auxEngine.vessel_machinery}</h5>
                                                                            </CardHeader>
                                                                            <CardBody className='p-2'>
                                                                                <Row>
                                                                                    <Col>
                                                                                        {/* Power output rating */}
                                                                                        <Label className='asteric mb-0'>Power output rating</Label>
                                                                                        <div className="input-group mb-3">
                                                                                            <Field name={`auxillaryEngine.${index}.power_output_rating`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id={`power_output_rating_${index}`}
                                                                                                        className="form-control max-width-7"
                                                                                                        {...field} />
                                                                                                )}
                                                                                            </Field>
                                                                                            {props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.power_output_rating && env?.form_validation === true &&
                                                                                                <ErrorTooltip
                                                                                                    target={`power_output_rating_${index}`}
                                                                                                    message={props?.errors?.auxillaryEngine[index]?.power_output_rating}
                                                                                                    open={(props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.power_output_rating) ? true : false}
                                                                                                />
                                                                                            }
                                                                                            <div className='input-group-text round_border'>kW</div>
                                                                                        </div>
                                                                                        {/* Rated frequency */}
                                                                                        <Label className='asteric mb-0'>Rated frequency</Label>
                                                                                        <div className="input-group mb-3">
                                                                                            <Field name={`auxillaryEngine.${index}.rated_frequency`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id={`rated_frequency_${index}`}
                                                                                                        className="form-control max-width-7"
                                                                                                        {...field} />
                                                                                                )}
                                                                                            </Field>
                                                                                            {props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.rated_frequency && env?.form_validation === true &&
                                                                                                <ErrorTooltip
                                                                                                    target={`rated_frequency_${index}`}
                                                                                                    message={props?.errors?.auxillaryEngine[index]?.rated_frequency}
                                                                                                    open={(props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.rated_frequency) ? true : false}
                                                                                                />
                                                                                            }
                                                                                            <div className='input-group-text round_border'>Hz</div>
                                                                                        </div>
                                                                                        {/* Alternator power rating */}
                                                                                        <Label className='asteric mb-0'>Alternator power rating</Label>
                                                                                        <div className="input-group mb-3">
                                                                                            <Field name={`auxillaryEngine.${index}.alternator_power_rating`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id={`alternator_power_rating_${index}`}
                                                                                                        className="form-control max-width-7"
                                                                                                        {...field} />
                                                                                                )}
                                                                                            </Field>
                                                                                            {props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.alternator_power_rating && env?.form_validation === true &&
                                                                                                <ErrorTooltip
                                                                                                    target={`alternator_power_rating_${index}`}
                                                                                                    message={props?.errors?.auxillaryEngine[index]?.alternator_power_rating}
                                                                                                    open={(props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.alternator_power_rating) ? true : false}
                                                                                                />
                                                                                            }
                                                                                            <div className='input-group-text round_border'>kVA</div>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        {/* Rated voltage */}
                                                                                        <Label className='asteric mb-0'>Rated voltage</Label>
                                                                                        <div className="input-group mb-3">
                                                                                            <Field name={`auxillaryEngine.${index}.rated_voltage`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id={`rated_voltage_${index}`}
                                                                                                        className="form-control max-width-7"
                                                                                                        {...field} />
                                                                                                )}
                                                                                            </Field>
                                                                                            {props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.rated_voltage && env?.form_validation === true &&
                                                                                                <ErrorTooltip
                                                                                                    target={`rated_voltage_${index}`}
                                                                                                    message={props?.errors?.auxillaryEngine[index]?.rated_voltage}
                                                                                                    open={(props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.rated_voltage) ? true : false}
                                                                                                />
                                                                                            }
                                                                                            <div className='input-group-text round_border'>V</div>
                                                                                        </div>
                                                                                        {/* Rated RPM */}
                                                                                        <Label className='asteric mb-0'>Rated RPM</Label>
                                                                                        <div className="input-group mb-3">
                                                                                            <Field name={`auxillaryEngine.${index}.rated_rpm`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id={`rated_rpm_${index}`}
                                                                                                        className="form-control max-width-7"
                                                                                                        {...field} />
                                                                                                )}
                                                                                            </Field>
                                                                                            {props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.rated_rpm && env?.form_validation === true &&
                                                                                                <ErrorTooltip
                                                                                                    target={`rated_rpm_${index}`}
                                                                                                    message={props?.errors?.auxillaryEngine[index]?.rated_rpm}
                                                                                                    open={(props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.rated_rpm) ? true : false}
                                                                                                />
                                                                                            }
                                                                                        </div>
                                                                                        {/* EEDI */}
                                                                                        <Label className='asteric mb-0'>EEDI</Label>
                                                                                        <div className="input-group mb-3">
                                                                                            <Field name={`auxillaryEngine.${index}.eedi`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id={`eedi_${index}`}
                                                                                                        className="form-control max-width-7"
                                                                                                        {...field} />
                                                                                                )}
                                                                                            </Field>
                                                                                            {props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.eedi && env?.form_validation === true &&
                                                                                                <ErrorTooltip
                                                                                                    target={`eedi_${index}`}
                                                                                                    message={props?.errors?.auxillaryEngine[index]?.eedi}
                                                                                                    open={(props?.errors?.auxillaryEngine && props?.errors?.auxillaryEngine[index]?.eedi) ? true : false}
                                                                                                />
                                                                                            }
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </CardBody>
                                                                            <CardFooter className='p-2'>
                                                                                <Button
                                                                                    type="button"
                                                                                    color='primary'
                                                                                    className='justify_right'
                                                                                    onClick={() => handleEngineSubmit(props?.values?.auxillaryEngine[index], 'auxillary_machine_setting', 'AuxillaryEngine')}
                                                                                >
                                                                                    Save
                                                                                </Button>
                                                                            </CardFooter>
                                                                        </Card>
                                                                    </Col>
                                                                ))}
                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                            </>

                                        )}
                                    </FieldArray>
                                </CardBody>
                                <Row className='mt-2'>
                                    <FormValuesDebug values={[props?.values, props?.errors, EngineFormik.initialValues]} />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }
            </Card>
        </>
    );
};
export default Engines;