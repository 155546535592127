import React, { useState, useEffect } from 'react'
import { VesselMachineryList } from '../VesselConfiguration.model';
import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { Button } from 'reactstrap';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';

export default function ReeferContainer() {
    const [reeferContainerMachineries, setreeferContainerMachineries] = useState<Array<VesselMachineryList>>([]);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [reeferConatinerKwhMeter] = useState<Array<any>>([]);

    const handleReeferContainerCheckbox = (e: any, machine: any, idx: number) => {
        machine.reefer_container_kwh_meter = e.target.checked;
        reeferConatinerKwhMeter.splice(idx, 1);
        reeferConatinerKwhMeter.splice(idx, 0, machine)
    }

    const ReeferKwhMetersSubmit = (e: any) => {
        reeferConatinerKwhMeter.map((meter: any) => {
            apiGlobal.put(`/special_operation_energy_setting/${meter.id}/`, meter).then(res => {
                successToast("Data saved successfully!");
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            });
            return '';
        })
    }

    useEffect(() => {
        const loadreeferContainerMachineries = async () => {
            return apiGlobal(`/special_operation_energy_setting/reefer_container_true_for_vessel/?vessel_id=${VesselID}`)
                .then((response) => {
                    setreeferContainerMachineries(response.data);
                    return response.data;
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
        }; loadreeferContainerMachineries();
    }, [VesselID]);
    return (
        <>
            <div className="table-responsive mb-5">
                <table className="table">
                    <thead className="table-light">
                        <tr>
                            <th>Sr. No.</th>
                            <th>Machinery name</th>
                            <th>Is kWh meter fitted on power pack/electrical motor panel?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reeferContainerMachineries &&
                            reeferContainerMachineries.map((vesselMachinery: any, index: number) => {
                                return (
                                    <tr key={vesselMachinery.id}>
                                        <td>{index + 1}</td>
                                        <td>{vesselMachinery.vessel_machinery_ec_group_name}</td>
                                        <td>
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="chckbx1"
                                                    onChange={(e: any) => handleReeferContainerCheckbox(e, vesselMachinery, index)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                <div className="d-flex flex-wrap">
                    <Button type="button" color='primary' className='pos-end1' onClick={ReeferKwhMetersSubmit}>Save</Button>
                </div>
            </div>
        </>
    )
}
