import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import { errorToast, successToast } from 'Components/Toasts';
import { vesselFuel, loadCargoReliquificationFuel, loadCargoReliquificationWithkWh, loadTimePeriod, loadAuxEngineMaxSFOC, loadCargoReliquificationFuelObject, loadCargoReliquification4hrObject, loadCargoReliquificationEnergyObject, loadBatchConsumptionObject, loadSpecialOperation, loadSpOpFuelSubTypeObject } from 'VesselMaster/vesselMaster.hooks';
import { Formik, FieldArray, Field, FieldProps } from 'formik';
import apiGlobal from 'global/api.global';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Row, Col, Card, CardHeader, CardBody, Input, Label, Modal, Button, Form } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import FourHrTableComponent from './FourHrTableComponent';
import { queryClient } from 'react-query/queryClient';
import { errResponse } from 'GenericForms/Helper';
import env from 'environment/env';
import { SpecialOpsConstant, FuelSubTypes, FuelTypes } from 'shared/constants';
import SpOpFuelBatchConsumption from 'Components/SpOpFuelBatchConsumption';
import * as Yup from 'yup';
import { TooltipMsg } from 'Components/ToolTipMessage';

interface CargoReliquificationType {
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    activeTab: number,
    toggleTab: any
    tabsIdList: any[],
    record: any,
    lastRecord: any,
    toggleDynamicTabs: any,
    previousTabIndex: number,
    tabsList: any[]
}

const CargoReliquificationComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    activeTab,
    toggleTab,
    tabsIdList,
    record,
    lastRecord,
    toggleDynamicTabs,
    previousTabIndex,
    tabsList
}: CargoReliquificationType) => {
    /** State variables */
    const [withkWhMeter, setWithkWhMeter] = useState<Array<any>>([]);
    const [withoutkWhMeter, setWithoutkWhMeter] = useState<Array<any>>([]);
    const [modal_backdrop, setmodal_backdrop] = useState(false);
    const [timePeriodId, setTimePeriodId] = useState<Array<any>>([]);
    const [modalMachinery, setModalMachinery] = useState<any>({});
    const [modalIndex, setModalIndex] = useState<number>(null);
    const [fuelSubTypeConsumption, setFuelSubTypeConsumption] = useState(false);
    const [totalConsumption, setTotalConsumption] = useState<any>(null);
    const [fuelConsumed, setFuelConsumed] = useState<any>({});
    /** State variables end */

    /** Queries */
    /** Fetch Vessel distinct fuel  */
    const { data: fuelTypes, isLoading: fuelTypesLoading, isError: fuelTypesError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.fuel.key, VesselID],
            async () => {
                return await vesselFuel(VesselID);
            },
            { staleTime: Infinity }
        );
    /** Vessel fuel machineries applicable for cargo reliquification */
    const { data: machineryFuelGroup, isLoading: machineryFuelGroupLoading, isError: machineryFuelGroupError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.CargoReliquificationFuelMachineries.key, VesselID],
            async () => {
                return await loadCargoReliquificationFuel(VesselID);
            },
            { staleTime: Infinity }
        )
    /** Vessel energy machineries applicable for cargo reliquification */
    const { data: machineryEnergyGroup, isLoading: machineryEnergyGroupLoading, isError: machineryEnergyGroupError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.CargoReliquificationWithkWhMachineries.key, VesselID],
            async () => {
                return await loadCargoReliquificationWithkWh(VesselID);
            },
            { staleTime: Infinity }
        )
    /** Time periods for 4 hours table */
    const { data: TimePeriod, isLoading: TimePeriodLoading, isError: TimePeriodError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.TimePeriodObject.key],
            async () => {
                return await loadTimePeriod();
            },
            { staleTime: Infinity }
        )
    /** Cargo Reliquification fuel Object used for edit */
    const { data: ReliquificationFuelObject, isLoading: ReliquificationFuelObjectLoading, isError: ReliquificationFuelObjectError } = useQuery(
        [queryKeyes.vessel.CargoReliquificationFuelObject.key, VesselID, ReportID],
        async () => {
            return await loadCargoReliquificationFuelObject(VesselID, ReportID);
        }, { staleTime: Infinity }
    );
    /** Cargo Reliquification energy Object used for edit */
    const { data: ReliquificationEnergyObject, isLoading: ReliquificationEnergyObjectLoading, isError: ReliquificationEnergyObjectError } = useQuery(
        [queryKeyes.vessel.CargoReliquificationEnergyObject.key, VesselID, ReportID],
        async () => {
            return await loadCargoReliquificationEnergyObject(VesselID, ReportID);
        }, { staleTime: Infinity }
    );
    /** Cargo Reliquification without kWh Object used for edit */
    const { data: Reliquification4hrObject, isLoading: Reliquification4hrObjectLoading, isError: Reliquification4hrObjectError } = useQuery(
        [queryKeyes.vessel.CargoReliquification4hrObject.key, VesselID, ReportID],
        async () => {
            return await loadCargoReliquification4hrObject(VesselID, ReportID);
        }, { staleTime: Infinity }
    );
    /** Max SFOC of vessel's auxiliary engines */
    const { data: AuxEngineMaxSFOC, isLoading: AuxEngineMaxSFOCLoading, isError: AuxEngineMaxSFOCError } = useQuery(
        [queryKeyes.vessel.AuxEngineMaxSFOC.key, VesselID],
        async () => {
            return await loadAuxEngineMaxSFOC(VesselID);
        }, { staleTime: Infinity }
    );
    /** Batch Consumption Object used for edit */
    const { data: BatchConsumptionObject, isLoading: BatchConsumptionObjectLoading, isError: BatchConsumptionObjectError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.BatchConsumptionObject.key, VesselID, ReportID],
            async () => {
                return await loadBatchConsumptionObject(VesselID, ReportID);
            }, { staleTime: Infinity }
        );
    /** Special operation object from master */
    const { data: SpecialOpsObject, isLoading: SpecialOpsObjectLoading, isError: SpecialOpsObjectError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.masters.SpecialOperationMaster.key, SpecialOpsConstant.CARGO_RELIQUIFICATION],
            async () => {
                return await loadSpecialOperation(SpecialOpsConstant.CARGO_RELIQUIFICATION);
            }, { staleTime: Infinity }
        );
    /** Fuel Sub Type consumption object used for edit */
    const { data: FuelSubTypeObject, isLoading: FuelSubTypeObjectLoading, isError: FuelSubTypeObjectError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.SpOpFuelSubTypeObject.key, VesselID, ReportID, SpecialOpsConstant.CARGO_RELIQUIFICATION],
            async () => {
                return await loadSpOpFuelSubTypeObject(VesselID, ReportID, SpecialOpsConstant.CARGO_RELIQUIFICATION);
            }, { staleTime: Infinity }
        );
    /** Queries end */

    /** Assign values to formik's fuelConsumption initial object */
    const getinitialCargoReliquificationFuelValue = () => {
        let obj: any[] = [];
        if (ReliquificationFuelObject && ReliquificationFuelObject?.length > 0) {
            return ReliquificationFuelObject;
        } else if (obj !== undefined &&
            machineryFuelGroup !== undefined &&
            fuelTypes !== undefined &&
            obj.length <= machineryFuelGroup.length * fuelTypes.length) {
            machineryFuelGroup.flatMap((machine: any) => {
                fuelTypes.map((fuel: any) => {
                    obj.push({
                        total_fuel_consumption: 0,
                        special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        vessel_machinery_fc_group: machine.vessel_machinery_fc_group,
                        vessel_fuel: fuel.fuel_type
                    });
                    return fuel;
                });
                return machine;
            });
            return obj;
        }
    }

    /** Assign values to formik's energy consumer's with kWh meter initial object */
    const getInitialCargoReliquificationWithkWhMeterValue = () => {
        let obj: any[] = [];
        if (ReliquificationEnergyObject && ReliquificationEnergyObject?.length > 0) {
            return ReliquificationEnergyObject;
        } else if (obj !== undefined &&
            withkWhMeter !== undefined &&
            obj.length <= withkWhMeter.length) {
            withkWhMeter.map((machine: any) => {
                obj.push({
                    is_kwh_meter_not_functional: false,
                    energy_consumed: 0,
                    equivalent_fuel_consumption: 0,
                    special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                    vessel_machinery_ec_group: machine.vessel_machinery_ec_group
                });
                return machine;
            });
            return obj;
        }
    }

    /** Assign values to formik's energy consumers with non functional kWh meter initial object */
    const getInitialCargoReliquificationWithkWhMeterNonFunctionalValue = () => {
        let obj: any[] = [];
        if (ReliquificationEnergyObject && ReliquificationEnergyObject?.length > 0) {
            ReliquificationEnergyObject.map((functional: any) => {
                if (functional.is_kwh_meter_not_functional === true) {
                    obj.push(Reliquification4hrObject?.filter((item: any) =>
                        item.vessel_machinery_name === functional.vessel_machinery_ec_group)[0]);
                }
                return '';
            })
            return obj;
        } else if (
            obj !== undefined &&
            withkWhMeter !== undefined &&
            fuelTypes !== undefined &&
            TimePeriod !== undefined &&
            obj.length <= withkWhMeter.length * fuelTypes.length) {
            withkWhMeter.flatMap((machine: any) => {
                TimePeriod.map((time: any) => {
                    obj.push({
                        hours_of_consumption: 0,
                        v: 0,
                        i: 0,
                        pf: 0,
                        w: 0,
                        special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                        vessel_machinery_name: machine.vessel_machinery_ec_group,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        period: time.id,
                        machine_name: machine.vessel_machinery_ec_group_name,
                        time_period: time.period
                    })
                    return time;
                });
                return machine;
            })
            return obj
        }
    }

    /** Assign values to formik's energy consumer's without kWh meter initial object */
    const getInitialCargoReliquificationWithoutkWhMeterValue = () => {
        let obj: any[] = [];
        if (Reliquification4hrObject && Reliquification4hrObject?.length > 0) {
            return Reliquification4hrObject;
        } else if (obj !== undefined &&
            TimePeriod?.length > 0 && TimePeriod[0].id > 0 &&
            fuelTypes !== undefined &&
            obj.length <= withoutkWhMeter.length) {
            withoutkWhMeter.flatMap((machine: any) => {
                TimePeriod.map((time: any) => {
                    obj.push({
                        hours_of_consumption: 0,
                        v: 0,
                        i: 0,
                        pf: 0,
                        w: 0,
                        special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                        vessel_machinery_name: machine.vessel_machinery_ec_group,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        period: time.id,
                        machine_name: machine.vessel_machinery_ec_group_name,
                        time_period: time.period
                    });
                    return time;
                });
                return machine;
            });
            return obj;
        }
    }

    /** Assign values to formik's fuel sub type consumption initial object */
    const getInitialFuelSubTypeConsumption = () => {
        let Arr: any[] = [];
        if (FuelSubTypeObject && FuelSubTypeObject?.length > 0) {
            return FuelSubTypeObject;
        } else {
            BatchConsumptionObject && machineryFuelGroup &&
                machineryFuelGroup.forEach((machine: any) => {
                    BatchConsumptionObject
                        .filter((item: any) => item.fuel_consumption > 0 &&
                            (item?.fuel_sub_type === FuelSubTypes.HFO || item?.fuel_sub_type === FuelSubTypes.LFO))
                        .forEach((batch: any) => {
                            Arr.push({
                                fuel_consumption: null,
                                special_operation_fuel: (ReliquificationFuelObject && ReliquificationFuelObject?.filter((item: any) =>
                                    item.vessel_machinery_fc_group === machine.vessel_machinery_fc_group &&
                                    item.vessel_fuel === batch?.fuel_name)[0]?.id) ?? null,
                                special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                                vessel_machinery_fc_group: machine.vessel_machinery_fc_group,
                                vessel_machinery_fc_group_name: machine.vessel_machinery_fc_group_name,
                                fuel_sub_type: batch?.fuel_sub_type,
                                fuel_consumption_batch: batch?.fuel_batch_name,
                                fuel_type: batch?.fuel_name,
                                vessel: VesselID,
                                voyage_information: VoyageID,
                                vessel_reporting_information: ReportID
                            })
                        })
                })
        }
        return Arr;
    }

    /** useEffect */
    /** Distinguish energy consumers based on kWh meter's presence */
    useEffect(() => {
        if (machineryEnergyGroup?.length > 0 && machineryEnergyGroup[0].id > 0) {
            setWithkWhMeter(machineryEnergyGroup.filter((item: any) => item.cargo_reliquification_kwh_meter === true));
            setWithoutkWhMeter(machineryEnergyGroup.filter((item: any) => item.cargo_reliquification_kwh_meter === false));
        }
    }, [machineryEnergyGroup])
    /** Call initial values function */
    useEffect(() => {
        CargoReliquificationFormik.initialValues.fuelConsumption = getinitialCargoReliquificationFuelValue();
        CargoReliquificationFormik.initialValues.withkWhMeter.functional = getInitialCargoReliquificationWithkWhMeterValue();
        CargoReliquificationFormik.initialValues.withkWhMeter.nonfunctional = getInitialCargoReliquificationWithkWhMeterNonFunctionalValue();
        CargoReliquificationFormik.initialValues.withoutkWhMeter = getInitialCargoReliquificationWithoutkWhMeterValue();
        CargoReliquificationFormik.initialValues.fuelSubTypeConsumption = getInitialFuelSubTypeConsumption()
    }, [fuelTypes, machineryFuelGroup, machineryEnergyGroup, TimePeriod, withkWhMeter, withoutkWhMeter, ReliquificationFuelObject,
        ReliquificationEnergyObject, Reliquification4hrObject, BatchConsumptionObject, SpecialOpsObject, FuelSubTypeObject])
    /** Show applicable time periods */
    useEffect(() => {
        let array = [...timePeriodId];
        TimePeriod && TimePeriod?.map((time: any) => {
            if (lastRecord && lastRecord.reporting_time_utc && time.period) {
                const lastRecordTime = new Date(lastRecord.reporting_time_utc);
                const currRecordTime = new Date(record.reporting_time_utc);
                if (time.id <= 3) {
                    const periodStart = new Date(`${lastRecord.reporting_time_utc.toString().substring(0, 11)}${time.period.toString().substring(0, 2)}:${time.period.toString().substring(2, 4)}:00Z`);
                    if ((lastRecordTime <= periodStart)) {
                        array.push(time.id);
                    }
                } else {
                    const periodEnd = new Date(`${record.reporting_time_utc.toString().substring(0, 11)}${time.period.toString().substring(5, 7)}:${time.period.toString().substring(7, 9)}:00Z`);
                    if ((currRecordTime >= periodEnd)) {
                        array.push(time.id);
                    }
                }
            }
            setTimePeriodId(array);
            return time;
        })
    }, [lastRecord, record, TimePeriod])
    /** useEffect end */

    /** Other Special Operation's formik object */
    const CargoReliquificationFormik = {
        initialValues: {
            fuelConsumption: getinitialCargoReliquificationFuelValue(),
            fuelSubTypeConsumption: getInitialFuelSubTypeConsumption(),
            withkWhMeter: {
                functional: getInitialCargoReliquificationWithkWhMeterValue(),
                nonfunctional: getInitialCargoReliquificationWithkWhMeterNonFunctionalValue(),
            },
            withoutkWhMeter: getInitialCargoReliquificationWithoutkWhMeterValue(),
        },
        validationScehma: Yup.object({}).test('batch_consumption_sum',
            function (value: any) {
                let sum: number = 0;
                value?.fuelSubTypeConsumption?.filter((item: any) =>
                    item.vessel_machinery_fc_group === fuelConsumed.vessel_machinery_fc_group &&
                    (item.fuel_sub_type === FuelSubTypes.HFO || item.fuel_sub_type === FuelSubTypes.LFO))
                    .forEach((batch: any, indx: number) => {
                        if (batch.fuel_consumption === null) {
                            batch.fuel_consumption = 0;
                        }
                        sum += parseFloat(batch.fuel_consumption);
                    })
                if (totalConsumption !== null) {
                    if (sum !== parseFloat(totalConsumption)) {
                        throw new Yup.ValidationError(
                            `The sum of batch consumptions should be ${totalConsumption} mt`,
                            undefined,
                            `fuelSubTypeConsumption.fuel_consumption`
                        )
                    }
                }
            }
        )
    }

    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }

    /** Calculate sum of w */
    const loadwsum = (values: any[], field: string, form: any) => {
        let sum = 0;
        values.map((val: any) => {
            sum += parseFloat(val.w);
            return '';
        })
        return sum.toFixed(2);
    }

    if (machineryFuelGroupLoading ||
        machineryEnergyGroupLoading ||
        fuelTypesLoading ||
        TimePeriodLoading ||
        ReliquificationFuelObjectLoading ||
        ReliquificationEnergyObjectLoading ||
        Reliquification4hrObjectLoading ||
        AuxEngineMaxSFOCLoading ||
        BatchConsumptionObjectLoading ||
        SpecialOpsObjectLoading) {
        return <Loading message='Loading required data!' />
    }

    /** Fuel sub type consumption submit */
    const fuelSubTypeSubmit = (values: any) => {
        if (FuelSubTypeObject && FuelSubTypeObject?.length > 0) {
            values?.fuelSubTypeConsumption?.map((val: any) => {
                apiGlobal.put(`/special_operation_sub_type_fuel_consumption/${val.id}/`, val).then(res => {
                    if (res.status === 200) {
                        queryClient.invalidateQueries(queryKeyes.vessel.SpOpFuelSubTypeObject.key);
                    }
                })
                return '';
            });
        } else {
            if (values.fuelSubTypeConsumption?.length > 0) {
                apiGlobal.post(`/special_operation_sub_type_fuel_consumption/`, values.fuelSubTypeConsumption).then(res => {
                    if (res.status === 201) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(queryKeyes.vessel.SpOpFuelSubTypeObject.key);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }
    }

    /** Cargo Reliquification submit function */
    const cargoReliquificationSubmit = (values: any) => {/** Cargo reliquification fuel consumption submit */
        if (ReliquificationFuelObject && ReliquificationFuelObject?.length > 0) {
            values.fuelConsumption.map((val: any) => {
                apiGlobal.put(`/special_operation_fuel/${val.id}/`, val).then(res => {
                    if (res.status === 200) {
                        queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquificationFuelObject.key);
                        if (env?.form_validation === true) {
                            toggleDynamicTabs(previousTabIndex + 1);
                        }
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                })
                return '';
            })
        } else {
            if (values.fuelConsumption?.length > 0) {
                apiGlobal.post(`/special_operation_fuel/`, values.fuelConsumption).then(res => {
                    if (res.status === 201) {
                        /** Fuel sub type consumption submit */
                        res.data.forEach((data: any) => {
                            values?.fuelSubTypeConsumption
                                .filter((item: any) => item.vessel_machinery_fc_group === data.vessel_machinery_fc_group &&
                                    item.fuel_type === data.vessel_fuel)?.forEach((batch: any) => {
                                        batch.special_operation_fuel = data.id;
                                    })
                        })
                        fuelSubTypeSubmit(values);
                        /** Fuel sub type consumption submit end */

                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquificationFuelObject.key);
                        if (env?.form_validation === true) {
                            toggleDynamicTabs(previousTabIndex + 1);
                        }
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }

        /** Fuel sub type consumption submit */
        if (FuelSubTypeObject && FuelSubTypeObject?.length > 0) {
            fuelSubTypeSubmit(values);
        }

        /** Energy consumption with kWh meter submit */
        if (ReliquificationEnergyObject && ReliquificationEnergyObject?.length > 0) {
            values.withkWhMeter.functional.map((val: any) => {
                apiGlobal.put(`/special_operation_energy/${val.id}/`, val).then(res => {
                    if (res.status === 200) {
                        queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquificationEnergyObject.key);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                })
                return '';
            })
        } else {
            if (values.withkWhMeter.functional?.length > 0) {
                apiGlobal.post(`/special_operation_energy/`, values.withkWhMeter.functional).then(res => {
                    if (res.status === 201) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquificationEnergyObject.key);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }

        /** Energy consumption with non functional kWh meter submit */
        if (Reliquification4hrObject && Reliquification4hrObject?.length > 0) {
            values.withkWhMeter.nonfunctional.map((val: any) => {
                if (val.id === undefined) {
                    apiGlobal.post(`/special_operation_4_hours_reporting/`, val).then(res => {
                        if (res.status === 201) {
                            successToast("Data saved successfully!");
                            queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquification4hrObject.key);
                        }
                    }).catch(err => {
                        if (errResponse.includes(err.response.status)) {
                            errorToast("Internal error occured, please contact the admin");
                        }
                    })
                } else {
                    apiGlobal.put(`/special_operation_4_hours_reporting/${val.id}/`, val).then(res => {
                        if (res.status === 200) {
                            queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquification4hrObject.key);
                        }
                    }).catch(err => {
                        if (errResponse.includes(err.response.status)) {
                            errorToast("Internal error occured, please contact the admin");
                        }
                    })
                }
                return '';
            })
        } else {
            if (values.withkWhMeter.nonfunctional?.length > 0) {
                apiGlobal.post(`/special_operation_4_hours_reporting/`, values.withkWhMeter.nonfunctional).then(res => {
                    if (res.status === 201) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquification4hrObject.key);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }

        /** Energy consumption without kWh meter submit */
        if (Reliquification4hrObject && Reliquification4hrObject?.length > 0) {
            values.withoutkWhMeter.map((val: any) => {
                apiGlobal.put(`/special_operation_4_hours_reporting/${val.id}/`, val).then(res => {
                    if (res.status === 200) {
                        queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquification4hrObject.key);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                })
                return '';
            })
        } else {
            if (values.withoutkWhMeter?.length > 0) {
                apiGlobal.post(`/special_operation_4_hours_reporting/`, values.withoutkWhMeter).then(res => {
                    if (res.status === 201) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquification4hrObject.key);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }
    }

    if (machineryFuelGroupLoading ||
        machineryEnergyGroupLoading ||
        fuelTypesLoading ||
        TimePeriodLoading ||
        ReliquificationFuelObjectLoading ||
        ReliquificationEnergyObjectLoading ||
        Reliquification4hrObjectLoading ||
        AuxEngineMaxSFOCLoading ||
        BatchConsumptionObjectLoading ||
        SpecialOpsObjectLoading ||
        FuelSubTypeObjectLoading) {
        return <Loading message='Loading required data!' />
    }

    return (
        <Card className='p-0 mb-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Special Operations - Cargo Reliquification</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(machineryFuelGroupLoading ||
                machineryEnergyGroupLoading ||
                fuelTypesLoading ||
                TimePeriodLoading ||
                ReliquificationFuelObjectLoading ||
                ReliquificationEnergyObjectLoading ||
                Reliquification4hrObjectLoading ||
                AuxEngineMaxSFOCLoading ||
                BatchConsumptionObjectLoading ||
                SpecialOpsObjectLoading ||
                FuelSubTypeObjectLoading) && <Loading message='Loading required data!' />}
            {(machineryFuelGroupError ||
                machineryEnergyGroupError ||
                fuelTypesError ||
                TimePeriodError ||
                AuxEngineMaxSFOCError ||
                BatchConsumptionObjectError ||
                SpecialOpsObjectError) && <ErrorComponent message='Unable to load required data!' />}
            {ReliquificationFuelObjectError && getinitialCargoReliquificationFuelValue()}
            {ReliquificationEnergyObjectError && (getInitialCargoReliquificationWithkWhMeterValue() &&
                getInitialCargoReliquificationWithkWhMeterNonFunctionalValue())}
            {Reliquification4hrObjectError && getInitialCargoReliquificationWithoutkWhMeterValue()}
            {FuelSubTypeObjectError && getInitialFuelSubTypeConsumption()}
            {
                !(machineryFuelGroupLoading ||
                    machineryEnergyGroupLoading ||
                    fuelTypesLoading ||
                    TimePeriodLoading ||
                    ReliquificationFuelObjectLoading ||
                    ReliquificationEnergyObjectLoading ||
                    Reliquification4hrObjectLoading ||
                    AuxEngineMaxSFOCLoading ||
                    BatchConsumptionObjectLoading ||
                    SpecialOpsObjectLoading ||
                    FuelSubTypeObjectLoading) &&
                !(machineryFuelGroupError ||
                    machineryEnergyGroupError ||
                    fuelTypesError ||
                    TimePeriodError ||
                    AuxEngineMaxSFOCError ||
                    BatchConsumptionObjectError ||
                    SpecialOpsObjectError) &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        cargoReliquificationSubmit(values);
                    }
                    }
                    initialValues={CargoReliquificationFormik.initialValues}
                    validationSchema={CargoReliquificationFormik.validationScehma}
                >
                    {({ values, errors, handleSubmit, handleChange }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='px-2 py-0'>
                                {
                                    (machineryFuelGroup && machineryFuelGroup?.length > 0) &&
                                    <Row className='p-0'>
                                        <Card className='mb-0'>
                                            <CardHeader className='p-2'>
                                                <div className="text-center">
                                                    <Row>
                                                        <Col className='ps-0'>
                                                            <h4 className="page_title pos-start mb-0">Fuel consumption</h4>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="p-0">
                                                <div className="table-responsive p-0">
                                                    <table className="table mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className='align-middle'>Sr. No.</th>
                                                                <th className="text-center align-middle">Machinery name</th>
                                                                {
                                                                    fuelTypes && fuelTypes?.map((fuelType: any, index: number) => {
                                                                        return (
                                                                            <th key={index} className="asteric text-center align-middle">{fuelType.fuel_type_name}</th>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {machineryFuelGroup.map((vesselMachinery: any, index: number) => {
                                                                return (
                                                                    <tr key={vesselMachinery.id}>
                                                                        <td className='align-middle'>{index + 1}</td>
                                                                        <td className='align-middle'>{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                                                        <FieldArray name='fuelConsumption'>
                                                                            {() => (
                                                                                <>
                                                                                    {values?.fuelConsumption
                                                                                        .filter((item: any) => item.vessel_machinery_fc_group === vesselMachinery.vessel_machinery_fc_group)
                                                                                        .map((fuelConsumptions: any, index: number) => {
                                                                                            return (
                                                                                                <td className='align-middle'>
                                                                                                    <div className="input-group">
                                                                                                        <Field
                                                                                                            name={
                                                                                                                `fuelConsumption.${values.fuelConsumption.indexOf(fuelConsumptions)}.fuel_consumption_value`
                                                                                                            }>
                                                                                                            {({ field }: FieldProps) => (
                                                                                                                <Input
                                                                                                                    type="text"
                                                                                                                    className="form-control text-right max-width-7"
                                                                                                                    id={fuelConsumptions.fuel_type}
                                                                                                                    onChange={(e: any) => {
                                                                                                                        handleChange(e);
                                                                                                                        if (values?.fuelConsumption[values.fuelConsumption.indexOf(fuelConsumptions)]?.vessel_fuel === FuelTypes.FUEL_OIL) {
                                                                                                                            setTotalConsumption(e.target.value);
                                                                                                                            setFuelConsumed(fuelConsumptions);
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    onBlur={(e: any) => {
                                                                                                                        handleChange(e);
                                                                                                                        if (values?.fuelSubTypeConsumption?.length > 0 &&
                                                                                                                            values?.fuelConsumption[values?.fuelConsumption?.indexOf(fuelConsumptions)]?.vessel_fuel === FuelTypes.FUEL_OIL &&
                                                                                                                            e.target.value > 0) {
                                                                                                                            setTotalConsumption(e.target.value);
                                                                                                                            setFuelConsumed(fuelConsumptions);
                                                                                                                            setFuelSubTypeConsumption(true);
                                                                                                                        } else {
                                                                                                                            setFuelSubTypeConsumption(false);
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    defaultValue={values.fuelConsumption[values.fuelConsumption.indexOf(fuelConsumptions)]?.total_fuel_consumption}
                                                                                                                />
                                                                                                            )}
                                                                                                        </Field>
                                                                                                        <div className="input-group-text">mt</div>
                                                                                                    </div>
                                                                                                    {values?.fuelConsumption[values?.fuelConsumption?.indexOf(fuelConsumptions)]?.total_fuel_consumption > 0 &&
                                                                                                        <SpOpFuelBatchConsumption
                                                                                                            state={fuelSubTypeConsumption}
                                                                                                            setState={setFuelSubTypeConsumption}
                                                                                                            values={values}
                                                                                                            id={index}
                                                                                                            machinery={values?.fuelSubTypeConsumption
                                                                                                                .filter((item: any) => item.vessel_machinery_fc_group ===
                                                                                                                    fuelConsumed.vessel_machinery_fc_group)}
                                                                                                            totalConsumption={values?.fuelConsumption[values?.fuelConsumption?.indexOf(fuelConsumptions)]?.total_fuel_consumption}
                                                                                                            error={errors}
                                                                                                            array={values?.fuelSubTypeConsumption}
                                                                                                        />
                                                                                                    }
                                                                                                </td>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </FieldArray>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Row>
                                }
                                {
                                    (withkWhMeter && withkWhMeter?.length > 0) &&
                                    <Row className='p-0'>
                                        <Card className='mb-0'>
                                            <CardHeader className='p-2'>
                                                <div className="text-center">
                                                    <Row>
                                                        <Col className='ps-0'>
                                                            <h4 className="page_title pos-start mb-0">Energy consumption</h4>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="p-0">
                                                <div className="table-responsive p-0">
                                                    <table className="table mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="ps-3">Sr. No.</th>
                                                                <th className="text-center">Machinery name</th>
                                                                <th className="asteric text-center">Energy consumed</th>
                                                                <th className="text-center">Equivalent fuel consumption</th>
                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {withkWhMeter.map((vesselMachinery: any, index: number) => {
                                                                return (
                                                                    <FieldArray name='withkWhMeter'>
                                                                        {() => (
                                                                            <>
                                                                                <FieldArray name='functional'>
                                                                                    {() => (
                                                                                        <>
                                                                                            {values?.withkWhMeter.functional
                                                                                                .filter((item: any) => item.vessel_machinery_ec_group === vesselMachinery.vessel_machinery_ec_group)
                                                                                                .map((energyConsumptions: any, index: number) => (
                                                                                                    <>
                                                                                                        <tr key={vesselMachinery.id}>
                                                                                                            <td className='ps-3 align-middle'>{index + 1}</td>
                                                                                                            <td className='align-middle'>{vesselMachinery.vessel_machinery_ec_group_name}</td>
                                                                                                            <td className='align-middle text-center'>
                                                                                                                <div className='d-inline-block'>
                                                                                                                    <div className="input-group">
                                                                                                                        <Field name={`withkWhMeter.functional.${values.withkWhMeter.functional.indexOf(energyConsumptions)}.energy_consumed`}>
                                                                                                                            {({ field, form }: FieldProps) => (
                                                                                                                                <Input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control text-right max-width-7"
                                                                                                                                    id={energyConsumptions.fuel_type}
                                                                                                                                    name={field.name}
                                                                                                                                    onBlur={(e: any) => {
                                                                                                                                        handleChange(e);
                                                                                                                                        form.setFieldValue(`withkWhMeter.functional.${values.withkWhMeter.functional.indexOf(energyConsumptions)}.equivalent_fuel_consumption`,
                                                                                                                                            AuxEngineMaxSFOC.max_eedi_eexi_technical_file * (e.target.value) / 1000000);
                                                                                                                                    }}
                                                                                                                                    defaultValue={
                                                                                                                                        values?.withkWhMeter?.functional[values.withkWhMeter.functional.indexOf(energyConsumptions)]?.energy_consumed
                                                                                                                                    }
                                                                                                                                    disabled={values?.withkWhMeter?.functional[values.withkWhMeter.functional.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true ? true : false}
                                                                                                                                    value={((ReliquificationEnergyObject && ReliquificationEnergyObject?.length > 0) && values?.withkWhMeter?.functional
                                                                                                                                    [values.withkWhMeter.functional.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true) ?
                                                                                                                                        values?.withkWhMeter?.functional
                                                                                                                                        [values.withkWhMeter.functional.indexOf(energyConsumptions)]?.energy_consumed :
                                                                                                                                        values?.withkWhMeter?.functional
                                                                                                                                        [values.withkWhMeter.functional.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true ?
                                                                                                                                            loadwsum(values.withkWhMeter.nonfunctional.filter((item: any) =>
                                                                                                                                                item.vessel_machinery_name === energyConsumptions.vessel_machinery_ec_group), field.name, form)
                                                                                                                                            : null}
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        </Field>
                                                                                                                        <div className="input-group-text">kWh</div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className='text-center align-middle'>
                                                                                                                <Label>{(AuxEngineMaxSFOC.max_eedi_eexi_technical_file *
                                                                                                                    values.withkWhMeter.functional[values.withkWhMeter.functional.indexOf(energyConsumptions)].energy_consumed / 1000000).toFixed(4)}</Label>
                                                                                                            </td>
                                                                                                            <td className="align-middle text-center">
                                                                                                                <Button
                                                                                                                    color={
                                                                                                                        values?.withkWhMeter?.functional[values.withkWhMeter.functional.indexOf(energyConsumptions)]
                                                                                                                            .is_kwh_meter_not_functional === true ?
                                                                                                                            'danger' : 'primary'
                                                                                                                    }
                                                                                                                    className='btn'
                                                                                                                    onClick={() => {
                                                                                                                        setModalMachinery(vesselMachinery);
                                                                                                                        setModalIndex(values.withkWhMeter.functional.indexOf(energyConsumptions));
                                                                                                                        tog_backdrop();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    Sensor failure
                                                                                                                </Button>
                                                                                                                <Modal
                                                                                                                    isOpen={modal_backdrop}
                                                                                                                    toggle={() => {
                                                                                                                        tog_backdrop();
                                                                                                                    }}
                                                                                                                    backdrop={"static"}
                                                                                                                    id="staticBackdrop"
                                                                                                                    size={values.withkWhMeter.functional[modalIndex]?.is_kwh_meter_not_functional === true ? 'xl' : 'md'}
                                                                                                                >
                                                                                                                    <div className="modal-header">
                                                                                                                        <h5 className="modal-title text-center" id="staticBackdropLabel">
                                                                                                                            Action
                                                                                                                        </h5>
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn-close"
                                                                                                                            onClick={() => {
                                                                                                                                setmodal_backdrop(false);
                                                                                                                            }}
                                                                                                                            aria-label="Close"
                                                                                                                        ></button>
                                                                                                                    </div>
                                                                                                                    <div className="modal-body">
                                                                                                                        <strong>Please Note -</strong>
                                                                                                                        <p>{TooltipMsg.Special_Operations
                                                                                                                            .filter((item: any) => item.target ===
                                                                                                                                'is_kWh_meter_functional_msg')
                                                                                                                            .map((tool: any) => { return tool.message })}</p>
                                                                                                                        <div className="d-flex flex-wrap gap-2 align-items-center">
                                                                                                                            <Label htmlFor={`withkWhMeter.functional.${modalIndex}.is_kwh_meter_not_functional`}>
                                                                                                                                kWh meter faliure?
                                                                                                                            </Label>
                                                                                                                            <div className="square-switch sqswitch d-flex align-items-center">
                                                                                                                                <Field name={`withkWhMeter.functional.${modalIndex}.is_kwh_meter_not_functional`}>
                                                                                                                                    {({ field, form }: FieldProps) => (
                                                                                                                                        <Input
                                                                                                                                            type="checkbox"
                                                                                                                                            switch="none"
                                                                                                                                            name={field.name}
                                                                                                                                            id={field.name}
                                                                                                                                            onChange={(e: any) => handleChange(e)}
                                                                                                                                            defaultChecked={values.withkWhMeter.functional[modalIndex].is_kwh_meter_not_functional}
                                                                                                                                        />
                                                                                                                                    )}
                                                                                                                                </Field>
                                                                                                                                <Label htmlFor={`withkWhMeter.functional.${modalIndex}.is_kwh_meter_not_functional`}
                                                                                                                                    data-on-label='Yes' data-off-label='No'>
                                                                                                                                </Label>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {values.withkWhMeter.functional[modalIndex]?.is_kwh_meter_not_functional === true &&
                                                                                                                            <FourHrTableComponent
                                                                                                                                vesselMachinery={modalMachinery}
                                                                                                                                lastRecord={lastRecord}
                                                                                                                                record={record}
                                                                                                                                tableHeader={'Hours of cargo reliquification'}
                                                                                                                                fieldName={'hours_of_cargo_reliquification'}
                                                                                                                                handleChange={handleChange}
                                                                                                                                valueName={'withkWhMeter.nonfunctional'}
                                                                                                                                valueSubName={values?.withkWhMeter?.nonfunctional}
                                                                                                                                energyConsumedField={`withkWhMeter.functional[${modalIndex}].energy_consumed`}
                                                                                                                                showMachineryName={false}
                                                                                                                            />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </Modal>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </>
                                                                                    )}
                                                                                </FieldArray>
                                                                            </>
                                                                        )}
                                                                    </FieldArray>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Row>
                                }
                                {withoutkWhMeter && withoutkWhMeter?.length > 0 &&
                                    <Row className='p-0'>
                                        <Card className='mb-0'>
                                            <CardHeader className='p-2'>
                                                <div className="text-center">
                                                    <Row>
                                                        <Col className='ps-0'>
                                                            <h4 className="page_title pos-start mb-0">Energy consumption without kWh meter</h4>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="p-0">
                                                {withoutkWhMeter.map((vesselMachinery: any) => {
                                                    return (
                                                        <>
                                                            <FourHrTableComponent
                                                                vesselMachinery={vesselMachinery}
                                                                lastRecord={lastRecord}
                                                                record={record}
                                                                tableHeader={'Hours of cargo reliquification'}
                                                                fieldName={'hours_of_cargo_reliquification'}
                                                                handleChange={handleChange}
                                                                valueName={'withoutkWhMeter'}
                                                                valueSubName={values?.withoutkWhMeter}
                                                                showMachineryName={true}
                                                                energyConsumedField={''}
                                                            />
                                                        </>
                                                    )
                                                })}
                                            </CardBody >
                                        </Card>
                                    </Row>
                                }
                            </CardBody>
                            <Row className="ele_row1">
                                <div className="d-flex flex-wrap gap-5 mt-3 btn_Margin">
                                    <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                        if (env?.form_validation === false) {
                                            toggleDynamicTabs(previousTabIndex + 1);
                                        }
                                    }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                    {tabsIdList[0] === tabsList
                                        .filter((item: any) => item.tab === 'Special Operations - Cargo Reliquification')[0]?.id &&
                                        (record && record.operation_precedence_id === 1) ?
                                        <Button type="button" color="primary" className="btn_size_cstm pos-start-0_75" onClick={(e) => {
                                            toggleTab(tabsList[0]?.id - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button> :
                                        tabsIdList[0] === tabsList
                                            .filter((item: any) => item.tab === 'Special Operations - Cargo Reliquification')[0]?.id &&
                                            (record && record.operation_precedence_id !== 1) ?
                                            <Button type="button" color="primary" className="btn_size_cstm pos-start-0_75" onClick={(e) => {
                                                toggleTab(tabsList[0]?.id - 3);
                                            }}><i className="bx bx-chevron-left me-1" /> Previous</Button> :
                                            <Button type="button" color="primary" className="btn_size_cstm pos-start-0_75" onClick={(e) => {
                                                toggleDynamicTabs(previousTabIndex - 1);
                                            }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    }
                                </div>
                            </Row>
                            <FormValuesDebug values={[values, errors, CargoReliquificationFormik.initialValues]} />
                        </Form>
                    )}
                </Formik >
            }
        </Card>
    )
}

export default CargoReliquificationComponent