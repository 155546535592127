import apiGlobal from '../../../global/api.global';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, Label, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { errorToast, successToast } from '../../../Components/Toasts';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadSpecialOperationEnergySettings, loadSpecialOperationFuelSettings } from 'VesselMaster/vesselMaster.hooks';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { Formik, FieldArray, Field, FieldProps } from 'formik';
import { errResponse } from 'GenericForms/Helper';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';

const GenerelSetting = () => {
  const VesselID = useSelector((state: RootState) => state.Reporting.VesselID);
  const Vessels = useSelector((state: RootState) => state.Reporting.Vessels);
  const vessel = Vessels.find((rec: any) => rec.id === VesselID);
  const { data: SpecialOperationFcObject, isLoading: SpecialOperationFcObjectLoading, isError: SpecialOperationFcError }:
    { data: any[], isLoading: any, isError: any } = useQuery(
      [queryKeyes.vessel.SpecialOperationFuelSettings, VesselID],
      async () => { return await loadSpecialOperationFuelSettings(VesselID) },
      {
        enabled: true,
        staleTime: Infinity,
      }
    );

  const { data: vesselMachineryEC, isLoading: vesselMachineryECLoading, isError: vesselMachineryECError }:
    { data: any[], isLoading: any, isError: any } = useQuery(
      [queryKeyes.vessel.SpecialOperationEnergySettings, VesselID],
      async () => { return await loadSpecialOperationEnergySettings(VesselID) },
      {
        enabled: true,
        staleTime: Infinity,
      }
    );

  const getInitialValueSpecialOperationFcForm = () => {
    if (SpecialOperationFcObject?.length > 0 && SpecialOperationFcObject[0].id > 0) {
      return SpecialOperationFcObject;
    } else {
      return [];
    }
  };

  const getInitialValueSpecialOperationEcForm = () => {
    if (vesselMachineryEC?.length > 0 && vesselMachineryEC[0].id > 0) {
      return vesselMachineryEC;
    } else {
      return [];
    }
  };

  const generelSettingFCFormik = {
    initialValues: {
      SpecialOperationFc: getInitialValueSpecialOperationFcForm(),
    },
    validationSchema: {},
  };

  const generelSettingECFormik = {
    initialValues: {
      SpecialOperationEc: getInitialValueSpecialOperationEcForm(),
    },
    validationSchema: {},
  };
  return (
    <>
      <Card className=''>
        <CardHeader className='p-2'>
          <h5 className="mb-0">Fuel Consumption</h5>
        </CardHeader>
        <CardBody>
          {SpecialOperationFcObjectLoading && <Loading message="Loading required data!" />}
          {SpecialOperationFcError && <ErrorComponent message="Unable to load required data!" />}
          {!SpecialOperationFcObjectLoading && !SpecialOperationFcError && (
            <Formik
              onSubmit={(values: any, actions: any) => {
                actions.setSubmitting(false);
                const handleResponse = (response: any) => {
                  if (response.status === 200) {
                    successToast("Data saved successfully!");
                  }
                };
                console.log(values);
                values?.SpecialOperationFc?.forEach((machine: any) => {
                  apiGlobal
                    .put(`/special_operation_fuel_setting/${machine.id}/`, machine)
                    .then((res) => {
                      handleResponse(res);
                    })
                    .catch((err) => {
                      if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occurred, please contact the admin");
                      }
                    });
                });
              }}
              initialValues={generelSettingFCFormik.initialValues}
            // validationSchema={generelSettingFCFormik.validationSchema}
            >
              {(props: any) => (
                <Form onSubmit={props?.handleSubmit} noValidate autoComplete="off">
                  {vessel && (
                    (vessel.vessel_type === 'Tanker' || vessel.vessel_type === 'Container' || vessel.vessel_type === 'Gas Carrier' || vessel.vessel_type === 'LNG Carrier') ? (
                      <>
                        {props?.values?.SpecialOperationFc?.length > 0 && (
                          <Row>
                            <Col className='p-0'>
                              {vessel.vessel_type === 'Tanker' && (
                                <div className="table-responsive mb-5">
                                  <table className="table">
                                    <thead className="table-light">
                                      <tr>
                                        <th className='p-2 align-middle sr-no-width'>#</th>
                                        <th className='p-2 align-middle'>Machinery name</th>
                                        <th className='p-2 align-middle text-center'>Cargo heating</th>
                                        <th className='p-2 align-middle text-center'>Tank cleaning</th>
                                        <th className='p-2 align-middle text-center'>Cargo discharging</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray name="SpecialOperationFc">
                                        {() => (
                                          <>
                                            {props.values.SpecialOperationFc.map((vesselMachinery: any, index: number) => (
                                              <tr key={vesselMachinery.id}>
                                                <td className='p-2 text-center align-middle'>{index + 1}</td>
                                                <td className='p-2 align-middle'>{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationFc.${index}.cargo_heating`} >
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`chckbx1_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationFc?.[index]?.cargo_heating}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationFc.${index}.tank_cleaning`} >
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`chckbx2_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationFc?.[index]?.tank_cleaning}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationFc.${index}.cargo_discharging`} >
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`chckbx3_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationFc?.[index]?.cargo_discharging}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        )}
                                      </FieldArray>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              {vessel.vessel_type === 'Container' && (
                                <div className="table-responsive mb-5">
                                  <table className="table">
                                    <thead className="table-light">
                                      <tr>
                                        <th className='p-2 align-middle sr-no-width'>#</th>
                                        <th className='p-2 align-middle text-center'>Machinery name</th>
                                        <th className='p-2 align-middle text-center'>Reefer Container</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray name="SpecialOperationFc">
                                        {() => (
                                          <>
                                            {props.values.SpecialOperationFc.map((vesselMachinery: any, index: number) => (
                                              <tr key={vesselMachinery.id}>
                                                <td className='p-2 text-center align-middle'>{index + 1}</td>
                                                <td className='p-2 align-middle'>{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationFc.${index}.reefer_container`} >
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`chckbx1_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationFc?.[index]?.reefer_container}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        )}
                                      </FieldArray>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              {(vessel.vessel_type === 'Gas Carrier' || vessel.vessel_type === 'LNG Carrier') && (
                                <div className="table-responsive mb-5">
                                  <table className="table">
                                    <thead className="table-light">
                                      <tr>
                                        <th className='p-2 align-middle sr-no-width'>#</th>
                                        <th className='p-2 align-middle'>Machinery name</th>
                                        <th className='p-2 align-middle text-center'>Cargo Cooling</th>
                                        <th className='p-2 align-middle text-center'>Cargo Reliquification</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray name="SpecialOperationFc">
                                        {() => (
                                          <>
                                            {props.values.SpecialOperationFc.map((vesselMachinery: any, index: number) => (
                                              <tr key={vesselMachinery.id}>
                                                <td className='p-2 text-center align-middle'>{index + 1}</td>
                                                <td className='p-2 align-middle'>{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationFc.${index}.cargo_cooling`} >
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`chckbx1_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationFc?.[index]?.cargo_cooling}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationFc.${index}.cargo_reliquification`} >
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`chckbx2_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationFc?.[index]?.cargo_reliquification}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        )}
                                      </FieldArray>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              {vessel.vessel_type && (
                                <><CardFooter className='p-2 py-3 mb-3'>
                                  <Row>
                                    <Col className='pb-3'>
                                      <Button type="submit" color='primary' className='pos-end'>Save</Button>
                                    </Col>
                                  </Row>
                                </CardFooter><Row className='mt-2'>
                                    <FormValuesDebug values={[props.values, props.errors, generelSettingFCFormik.initialValues]} />
                                  </Row></>
                              )}
                            </Col>
                          </Row>
                        )}
                      </>
                    ) : (
                      <h6>No data found.</h6>
                    )
                  )}
                </Form>
              )}
            </Formik>
          )}
        </CardBody>
      </Card>
      <Card className='border-2'>
        <CardHeader>
          <h6 className="mb-0">Energy Consumption</h6>
        </CardHeader>
        <CardBody>

          {vesselMachineryECLoading && <Loading message="Loading required data!" />}
          {vesselMachineryECError && <ErrorComponent message="Unable to load required data!" />}
          {!vesselMachineryECLoading && !vesselMachineryECError && (
            <Formik
              onSubmit={(values: any, actions: any) => {
                actions.setSubmitting(false);
                const handleResponse = (response: any) => {
                  if (response.status === 200) {
                    successToast("Data saved successfully!");
                  }
                };
                console.log(values);
                values?.SpecialOperationEc?.forEach((machine: any) => {
                  apiGlobal
                    .put(`/special_operation_energy_setting/${machine.id}/`, machine)
                    .then((res) => {
                      handleResponse(res);
                    })
                    .catch((err) => {
                      if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occurred, please contact the admin");
                      }
                    });
                });
              }}
              initialValues={generelSettingECFormik.initialValues}
            >
              {(props: any) => (
                <Form onSubmit={props?.handleSubmit} noValidate autoComplete="off">
                  {vessel && (
                    (vessel.vessel_type === 'Tanker' ||
                      vessel.vessel_type === 'Container' ||
                      vessel.vessel_type === 'Gas Carrier' ||
                      vessel.vessel_type === 'LNG Carrier') ? (
                      <>
                        {props?.values?.SpecialOperationEc?.length > 0 && (
                          <Row>
                            <Col>
                              {vessel.vessel_type === 'Tanker' && (
                                <div className="table-responsive mb-5">
                                  <table className="table">
                                    <thead className="table-light">
                                      <tr>
                                        <th className='p-2 align-middle sr-no-width'>#</th>
                                        <th className='p-2 align-middle'>Machinery name</th>
                                        <th className='p-2 align-middle text-center'>Cargo heating</th>
                                        <th className='p-2 align-middle text-center'>Tank cleaning</th>
                                        <th className='p-2 align-middle text-center'>Cargo discharging</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray name="SpecialOperationEc">
                                        {() => (
                                          <>
                                            {props.values.SpecialOperationEc.map((vesselMachinery: any, index: number) => (
                                              <tr key={vesselMachinery.id}>
                                                <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                <td className='p-2 align-middle'>{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationEc.${index}.cargo_heating`}>
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`cargo_heating_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationEc?.[index]?.cargo_heating}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationEc.${index}.tank_cleaning`}>
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`tank_cleaning_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationEc?.[index]?.tank_cleaning}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationEc.${index}.cargo_discharging`}>
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`cargo_discharging_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationEc?.[index]?.cargo_discharging}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        )}
                                      </FieldArray>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              {vessel.vessel_type === 'Container' && (
                                <div className="table-responsive mb-5">
                                  <table className="table">
                                    <thead className="table-light">
                                      <tr>
                                        <th className='p-2 align-middle sr-no-width'>#</th>
                                        <th className='p-2 align-middle'>Machinery name</th>
                                        <th className='p-2 align-middle text-center'>Reefer Container</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray name="SpecialOperationFc">
                                        {() => (
                                          <>
                                            {props.values.SpecialOperationEc.map((vesselMachinery: any, index: number) => (
                                              <tr key={vesselMachinery.id}>
                                                <td className='p-2 text-center align-middle'>{index + 1}</td>
                                                <td className='p-2 align-middle'>{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationEc.${index}.reefer_container`}>
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`reefer_container_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationEc?.[index]?.reefer_container}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        )}
                                      </FieldArray>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              {(vessel.vessel_type === 'Gas Carrier' || vessel.vessel_type === 'LNG Carrier') && (
                                <div className="table-responsive mb-5">
                                  <table className="table">
                                    <thead className="table-light">
                                      <tr>
                                        <th className='p-2 align-middle sr-no-width'>#</th>
                                        <th className='p-2 align-middle'>Machinery name</th>
                                        <th className='p-2 align-middle text-center'>Cargo Cooling</th>
                                        <th className='p-2 align-middle text-center'>Cargo Reliquification</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray name="SpecialOperationFc">
                                        {() => (
                                          <>
                                            {props.values.SpecialOperationEc.map((vesselMachinery: any, index: number) => (
                                              <tr key={vesselMachinery.id}>
                                                <td className='p-2 text-center align-middle'>{index + 1}</td>
                                                <td className='p-2 align-middle'>{vesselMachinery.vessel_machinery_ec_group_name}</td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationEc.${index}.cargo_cooling`}>
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`cargo_cooling_${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationEc?.[index]?.cargo_cooling}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                                <td className='p-2 text-center align-middle'>
                                                  <div className="form-check d-inline-block">
                                                    <Field name={`SpecialOperationEc.${index}.cargo_reliquification`}>
                                                      {({ field }: FieldProps) => (
                                                        <Input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`cargo_reliquification${index}`}
                                                          name={field.name}
                                                          onChange={(e: any) => props?.handleChange(e)}
                                                          defaultChecked={props?.values?.SpecialOperationEc?.[index]?.cargo_reliquification}
                                                        />
                                                      )}
                                                    </Field>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        )}
                                      </FieldArray>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              {vessel.vessel_type && (
                                <><CardFooter className='p-2 py-3 mb-3'>
                                  <Row>
                                    <Col className='pb-3'>
                                      <Button type="submit" color='primary' className='pos-end'>Save</Button>
                                    </Col>
                                  </Row>
                                </CardFooter><Row className='mt-2'>
                                    <FormValuesDebug values={[props.values, props.errors, generelSettingECFormik.initialValues]} />
                                  </Row></>
                              )}
                            </Col>
                          </Row>
                        )}
                      </>
                    ) : (
                      <Label>Please add energy consumer machinery to this vessel</Label>
                    )
                  )}
                </Form>
              )}
            </Formik>
          )}
        </CardBody>
      </Card>
    </>
  )
}
export default GenerelSetting;
