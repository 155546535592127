import React, { useState } from "react";
import { CardBody, Card, TabContent, TabPane, Col, Container, Row, Input, Label, Button, Form, CardHeader, CardFooter } from "reactstrap";
import Layout from "../../HorizontalMenu/Menu";
import Navbar from "../../HorizontalMenu/Navbar";
import '../../global/GlobalCSS.css';
import apiGlobal from "../../global/api.global";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../';
import { VesselState, setVesselState } from "../../Store/Generic/ReportingSlice";
import VesselDetailsHeader from "../../Components/VesselDetailsHeader";
import ReportDetailsHeader from "../../Components/ReportDetailsHeader";
import { PreviousVesselReportingInfo } from "../../VesselMaster/vesselMaster.hooks";
import { queryKeyes } from "shared/queryKeys";
import { useQuery } from "react-query";
import { successToast } from "Components/Toasts";
import FuelConsumptionComponent from "./partials/FuelConsumptionComponent";
import VoyageParameterComponent from "./partials/VoyageParameterComponent";
import EnergyParameterCompenent from "./partials/EnergyParameterCompenent";
import DebunkeringComponent from "./partials/DebunkeringComponent";
import LubeOilROBComponent from "./partials/LubeOilROBComponent";
import LubeOilBunkeringComponent from "./partials/LubeOilBunkeringComponent";
import LubeOilDebunkeringComponent from "./partials/LubeOilDebunkeringComponent";
import FreshWaterComponent from "./partials/FreshWaterComponent";
import CargoDischargingComponent from "./partials/CargoDischargingComponent";
import RunningHoursComponent from "./partials/RunningHoursComponent";
import LfondsAddInformation from "./AdditionalInformation/Lfonds/LfondsAddInformation";
import CargoHeatingComponent from "./partials/CargoHeatingComponent";
import TankCleaningComponent from "./partials/TankCleaningComponent";
import ReeferContainerComponent from "./partials/ReeferContainerComponent";
import CargoCoolingComponent from "./partials/CargoCoolingComponent";
import CargoReliquificationComponent from "./partials/CargoReliquificationComponent";
import OtherSpecialOperationComponent from "./partials/OtherSpecialOperationComponent";
import { AddInfoConstant, OperationPrecedence, VesselTypeConstant } from "shared/constants";
import env from "environment/env";
import ProactiveAddInformation from "./AdditionalInformation/Proactive/ProactiveAddInformation";
import Bunkering from "./partials/Bunkering/Bunkering";

const EngineerWizard = () => {
    const [activeTab, setactiveTab] = useState(1);
    let [totalTabs, setTotalTabs] = useState(6);
    const [previousTabIndex, setPreviousTabIndex] = useState(0);
    const [tabsIdList, setTabsIdList] = useState<Array<any>>([18, 19, 20]);
    const [tabsList] = useState<Array<any>>([
        { id: 7, tab: 'Special Operations - Cargo Heating', group: 1, vessel_type: [VesselTypeConstant.TANKER] },
        { id: 8, tab: 'Special Operations - Tank Cleaning', group: 2, vessel_type: [VesselTypeConstant.TANKER] },
        { id: 9, tab: 'Special Operations - Cargo Discharging', group: 1, vessel_type: [VesselTypeConstant.TANKER] },
        { id: 10, tab: 'Special Operations - Reefer Container', group: 1, vessel_type: [VesselTypeConstant.CONTAINER, VesselTypeConstant.GENERAL_CARGO_SHIP] },
        { id: 11, tab: 'Special Operations - Cargo Cooling', group: 1, vessel_type: [VesselTypeConstant.GAS_CARRIER, VesselTypeConstant.LNG_CARRIER] },
        { id: 12, tab: 'Special Operations - Cargo Reliquification', group: 1, vessel_type: [VesselTypeConstant.GAS_CARRIER, VesselTypeConstant.LNG_CARRIER] },
        { id: 13, tab: 'Other Special Operation', group: 2, vessel_type: [VesselTypeConstant.TANKER, VesselTypeConstant.CONTAINER, VesselTypeConstant.GAS_CARRIER, VesselTypeConstant.LNG_CARRIER, 'Other'] },
        { id: 14, tab: 'Bunkering', group: 3, vessel_type: ['Other'] },
        { id: 15, tab: 'Fuel Debunkering', group: 3, vessel_type: ['Other'] },
        { id: 16, tab: 'Lube Oil Bunkering', group: 3, vessel_type: ['Other'] },
        { id: 17, tab: 'Lube Oil Debunkering', group: 3, vessel_type: ['Other'] }
    ]);
    const dispatch = useDispatch();
    const { VesselID, VoyageID, ReportID, Reports, Vessels } = useSelector((state: RootState) => state.Reporting);
    const record = Reports.find((rec: any) => rec.id === ReportID);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    const toggleTab = (tab: number) => {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= totalTabs) {
                setactiveTab(tab);
            }
        }
    }

    const toggleDynamicTabs = (tab: any) => {
        setPreviousTabIndex(tab);
        setactiveTab(tabsIdList[tab]);
    }

    const { data: lastRecord } = useQuery([queryKeyes.vessel.PreviousVesselReport.key, ReportID, VesselID], async () => {
        return await PreviousVesselReportingInfo(ReportID, VesselID);
    }, { staleTime: Infinity })

    const handleResponse = (response: any) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
        }
    }

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    function compareNumbers(a: number, b: number) {
        return a - b;
    }

    const handleTabsList = (e: any, item: any, index: number) => {
        let data = [...tabsIdList];
        if (e.target.checked === true) {
            data.push(item.id);
            data.sort(compareNumbers);
        } else {
            let idx = data.indexOf(item.id);
            data.splice(idx, 1);
            data.sort(compareNumbers);
        }
        if (record && record.operation_precedence_id === 1) {
            setTotalTabs(5 + (data.length - 1));
        } else {
            setTotalTabs(3 + (data.length - 1));
        }
        data.sort(compareNumbers);
        setTabsIdList([...data]);
    }

    const handleSaveandComplete = async () => {
        let report = { ...record }
        report.engineer_reporting_status = 3;
        try {
            const response = await apiGlobal.put(`/vessel_reporting_information/${ReportID}/`, report);
            handleResponse(response);
            handleVesselState('VESSEL_REPORTING');
            return response.data
        } catch (err) {
            return null;
        }
    }

    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardHeader className="p-2">
                            <Row>
                                <Col sm={2}>
                                    <button color='primary' className='btn btn-primary margin-top-1' onClick={() => { handleVesselState('VESSEL_REPORTING') }}>
                                        <i className="bx bx-chevron-left me-1" /> Back
                                    </button>
                                </Col>
                                <Col sm="10">
                                    <VesselDetailsHeader />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                <TabContent
                                    activeTab={activeTab}                                >
                                    <TabPane tabId={record && record.operation_precedence_id === OperationPrecedence.SEA_PASSAGE ? 1 : 0}>
                                        <VoyageParameterComponent
                                            key={'voyageParameterComponent'}
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                        />
                                    </TabPane>
                                    <TabPane tabId={tabsList.filter((item: any) => item.tab === 'Bunkering')[0]?.id}>
                                        <Bunkering
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                            tabsIdList={tabsIdList}
                                            record={record}
                                            toggleDynamicTabs={toggleDynamicTabs}
                                            previousTabIndex={previousTabIndex}
                                            lastRecord={lastRecord}
                                            tabsList={tabsList}
                                        />
                                    </TabPane>
                                    <TabPane tabId={tabsList.filter((item: any) => item.tab === 'Fuel Debunkering')[0]?.id}>
                                        <DebunkeringComponent
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                            tabsIdList={tabsIdList}
                                            record={record}
                                            toggleDynamicTabs={toggleDynamicTabs}
                                            previousTabIndex={previousTabIndex}
                                            lastRecord={lastRecord}
                                            tabsList={tabsList}
                                        />
                                    </TabPane>
                                    <TabPane tabId={record && record.operation_precedence_id === OperationPrecedence.SEA_PASSAGE ? 2 : 1}>
                                        <FuelConsumptionComponent
                                            key={'fuelConsuptionComponent'}
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                            vessel={vessel}
                                        />
                                    </TabPane>
                                    <TabPane tabId={record && record.operation_precedence_id === OperationPrecedence.SEA_PASSAGE ? 4 : 2}>
                                        <RunningHoursComponent
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                        />
                                    </TabPane>
                                    <TabPane tabId={record && record.operation_precedence_id === OperationPrecedence.SEA_PASSAGE ? 5 : 3}>
                                        <LubeOilROBComponent
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                        />
                                    </TabPane>
                                    <TabPane tabId={record && record.operation_precedence_id === OperationPrecedence.SEA_PASSAGE ? 6 : 4}>
                                        <Card className='p-0 mb-0 border-0'>
                                            <CardHeader className='p-2 border-bottom-0'>
                                                <div className="text-center">
                                                    <Row>
                                                        <Col>
                                                            <h4 className="page_title pos-start mb-0">Special Operations</h4>
                                                            <p className="card-title-desc pos-start">All readings since last report</p>
                                                        </Col>
                                                        <Col>
                                                            <ReportDetailsHeader />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardHeader>
                                            <Form className="needs-validation" autoComplete="off"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                }}
                                            >
                                                <CardBody className='px-2 py-0'>
                                                    <Row>
                                                        <Col sm={12} className="p-0">
                                                            <table
                                                                className="table table-bordered responsiveTable mb-0 border-start-0 border-end-0 no-hover engineer-card-body">
                                                                <thead>
                                                                    <tr>
                                                                        <td className="border-start-0">
                                                                            <h5 className="mb-0">CII Special Operations</h5>
                                                                            <p className="card-title-desc pos-start">Select if carried out since last report</p>
                                                                        </td>
                                                                        <td>
                                                                            <h5 className="mb-0">Non CII Special Operations</h5>
                                                                            <p className="card-title-desc pos-start">Select if carried out since last report</p>
                                                                        </td>
                                                                        <td className="border-end-0">
                                                                            <h5 className="mb-0">Bunkering & Debunkering</h5>
                                                                            <p className="card-title-desc pos-start">Select if carried out since last report</p>
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="border-start-0 border-bottom-0">
                                                                            {/* {tabsList
                                                                                .filter((tabs: any) => tabs.group === 1 && tabs.vessel_type.includes(`${vessel && vessel.vessel_type}`))
                                                                                .length === 0 && <p>There are no CII Special Operations for {vessel?.vessel_type}</p>
                                                                            }
                                                                            {tabsList
                                                                                .filter((tabs: any) => tabs.group === 1 && tabs.vessel_type.includes(`${vessel && vessel.vessel_type}`))
                                                                                .map((item: any, index: number) => (
                                                                                    <Row key={index}>
                                                                                        <Col sm={1}>
                                                                                            <div className="form-check">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"
                                                                                                    id={`cii-${index}`}
                                                                                                    name={`cii-${index}`}
                                                                                                    onChange={(e: any) => handleTabsList(e, item, index)}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Label htmlFor={`cii-${index}`}>{item.tab}</Label>
                                                                                        </Col>
                                                                                    </Row>
                                                                                ))
                                                                            } */}
                                                                            <p>There are no CII Special Operations<br /> for {vessel?.vessel_name}</p>
                                                                        </td>
                                                                        <td className="border-bottom-0">
                                                                            {(() => {
                                                                                if (vessel && vessel.vessel_type === VesselTypeConstant.TANKER) {
                                                                                    return tabsList
                                                                                        .filter((tabs: any) => tabs.group === 2 && tabs.vessel_type.includes(VesselTypeConstant.TANKER))
                                                                                        .map((item: any, indx: number) => (
                                                                                            <Row key={indx}>
                                                                                                <Col sm={1}>
                                                                                                    <div className="form-check">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="checkbox"
                                                                                                            id={`non-cii-${indx}`}
                                                                                                            name={`non-cii-${indx}`}
                                                                                                            onChange={(e: any) => handleTabsList(e, item, indx)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Label htmlFor={`non-cii-${indx}`}>{item.tab}</Label>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        ));
                                                                                } else {
                                                                                    return tabsList
                                                                                        .filter((tabs: any) => tabs.group === 2 && tabs.vessel_type.includes('Other'))
                                                                                        .map((item: any, indx: number) => (
                                                                                            <Row key={indx}>
                                                                                                <Col sm={1}>
                                                                                                    <div className="form-check">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="checkbox"
                                                                                                            id={`non-cii-${indx}`}
                                                                                                            name={`non-cii-${indx}`}
                                                                                                            onChange={(e: any) => handleTabsList(e, item, indx)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Label htmlFor={`non-cii-${indx}`}>{item.tab}</Label>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        ));
                                                                                }
                                                                            })()}
                                                                        </td>
                                                                        <td className="border-end-0 border-bottom-0">
                                                                            {
                                                                                tabsList.filter((tabs: any) => tabs.group === 3).map((item: any, index: number) => {
                                                                                    return (
                                                                                        <Row key={index}>
                                                                                            <Col sm={1}>
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id={`bunkering-${index}`}
                                                                                                        name={`bunkering-${index}`}
                                                                                                        onChange={(e: any) => handleTabsList(e, item, index)}
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <Label htmlFor={`bunkering-${index}`}>{item.tab}</Label>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter className="p-2 py-3 border-top-0">
                                                    <Row className="ele_row1">
                                                        <div className="d-flex flex-wrap gap-5">
                                                            <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                                                toggleDynamicTabs(0);
                                                            }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                                            <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                                                toggleTab(activeTab - 1);
                                                            }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                                        </div>
                                                    </Row>
                                                </CardFooter>
                                            </Form>
                                        </Card>
                                    </TabPane>
                                    {vessel && vessel?.vessel_type === VesselTypeConstant.TANKER &&
                                        <>
                                            <TabPane tabId={tabsList.filter((item: any) =>
                                                item.tab === 'Special Operations - Cargo Heating')[0]?.id}>
                                                <CargoHeatingComponent
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    tabsIdList={tabsIdList}
                                                    record={record}
                                                    lastRecord={lastRecord}
                                                    toggleDynamicTabs={toggleDynamicTabs}
                                                    previousTabIndex={previousTabIndex}
                                                    tabsList={tabsList}
                                                />
                                            </TabPane>
                                            <TabPane tabId={tabsList.filter((item: any) =>
                                                item.tab === 'Special Operations - Tank Cleaning')[0]?.id}>
                                                <TankCleaningComponent
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    tabsIdList={tabsIdList}
                                                    record={record}
                                                    lastRecord={lastRecord}
                                                    toggleDynamicTabs={toggleDynamicTabs}
                                                    previousTabIndex={previousTabIndex}
                                                    tabsList={tabsList}
                                                />
                                            </TabPane>
                                            <TabPane tabId={tabsList.filter((item: any) =>
                                                item.tab === 'Special Operations - Cargo Discharging')[0]?.id}>
                                                <CargoDischargingComponent
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    tabsIdList={tabsIdList}
                                                    record={record}
                                                    lastRecord={lastRecord}
                                                    toggleDynamicTabs={toggleDynamicTabs}
                                                    previousTabIndex={previousTabIndex}
                                                    tabsList={tabsList}
                                                />
                                            </TabPane>
                                        </>
                                    }
                                    {vessel &&
                                        (vessel?.vessel_type === VesselTypeConstant.CONTAINER ||
                                            vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                                        <TabPane tabId={tabsList.filter((item: any) =>
                                            item.tab === 'Special Operations - Reefer Container')[0]?.id}>
                                            <ReeferContainerComponent
                                                ReportID={ReportID}
                                                VesselID={VesselID}
                                                VoyageID={VoyageID}
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                                tabsIdList={tabsIdList}
                                                record={record}
                                                lastRecord={lastRecord}
                                                toggleDynamicTabs={toggleDynamicTabs}
                                                previousTabIndex={previousTabIndex}
                                                tabsList={tabsList}
                                            />
                                        </TabPane>
                                    }
                                    {vessel && (vessel?.vessel_type === VesselTypeConstant.GAS_CARRIER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) &&
                                        <>
                                            <TabPane tabId={tabsList.filter((item: any) =>
                                                item.tab === 'Special Operations - Cargo Cooling')[0]?.id}>
                                                <CargoCoolingComponent
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    tabsIdList={tabsIdList}
                                                    record={record}
                                                    lastRecord={lastRecord}
                                                    toggleDynamicTabs={toggleDynamicTabs}
                                                    previousTabIndex={previousTabIndex}
                                                    tabsList={tabsList}
                                                />
                                            </TabPane>
                                            <TabPane tabId={tabsList.filter((item: any) =>
                                                item.tab === 'Special Operations - Cargo Reliquification')[0]?.id}>
                                                <CargoReliquificationComponent
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    tabsIdList={tabsIdList}
                                                    record={record}
                                                    lastRecord={lastRecord}
                                                    toggleDynamicTabs={toggleDynamicTabs}
                                                    previousTabIndex={previousTabIndex}
                                                    tabsList={tabsList}
                                                />
                                            </TabPane>
                                        </>
                                    }
                                    <TabPane tabId={tabsList.filter((item: any) => item.tab === 'Other Special Operation')[0]?.id}>
                                        <OtherSpecialOperationComponent
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                            tabsIdList={tabsIdList}
                                            record={record}
                                            lastRecord={lastRecord}
                                            toggleDynamicTabs={toggleDynamicTabs}
                                            previousTabIndex={previousTabIndex}
                                            tabsList={tabsList}
                                        />
                                    </TabPane>
                                    <TabPane tabId={tabsList.filter((item: any) => item.tab === 'Lube Oil Bunkering')[0]?.id}>
                                        <LubeOilBunkeringComponent
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                            tabsIdList={tabsIdList}
                                            record={record}
                                            toggleDynamicTabs={toggleDynamicTabs}
                                            previousTabIndex={previousTabIndex}
                                            lastRecord={lastRecord}
                                            tabsList={tabsList}
                                        />
                                    </TabPane>
                                    <TabPane tabId={tabsList.filter((item: any) => item.tab === 'Lube Oil Debunkering')[0]?.id}>
                                        <LubeOilDebunkeringComponent
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                            tabsIdList={tabsIdList}
                                            record={record}
                                            toggleDynamicTabs={toggleDynamicTabs}
                                            previousTabIndex={previousTabIndex}
                                            lastRecord={lastRecord}
                                            tabsList={tabsList}
                                        />
                                    </TabPane>
                                    <TabPane tabId={record && record.operation_precedence_id === OperationPrecedence.SEA_PASSAGE ? 3 : -1}>
                                        <EnergyParameterCompenent
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                        />
                                    </TabPane>
                                    <TabPane tabId={18}>
                                        <FreshWaterComponent
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                            tabsIdList={tabsIdList}
                                            record={record}
                                            toggleDynamicTabs={toggleDynamicTabs}
                                            previousTabIndex={previousTabIndex}
                                            tabsList={tabsList}
                                        />

                                    </TabPane>
                                    <TabPane tabId={env?.additional_info_engg === AddInfoConstant.NONE ? 19 : 20}>
                                        <CardHeader className='p-2 pb-0'>
                                            <div className="text-center mb-4">
                                                <Row>
                                                    <Col>
                                                        <h5 className="page_title pos-start mb-0">Remarks (if any)</h5>
                                                    </Col>
                                                    <Col>
                                                        <ReportDetailsHeader />
                                                    </Col>
                                                </Row>
                                            </div></CardHeader>
                                        <Form className="needs-validation" autoComplete="off"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                let report = { ...record }
                                                report.engineer_reporting_status = 2;
                                                apiGlobal.put(`/vessel_reporting_information/${ReportID}/`, report).then(res => {
                                                    handleResponse(res);
                                                }).catch(err => {
                                                    console.log(err);
                                                });
                                            }}
                                        >
                                            <CardBody className='engineer-card-body'>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="form-floating mb-3 input-group">
                                                            <Input
                                                                type="textarea"
                                                                className="form-control"
                                                                id="textarea"
                                                                name="remarks"
                                                                placeholder='example'
                                                            />
                                                            <Label for="cargo_details">Remarks</Label>
                                                        </div>
                                                    </Col>
                                                </Row></CardBody>
                                            <CardFooter className='p-2 py-3'>
                                                <Row className="ele_row1">
                                                    <div className="d-flex flex-wrap gap-5 ">
                                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end12" onClick={(e) => {
                                                        }}>Save</Button>
                                                        <Button type="button" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                                            handleSaveandComplete();
                                                        }}>Save & Complete</Button>
                                                        <Button type="button" color="primary" className="btn_size_cstm " onClick={(e) => {
                                                            toggleDynamicTabs(previousTabIndex - 1);
                                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                                    </div>
                                                </Row>
                                            </CardFooter>
                                        </Form>
                                    </TabPane>
                                    <TabPane tabId={env?.additional_info_engg === AddInfoConstant.LFONDS_ENGG ? 19 : 0}>
                                        <LfondsAddInformation
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            activeTab={activeTab}
                                            toggleTab={toggleTab}
                                            tabsIdList={tabsIdList}
                                            record={record}
                                            toggleDynamicTabs={toggleDynamicTabs}
                                            previousTabIndex={previousTabIndex}
                                        />
                                    </TabPane>
                                    <TabPane tabId={env?.additional_info_engg === AddInfoConstant.PROACTIVE_ENGG ? 19 : 0}>
                                        <ProactiveAddInformation
                                            ReportID={ReportID}
                                            VesselID={VesselID}
                                            VoyageID={VoyageID}
                                            tabsIdList={tabsIdList}
                                            toggleDynamicTabs={toggleDynamicTabs}
                                            previousTabIndex={previousTabIndex}
                                            toggleTab={toggleTab}
                                            record={record}
                                            tabsList={tabsList}
                                        />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EngineerWizard
