import { Field, FieldProps, Formik } from 'formik';
import { dataTimeFormat, errResponse, searchCountries, searchPorts } from 'GenericForms/Helper';
import env from 'environment/env';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Card, CardHeader, Form, Row, Col, CardBody, Label, Button, CardFooter } from 'reactstrap';
import { customStyle } from 'shared/CommonCSS';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadProactiveETAAdditionalDetail } from 'VesselMaster/vesselMaster.hooks';
import apiGlobal from 'global/api.global';
import { errorToast, successToast } from 'Components/Toasts';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import ErrorTooltip from 'Components/ErrorTooltip';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { PortConstant } from 'shared/constants';

const ProactiveETADComponent = ({
    activeTab,
    toggleTab,
    ReportID,
    VesselID,
    VoyageID,
    activeAddTab,
    toggleAddTab,
    record,
    lastRecord
}: any) => {
    /** State variables */
    const [otherPort, setOtherPort] = useState(false);
    /** State variables end */

    /** Queries */
    /** Load proactive ATA additional detail */
    const { data: ProactiveETAD, isLoading: ProactiveETADLoading, isError: ProactiveETADError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.ProactiveETAAdditionalDetail.key, VesselID, ReportID],
            async () => { return await loadProactiveETAAdditionalDetail(VesselID, ReportID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** Queries end */

    /** Assign intial values to Proactive ETA/D's formik object */
    const getInitialValues = () => {
        if (ProactiveETAD && ProactiveETAD.length > 0) {
            return ProactiveETAD[0];
        } else {
            return ({
                next_destination_intermediate_port_distance: 0,
                next_destination_intermediate_port_eta_at_charter_party_speed: null as Date,
                next_destination_intermediate_port_eta_in_masters_best_judgement: null as Date,
                final_destination_port_eta_at_charter_party_speed: null as Date,
                final_destination_port_eta_in_masters_best_judgement: null as Date,
                final_destination_port_ordered_eta_as_per_voyage_order: null as Date,
                final_destination_port_speed_required_to_make_eta: 0,
                exclusion_or_deviations: 0,
                next_destination_intermediate_port: null as number,
                other_port: null as number,
                other_port_name: null as string,
                other_country: null as number,
                other_country_name: null as string,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
        }
    }

    /** Proactive ETA/D's formik object */
    const ProactiveETADFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object().shape({
            next_destination_intermediate_port_distance: Yup.number().typeError(`${commonValidationMessages.number}`),
            next_destination_intermediate_port_eta_at_charter_party_speed: Yup.date().nullable(),
            next_destination_intermediate_port_eta_in_masters_best_judgement: Yup.date().nullable(),
            final_destination_port_eta_at_charter_party_speed: Yup.date().nullable(),
            final_destination_port_eta_in_masters_best_judgement: Yup.date().nullable(),
            final_destination_port_ordered_eta_as_per_voyage_order: Yup.date().nullable(),
            final_destination_port_speed_required_to_make_eta: Yup.number().typeError(`${commonValidationMessages.number}`),
            exclusion_or_deviations: Yup.string().matches(/^[+-]?\d+(\.\d+)?$/, "Please enter a number").nullable(),
            next_destination_intermediate_port: Yup.number().nullable().typeError(`${commonValidationMessages.number}`),
            other_port_name: Yup.string().when('$fieldVisibility', (field: any, schema) => {
                return !otherPort
                    ? schema.nullable()
                    : schema.required(commonValidationMessages.required);
            }),
            other_country: Yup.number().when('$fieldVisibility', (field: any, schema) => {
                return !otherPort
                    ? schema.nullable()
                    : schema.required(commonValidationMessages.required);
            }),
        })
    }
    return (
        <Card className='p-0 mb-0 border-0'>
            {(ProactiveETADLoading) && <Loading message="Loading required data!" />}
            {(ProactiveETADError) && <ErrorComponent message="Error loading required data!" />}
            {!(ProactiveETADLoading) &&
                !(ProactiveETADError) &&
                <>
                    <CardHeader className='p-2'>
                        <div className="text-center">
                            <Row>
                                <Col>
                                    <h4 className="page_title pos-start mb-0">ETA/D Additional information</h4>
                                    <p className="card-title-desc pos-start">All readings since last report</p>
                                </Col>
                            </Row>
                        </div>
                    </CardHeader>
                    <Formik
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(false);
                            if (ProactiveETAD.length > 0) {
                                apiGlobal.put(`proactive_eta_additional_detail/${values.id}/`, values)
                                    .then(res => {
                                        if (res.status === 200) {
                                            successToast("Data saved successfully!");
                                        }
                                        if (env?.form_validation === true) {
                                            toggleAddTab(activeAddTab + 1);
                                        }

                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                            } else {
                                apiGlobal.post(`proactive_eta_additional_detail/`, values)
                                    .then(res => {
                                        if (res.status === 201) {
                                            successToast("Data saved successfully!");
                                        }
                                        if (env?.form_validation === true) {
                                            toggleAddTab(activeAddTab + 1);
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    })
                            }
                        }}
                        initialValues={ProactiveETADFormik.initialValues}
                        validationSchema={ProactiveETADFormik.validationSchema}
                    >
                        {props => (
                            <Form onSubmit={props?.handleSubmit} autoComplete="off" noValidate>
                                <CardBody className='px-2 py-0 mt-2'>
                                    <Row className='mb-2'>
                                        <Col sm={3}>
                                            <Label className='mb-0' for='next_destination_intermediate_port_distance'>
                                                Next destination intermediate port distance
                                            </Label>
                                            <div className='input-group'>
                                                <Field
                                                    typ="text"
                                                    name='next_destination_intermediate_port_distance'
                                                    id='next_destination_intermediate_port_distance'
                                                    className='form-control text-right max-width-7'
                                                />
                                                <div className='input-group-text'>nm</div>
                                            </div>
                                            {props?.errors?.next_destination_intermediate_port_distance && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target={`next_destination_intermediate_port_distance`}
                                                    message={props?.errors?.next_destination_intermediate_port_distance}
                                                    open={props?.errors?.next_destination_intermediate_port_distance ? true : false}
                                                />
                                            }
                                        </Col>
                                        <Col sm={3}>
                                            <Label className='mb-0' for='next_destination_intermediate_port_eta_at_charter_party_speed'>
                                                Next destination intermediate port ETA at charter party speed
                                            </Label>

                                            <Field name="next_destination_intermediate_port_eta_at_charter_party_speed">
                                                {({ field, form }: FieldProps) => (
                                                    <input
                                                        type="datetime-local"
                                                        name={field.name}
                                                        id='next_destination_intermediate_port_eta_at_charter_party_speed'
                                                        min={dataTimeFormat(lastRecord?.reporting_time_utc)}
                                                        max={'9999-12-31T00:00'}
                                                        className='datetimepicker text-uppercase form-control mt-0 w-12'
                                                        defaultValue={dataTimeFormat(props?.values.next_destination_intermediate_port_eta_at_charter_party_speed, false, true)}
                                                        onBlur={(e: any) => props?.handleBlur(e)}
                                                        onChange={(e: any) => props?.handleChange(e)}
                                                    />
                                                )}
                                            </Field>
                                        </Col>
                                        <Col sm={3}>
                                            <Label className='mb-0' for='next_destination_intermediate_port_eta_in_masters_best_judgement'>
                                                Next destination intermediate port ETA in master's best judgement
                                            </Label>
                                            <Field name="next_destination_intermediate_port_eta_in_masters_best_judgement">
                                                {({ field, form }: FieldProps) => (
                                                    <input
                                                        type="datetime-local"
                                                        name={field.name}
                                                        id='next_destination_intermediate_port_eta_in_masters_best_judgement'
                                                        min={dataTimeFormat(lastRecord?.reporting_time_utc)}
                                                        max={'9999-12-31T00:00'}
                                                        className='datetimepicker text-uppercase form-control mt-0 w-12'
                                                        defaultValue={dataTimeFormat(props?.values.next_destination_intermediate_port_eta_in_masters_best_judgement, false, true)}
                                                        onBlur={(e: any) => props?.handleBlur(e)}
                                                        onChange={(e: any) => props?.handleChange(e)}
                                                    />
                                                )}
                                            </Field>
                                        </Col>
                                        <Col sm={3}>
                                            <Label className='mb-0' for='final_destination_port_eta_at_charter_party_speed'>
                                                Final destination port ETA at charter party speed
                                            </Label>
                                            <Field name="final_destination_port_eta_at_charter_party_speed">
                                                {({ field, form }: FieldProps) => (
                                                    <input
                                                        type="datetime-local"
                                                        name={field.name}
                                                        id='final_destination_port_eta_at_charter_party_speed'
                                                        min={dataTimeFormat(lastRecord?.reporting_time_utc)}
                                                        max={'9999-12-31T00:00'}
                                                        className='datetimepicker text-uppercase form-control mt-0 w-12'
                                                        defaultValue={dataTimeFormat(props?.values.final_destination_port_eta_at_charter_party_speed, false, true)}
                                                        onBlur={(e: any) => props?.handleBlur(e)}
                                                        onChange={(e: any) => props?.handleChange(e)}
                                                    />
                                                )}
                                            </Field>
                                        </Col>
                                    </Row>
                                    <Row className='mb-2'>
                                        <Col sm={3}>
                                            <Label className='mb-0' for='final_destination_port_eta_in_masters_best_judgement'>
                                                Final destination port ETA in master's best judgement
                                            </Label>
                                            <Field name="final_destination_port_eta_in_masters_best_judgement">
                                                {({ field, form }: FieldProps) => (
                                                    <input
                                                        type="datetime-local"
                                                        name={field.name}
                                                        id='final_destination_port_eta_in_masters_best_judgement'
                                                        min={dataTimeFormat(lastRecord?.reporting_time_utc)}
                                                        max={'9999-12-31T00:00'}
                                                        className='datetimepicker text-uppercase form-control mt-0 w-12'
                                                        defaultValue={dataTimeFormat(props?.values.final_destination_port_eta_in_masters_best_judgement, false, true)}
                                                        onBlur={(e: any) => props?.handleBlur(e)}
                                                        onChange={(e: any) => props?.handleChange(e)}
                                                    />
                                                )}
                                            </Field>
                                        </Col>
                                        <Col sm={3}>
                                            <Label className='mb-0' for='final_destination_port_ordered_eta_as_per_voyage_order'>
                                                Final destination port ordered ETA as per voyage ordered
                                            </Label>
                                            <Field name="final_destination_port_ordered_eta_as_per_voyage_order">
                                                {({ field, form }: FieldProps) => (
                                                    <input
                                                        type="datetime-local"
                                                        name={field.name}
                                                        id='final_destination_port_ordered_eta_as_per_voyage_order'
                                                        min={dataTimeFormat(lastRecord?.reporting_time_utc)}
                                                        max={'9999-12-31T00:00'}
                                                        className='datetimepicker text-uppercase form-control mt-0 w-12'
                                                        defaultValue={dataTimeFormat(props?.values.final_destination_port_ordered_eta_as_per_voyage_order, false, true)}
                                                        onBlur={(e: any) => props?.handleBlur(e)}
                                                        onChange={(e: any) => props?.handleChange(e)}
                                                    />
                                                )}
                                            </Field>
                                        </Col>
                                        <Col sm={3}>
                                            <Label className='mb-0' for='final_destination_port_speed_required_to_make_eta'>
                                                Final destination port speed required to make ETA
                                            </Label>
                                            <div className='input-group'>
                                                <Field
                                                    typ="text"
                                                    name='final_destination_port_speed_required_to_make_eta'
                                                    id='final_destination_port_speed_required_to_make_eta'
                                                    className='form-control text-right max-width-7'
                                                />
                                                <div className='input-group-text'>knots</div>
                                            </div>
                                            {props?.errors?.final_destination_port_speed_required_to_make_eta && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target={`final_destination_port_speed_required_to_make_eta`}
                                                    message={props?.errors?.final_destination_port_speed_required_to_make_eta}
                                                    open={props?.errors?.final_destination_port_speed_required_to_make_eta ? true : false}
                                                />
                                            }
                                        </Col>
                                        <Col sm={3}>
                                            <Label className='mb-0' for='exclusion_or_deviations'>
                                                Exclusions or deviations
                                            </Label>
                                            <div className='input-group'>
                                                <Field
                                                    typ="text"
                                                    name='exclusion_or_deviations'
                                                    id='exclusion_or_deviations'
                                                    className='form-control text-right max-width-7'
                                                />
                                                <div className='input-group-text'>hr</div>
                                            </div>
                                            {props?.errors?.exclusion_or_deviations && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target={`exclusion_or_deviations`}
                                                    message={props?.errors?.exclusion_or_deviations}
                                                    open={props?.errors?.exclusion_or_deviations ? true : false}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <Row className='mb-2'>
                                        <Col sm={3}>
                                            <Label className='mb-0'>Next destination intermediate port</Label>
                                            <Field name='next_destination_intermediate_port'>
                                                {({ field, form }: FieldProps) => (
                                                    <AsyncSelect
                                                        name={field.name}
                                                        inputId={field.name}
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={(e: any) => searchPorts(e)}
                                                        getOptionLabel={(e: any) => e.port_name}
                                                        getOptionValue={(e: any) => e.id}
                                                        onChange={(e: any) => {
                                                            form.setFieldValue(field.name, e?.id);
                                                            if (e?.precedence_id === PortConstant.OTHER) {
                                                                setOtherPort(true);
                                                            } else {
                                                                setOtherPort(false);
                                                            }
                                                        }}
                                                        styles={customStyle}
                                                        defaultValue={ProactiveETAD && ProactiveETAD[0]?.id > 0 && {
                                                            id: props?.values?.next_destination_intermediate_port,
                                                            port_name: props?.values?.next_port_name
                                                        }}
                                                        noOptionsMessage={(e: any) => {
                                                            if (e?.inputValue?.toString()?.length > 2) {
                                                                return 'Please select the Other option and enter the port name in the textbox provided'
                                                            }
                                                            return 'Please enter the first 3 characters of port name';
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Col>
                                    </Row>
                                    {otherPort === true &&
                                        <Row>
                                            <Col lg={4}>
                                                <Label className='mb-0 asteric'>Other port name</Label>
                                                <Field
                                                    type='text'
                                                    name='other_port_name'
                                                    id='other_port_name'
                                                    className='form-control'
                                                />
                                                {props?.errors && props?.touched && props?.touched?.other_port_name &&
                                                    props?.errors?.other_port_name && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={`other_port_name`}
                                                        message={props?.errors?.other_port_name}
                                                        open={(props?.errors && props?.errors?.other_port_name) ? true : false}
                                                    />
                                                }
                                            </Col>
                                            <Col lg={4}>
                                                <Label className='mb-0 asteric'>Other country name</Label>
                                                <Field name={`other_country`}>
                                                    {({ field, form }: FieldProps) => (
                                                        <AsyncSelect
                                                            name={field.name}
                                                            id={'other_country'}
                                                            cacheOptions
                                                            defaultOptions
                                                            loadOptions={(e: any) => searchCountries(e)}
                                                            getOptionLabel={(e: any) => e.country_name}
                                                            getOptionValue={(e: any) => e.id}
                                                            onBlur={(e) => {
                                                                props?.setFieldTouched(field.name, true);
                                                            }}
                                                            onChange={(e: any) => {
                                                                props?.setFieldTouched(field.name, true);
                                                                form.setFieldValue(field.name, e?.id);
                                                            }}
                                                            defaultValue={ProactiveETAD && ProactiveETAD[0]?.id > 0 && {
                                                                id: props?.values?.other_country,
                                                                country_name: props?.values?.other_country_name
                                                            }}
                                                            className="select-height"
                                                            menuPortalTarget={document.body}
                                                            styles={customStyle}
                                                            noOptionsMessage={(e: any) => {
                                                                return 'Please enter the first 3 characters of country name';
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                {props?.errors && props?.touched && props?.touched?.other_country &&
                                                    props?.errors?.other_country && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={`other_country`}
                                                        message={props?.errors?.other_country}
                                                        open={(props?.errors && props?.errors?.other_country) ? true : false}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                                <CardFooter className='p-2 py-3'>
                                    <Row className="ele_row1">
                                        <div className="d-flex flex-wrap gap-5">
                                            <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                                if (env?.form_validation === false) {
                                                    toggleAddTab(activeAddTab + 1);
                                                }
                                            }}>
                                                Next<i className="bx bx-chevron-right ms-1" />
                                            </Button>
                                            <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                                toggleTab(activeTab - 1);
                                            }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                        </div>
                                    </Row>
                                </CardFooter>
                                <FormValuesDebug values={[props?.values, props?.errors, ProactiveETADFormik.initialValues]} />
                            </Form>
                        )}
                    </Formik>
                </>
            }
        </Card>
    )
}

export default ProactiveETADComponent