import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { Col, Row, Input, Label, Button, Form, CardBody } from "reactstrap";
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import AsyncSelect from "react-select/async";
import { loadBunkeringObject, loadFuelBatches, loadFuelTypeROB, loadPortOptions, loadVesselFuelTypes } from 'VesselMaster/vesselMaster.hooks';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { dataTimeFormat, errResponse, searchCountries, searchPorts } from 'GenericForms/Helper';
import { customStyle } from 'shared/CommonCSS';
import { FuelSubTypes, FuelTypes, PortConstant, ViscosityConstant } from 'shared/constants';
import { queryClient } from 'react-query/queryClient';
import { errorToast, successToast } from 'Components/Toasts';
import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment/env';

interface BunkeringType {
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    record: any,
    lastRecord: any,
}

const BunkeringComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    record,
    lastRecord,
}: BunkeringType) => {
    /** State variables */
    const [vesselFuels, setVesselFuels] = useState<Array<any>>([]);
    const [count, setCount] = useState(0);
    const [port, setPort] = useState(0);
    const [otherPort, setOtherPort] = useState(false);
    const [otherPortName, setOtherPortName] = useState('');
    const [otherCountry, setOtherCountry] = useState(0);
    /** State variables end */

    /** Queries */
    /** Rob according to fuel types */
    const { data: fuelTypeROB, isLoading: fuelTypeROBIsLoading, isError: fuelTypeROBIsError } = useQuery(
        [queryKeyes.vessel.FuelTypeROB.key, VesselID, VoyageID, ReportID],
        async () => {
            return await loadFuelTypeROB(VesselID, VoyageID, ReportID)
        },
        {
            enabled: true,
        }
    );
    /** Ports from port master */
    const { data: ports, isLoading: PortsIsLoading, isError: PortsIsError } = useQuery(
        [queryKeyes.masters.portMaster],
        async () => { return await loadPortOptions() },
        {
            enabled: true,
        }
    );
    /**Bunkering object used for edit */
    const { data: BunkeringObject, isLoading: BunkeringObjectLoading, isError: BunkeringObjectError } = useQuery(
        [queryKeyes.vessel.BunkeringObject.key, VesselID, ReportID],
        async () => {
            return await loadBunkeringObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Assign initial values to formik object */
    const getintialValueBunkering = () => {
        let obj: any[] = [];
        if (BunkeringObject?.length > 0 && BunkeringObject[0].id > 0) {
            return BunkeringObject;
        } else {
            obj.push({
                precedence_id: null,
                precedence_id_type: null,
                bdn_identification_number: null,
                log_book_upload: null,
                baplie_file_upload: null,
                quantity_bunkered: null,
                bunkering_supply_name: '',
                viscosity: null,
                density_temperature: null,
                quantity_remaining: 0,
                previous_quantity_remaining: 0,
                status: '',
                full_consumption_date: null,
                sulphur_percentage: null,
                density: null,
                date_of_bunkering: '',
                vessel_fuel_name: 0,
                fuel_type_name: 0,
                fuel_sub_type: null,
                port_of_bunkering: null,
                other_port_name: null as string,
                other_country_name: null,
                vessel_name: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
        }
        return obj;
    }

    /**useEffect start */
    /** Create and assign initial object to Formik's initial values' object  */
    useEffect(() => {
        queryClient.invalidateQueries(queryKeyes.vessel.BunkeringObject.key);
    }, [])
    useEffect(() => {
        BunkeringFormik.initialValues.bunkering = getintialValueBunkering();
    }, [BunkeringObject])
    /**useEffect end */

    /** Bunkering Formik object */
    const BunkeringFormik = {
        initialValues: {
            bunkering: getintialValueBunkering(),
        },
        validationSchema: Yup.object().shape({
            bunkering: Yup.array(
                Yup.object({
                    bdn_identification_number: Yup.string().required(commonValidationMessages.required),
                    quantity_bunkered: Yup.string().matches(/^(?!0\d)\d{1,5}(?:\.\d{1,4})?$/, `${commonValidationMessages.before5after4}`).required(commonValidationMessages.required),
                    bunkering_supply_name: Yup.string(),
                    viscosity: Yup.string().matches(/^\d{0,3}(\.\d{1,3})?$/, `${commonValidationMessages.before3after3}`).nullable(),
                    quantity_remaining: Yup.number().nullable(),
                    previous_quantity_remaining: Yup.number().nullable(),
                    status: Yup.string(),
                    full_consumption_date: Yup.string().nullable(),
                    sulphur_percentage: Yup.string().matches(/^\d(?:\.\d{1,3})?$/, `${commonValidationMessages.before1after3}`).nullable(),
                    density: Yup.string().matches(/^\d{0,4}(\.\d{1})?$/, `${commonValidationMessages.before4after1}`).required(commonValidationMessages.required),
                    date_of_bunkering: Yup
                        .date()
                        .min(dataTimeFormat(lastRecord?.reporting_time_utc, true)) //lastRecord?.reporting_time_utc
                        .max(dataTimeFormat(record?.reporting_time_utc, true))
                        .required(commonValidationMessages.required),
                    vessel_fuel_name: Yup.number().required(commonValidationMessages.required),
                    fuel_type_name: Yup.number().required(commonValidationMessages.required),
                    port_of_bunkering: Yup.number().required(commonValidationMessages.required),
                    other_port_name: Yup.string().when('$fieldVisibility', (field: any, schema) => {
                        return !otherPort
                            ? schema.nullable()
                            : schema.required(commonValidationMessages.required);
                    }),
                    other_country_name: Yup.number().when('$fieldVisibility', (field: any, schema) => {
                        return !otherPort
                            ? schema.nullable()
                            : schema.required(commonValidationMessages.required);
                    }),
                    vessel_name: Yup.number(),
                    voyage_information: Yup.number(),
                    vessel_reporting_information: Yup.number()
                }))
        })
    }

    /** Load fuels used on the vessel */
    const loadVesselFuels = async (bunkeringFuelType: number) => {
        return apiGlobal(`/vessel_fuel_information/get_fuel_by_vessel/?vessel_id=${VesselID}`)
            .then((response) => {
                setVesselFuels(response.data);
                return response.data.filter((item: any) => item.fuel_type === bunkeringFuelType);
            })
    };

    /** Handle changes of bunkering date */
    const handleBunkeringDateChanges = (e: any, form: any, values: any, index: number, fieldName: any, bunkering_supply_name: any, status: any) => {
        let fuelShortName = vesselFuels.filter((item: any) => item.id === values.bunkering[index]?.vessel_fuel_name).map((fuel: any) => { return fuel.fuel_shortname });
        let portName = ports.filter((item: any) => item.id === values.bunkering[index]?.port_of_bunkering).map((port: any) => { return port.port_name });
        form.setFieldValue(fieldName, e.target.value);
        form.setFieldValue(status, 'inuse');
        form.setFieldValue(bunkering_supply_name, `${fuelShortName}/${portName}/${ReportID}/${index + 1}/${e.target.value}`);
    }

    /** Post submit actions based on response */
    const handleResponse = (response: any) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
            loadFuelBatches(VesselID);
            queryClient.invalidateQueries(queryKeyes.vessel.BunkeringObject.key);
        }
    }

    /** PUT request for Bunkering */
    const putBunkering = (value: any) => {
        apiGlobal.put(`/bunkering/${value?.id}/`, value).then(res => {
            handleResponse(res);
        }).catch(err => {
            if (errResponse.includes(err.response.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }

    /** POST request for Bunkering */
    const postBunkering = (values: any) => {
        apiGlobal.post(`/bunkering/`, values).then(res => {
            handleResponse(res);
        }).catch(err => {
            if (errResponse.includes(err.response.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }

    /** Bunkering submit function */
    const bunkeringSubmit = (values: any) => {
        let obj: any = {
            port_name: otherPortName,
            country_name: otherCountry
        }
        /** Update quantity remaining & previous quantity remaining  */
        values.bunkering.map((bunker: any) => {
            bunker.other_port_name = otherPortName;
            bunker.other_country_name = otherCountry;
            bunker.quantity_remaining = bunker.quantity_bunkered;
            bunker.previous_quantity_remaining = bunker.quantity_bunkered;
            return '';
        })
        /** Bunkering submit */
        if (BunkeringObject?.length > 0 && BunkeringObject[0].id > 0) {
            if (otherPort === true) {
                apiGlobal.post('port_master/', obj).then(portMaster => {
                    if (portMaster.status === 201) {
                        values.bunkering.forEach((bunker: any) => {
                            bunker.port_of_bunkering = portMaster.data?.id;
                            if (bunker.id === undefined) {
                                postBunkering(bunker);
                            } else {
                                putBunkering(bunker);
                            }
                            return '';
                        })
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            } else {
                values.bunkering.map((bunker: any) => {
                    if (bunker.id === undefined) {
                        postBunkering(bunker);
                    } else {
                        putBunkering(bunker);
                    }
                    return '';
                })
            }
        } else {
            if (otherPort === true) {
                apiGlobal.post('port_master/', obj).then(portMaster => {
                    if (portMaster.status === 201) {
                        values?.bunkering?.forEach((bunker: any) => {
                            bunker.port_of_bunkering = portMaster.data?.id;
                        })
                        postBunkering(values?.bunkering);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            } else {
                postBunkering(values?.bunkering);
            }
        }

        /** Total quantity bunkered w.r.t. fuel type */
        let totalBunkering = values.bunkering.reduce(
            (acc: any, curr: any) => {
                const { fuel_type_name, quantity_bunkered } = curr;
                const quantity = parseFloat(quantity_bunkered);
                if (!isNaN(quantity)) {
                    acc[fuel_type_name] = (acc[fuel_type_name] || 0) + quantity;
                    return acc;
                }
                return '';
            },
            {}
        );
        /** Update fuel ROB */
        fuelTypeROB.filter((item: any) => totalBunkering[item.vessel_fuel] > 0).map((type: any, index: number) => {
            type.bunkered_quantity = totalBunkering[type.vessel_fuel].toFixed(4);
            type.rob = ((type.previous_rob - type.total_fuel_consumed) + totalBunkering[type.vessel_fuel]).toFixed(4);
            apiGlobal.patch(`/fo_rob/${type.id}/`, type).then(res => {
                if (res.status === 200) {
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.FuelTypeROB.key);
                }
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            });
            return '';
        })
    }

    return (
        <>
            {(fuelTypeROBIsLoading || PortsIsLoading || BunkeringObjectLoading) &&
                <Loading message='Loading required data!' />}
            {(fuelTypeROBIsError || PortsIsError) && <ErrorComponent message='Unable to load required data!' />}
            {BunkeringObjectError && getintialValueBunkering()}
            {!(fuelTypeROBIsLoading || PortsIsLoading || BunkeringObjectLoading) &&
                !(fuelTypeROBIsError || PortsIsError) &&
                <Formik
                    initialValues={BunkeringFormik.initialValues}
                    validationSchema={BunkeringFormik.validationSchema}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        bunkeringSubmit(values);
                    }}
                >
                    {({ values, errors, handleSubmit, handleChange, touched, handleBlur }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any, touched: any, handleBlur: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit}>
                            <CardBody className='px-2 py-0 mt-2'>
                                <FieldArray name="bunkering">
                                    {({ push, remove }) => (
                                        <React.Fragment>
                                            <Row className='mb-2'>
                                                <Col lg={4}>
                                                    <Label className='asteric mb-0' for='port_of_bunkering'>Port of bunkering</Label>
                                                    <Field name={`bunkering.${0}.port_of_bunkering`}>
                                                        {({ field, form }: FieldProps) => (
                                                            <AsyncSelect
                                                                name={field.name}
                                                                inputId='port_of_bunkering'
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={(e: any) => searchPorts(e)}
                                                                getOptionLabel={e => e.port_name}
                                                                getOptionValue={e => e.id}
                                                                onBlur={handleBlur}
                                                                onChange={(e: any) => {
                                                                    setPort(e.id);
                                                                    values?.bunkering?.forEach((bunker: any) => {
                                                                        bunker.port_of_bunkering = e?.id;
                                                                    })
                                                                    if (e?.precedence_id === PortConstant.OTHER) {
                                                                        setOtherPort(true);
                                                                    } else {
                                                                        setOtherPort(false);
                                                                    }
                                                                }}
                                                                className="select-height"
                                                                menuPortalTarget={document.body}
                                                                styles={customStyle}
                                                                defaultValue={(BunkeringObject?.length > 0 && BunkeringObject[0].id > 0) ?
                                                                    {
                                                                        id: BunkeringObject[0].port_of_bunkering,
                                                                        port_name: BunkeringObject[0].port_of_bunkering_name
                                                                    } : null
                                                                }
                                                                noOptionsMessage={(e: any) => {
                                                                    if (e?.inputValue?.toString()?.length > 2) {
                                                                        return 'Please select the Other option and enter the port name in the textbox provided'
                                                                    }
                                                                    return 'Please enter the first 3 characters of port name';
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.bunkering && touched?.port_of_bunkering &&
                                                        errors?.bunkering[0]?.port_of_bunkering && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`port_of_bunkering`}
                                                            message={errors?.bunkering[0]?.port_of_bunkering}
                                                            open={(errors.bunkering && errors?.bunkering[0]?.port_of_bunkering) ? true : false}
                                                        />
                                                    }
                                                </Col>
                                                {otherPort === true &&
                                                    <>
                                                        <Col lg={4}>
                                                            <Label className='mb-0 asteric'>Other port name</Label>
                                                            <Input
                                                                type="text"
                                                                name={`bunkering.${0}.other_port_name`}
                                                                id='other_port'
                                                                className="form-control"
                                                                onBlur={(e: any) => {
                                                                    setOtherPortName(e.target.value);
                                                                    handleBlur(e);
                                                                    values?.bunkering?.forEach((bunker: any) => {
                                                                        bunker.other_port_name = e.target.value;
                                                                    })
                                                                }}
                                                            />
                                                        </Col>
                                                        {errors.bunkering && touched?.bunkering[0]?.other_port_name &&
                                                            errors?.bunkering[0]?.other_port_name && env?.form_validation === true &&
                                                            <ErrorTooltip
                                                                target={`other_port`}
                                                                message={errors?.bunkering[0]?.other_port_name}
                                                                open={(errors.bunkering && errors?.bunkering[0]?.other_port_name) ? true : false}
                                                            />
                                                        }
                                                        <Col lg={4}>
                                                            <Label className='mb-0 asteric'>Other country name</Label>
                                                            <Field name={`bunkering.${0}.other_country_name`}>
                                                                {({ field }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name={field.name}
                                                                        id={'other_country_name'}
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={(e: any) => searchCountries(e)}
                                                                        getOptionLabel={(e: any) => e.country_name}
                                                                        getOptionValue={(e: any) => e.id}
                                                                        onBlur={handleBlur}
                                                                        onChange={(e: any) => {
                                                                            setOtherCountry(e.id);
                                                                            values?.bunkering?.forEach((bunker: any) => {
                                                                                bunker.other_country_name = e.id;
                                                                            })
                                                                        }}
                                                                        className="select-height"
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyle}
                                                                        noOptionsMessage={(e: any) => {
                                                                            return 'Please enter the first 3 characters of country name';
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors.bunkering && touched?.bunkering[0]?.other_country_name &&
                                                                errors?.bunkering[0]?.other_country_name && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target={`other_country_name`}
                                                                    message={errors?.bunkering[0]?.other_country_name}
                                                                    open={(errors.bunkering && errors?.bunkering[0]?.other_country_name) ? true : false}
                                                                />
                                                            }
                                                        </Col>
                                                    </>
                                                }
                                            </Row>
                                            {values?.bunkering?.map((value: any, index: number) => {
                                                return (
                                                    <div key={index}>
                                                        <Row>
                                                            <Col>
                                                                {values?.bunkering?.length === 1 ? null :
                                                                    <button type="button" className="btn justify_right">
                                                                        <i className='dripicons-trash icon_s18'
                                                                            onClick={(actions) => remove(index)} />
                                                                    </button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-2">
                                                            <Col lg={4}>
                                                                <Label for={`fuel_type_name-${index}`} className="asteric mb-0">
                                                                    Bunkered fuel type<i className='bx bx-info-circle ml-2p' id='fuel_type_name_msg' />
                                                                </Label>
                                                                <ToolTip target='fuel_type_name_msg' message={`${TooltipMsg.Bunkering
                                                                    .filter((item: any) => item.target === 'fuel_type_name_msg')
                                                                    .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <Field name={`bunkering.${index}.fuel_type_name`}>
                                                                    {({ field, form }: FieldProps) => (
                                                                        <AsyncSelect
                                                                            name={field.name}
                                                                            inputId={`fuel_type_name-${index}`}
                                                                            cacheOptions
                                                                            defaultOptions
                                                                            loadOptions={(inputValue: string, callback: (options: any) => void) => {
                                                                                loadVesselFuelTypes(VesselID).then(options => {
                                                                                    callback(options);
                                                                                });
                                                                            }}
                                                                            getOptionLabel={(e: any) => e.fuel_type_name}
                                                                            getOptionValue={(e: any) => e.fuel_type}
                                                                            onChange={(selectedOption: any) => {
                                                                                form.setFieldValue(field.name, selectedOption.fuel_type);
                                                                                setCount(count + 1);
                                                                            }}
                                                                            menuPortalTarget={document.body}
                                                                            styles={customStyle}
                                                                            defaultValue={values?.bunkering[index]?.fuel_type_name > 0 &&
                                                                            {
                                                                                fuel_type: values?.bunkering[index]?.fuel_type_name,
                                                                                fuel_type_name: values?.bunkering[index]?.fuel_typename
                                                                            }
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                {errors.bunkering && errors?.bunkering[index]?.fuel_type_name && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`fuel_type_name-${index}`}
                                                                        message={errors?.bunkering[index]?.fuel_type_name}
                                                                        open={(errors.bunkering && errors?.bunkering[index]?.fuel_type_name) ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col lg={4}>
                                                                <Label for={`vessel_fuel_name-${index}`} className="asteric mb-0">
                                                                    Bunkered fuel<i className='bx bx-info-circle ml-2p' id='vessel_fuel_name_msg' />
                                                                </Label>
                                                                <ToolTip target='vessel_fuel_name_msg' message={`${TooltipMsg.Bunkering
                                                                    .filter((item: any) => item.target === 'vessel_fuel_name_msg')
                                                                    .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <Field name={`bunkering.${index}.vessel_fuel_name`}>
                                                                    {({ field, form, }: FieldProps) => (
                                                                        <AsyncSelect
                                                                            name={field.name}
                                                                            inputId={`vessel_fuel_name-${index}`}
                                                                            key={count}
                                                                            cacheOptions
                                                                            defaultOptions
                                                                            loadOptions={() => loadVesselFuels(values.bunkering[index]?.fuel_type_name)}
                                                                            getOptionLabel={(e: any) => e.fuel}
                                                                            getOptionValue={(e: any) => e.id}
                                                                            onChange={(selectedOption: any) => {
                                                                                form.setFieldValue(field.name, selectedOption.id);
                                                                                form.setFieldValue(`bunkering.${index}.precedence_id`, selectedOption.precedence_id_sub_type);
                                                                                if (selectedOption.fuel_type !== FuelTypes.FUEL_OIL) {
                                                                                    form.setFieldValue(`bunkering.${index}.fuel_sub_type`, selectedOption.fuel_sub_type);
                                                                                }
                                                                                if (values?.bunkering[index]?.precedence_id_type === null) {
                                                                                    form.setFieldValue(`bunkering.${index}.precedence_id_type`, selectedOption.precedence_id);
                                                                                }
                                                                            }}
                                                                            menuPortalTarget={document.body}
                                                                            styles={customStyle}
                                                                            defaultValue={values?.bunkering[index]?.vessel_fuel_name > 0 &&
                                                                            {
                                                                                id: values?.bunkering[index]?.vessel_fuel_name,
                                                                                fuel: values?.bunkering[index]?.vessel_fuelname
                                                                            }
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                {errors?.bunkering && errors.bunkering[index]?.vessel_fuel_name && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`vessel_fuel_name-${index}`}
                                                                        message={errors.bunkering[index]?.vessel_fuel_name}
                                                                        open={(errors?.bunkering && errors.bunkering[index]?.vessel_fuel_name) ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col lg={4}><Label className='asteric mb-0' for={`bdn_identification_number_${index}`}>
                                                                BDN identification number<i className='bx bx-info-circle ml-2p' id='bdn_identification_number_msg' />
                                                            </Label>
                                                                <ToolTip target='bdn_identification_number_msg' message={`${TooltipMsg.Bunkering
                                                                    .filter((item: any) => item.target === 'bdn_identification_number_msg').map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <div className="input-group max-width-13">
                                                                    <Field name={`bunkering.${index}.bdn_identification_number`}>
                                                                        {() => (
                                                                            <Input
                                                                                name={`bunkering.${index}.bdn_identification_number`}
                                                                                id={`bdn_identification_number_${index}`}
                                                                                type='text'
                                                                                className='form-control text-right'
                                                                                onBlur={(e: any) => {
                                                                                    handleBlur(e);
                                                                                    handleChange(e);
                                                                                }}
                                                                                defaultValue={values.bunkering[index]?.bdn_identification_number}
                                                                            />)}
                                                                    </Field>
                                                                </div>
                                                                {errors?.bunkering && touched.bunkering && touched.bunkering[index]?.bdn_identification_number &&
                                                                    errors.bunkering[index]?.bdn_identification_number && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`bdn_identification_number_${index}`}
                                                                        message={errors.bunkering[index]?.bdn_identification_number}
                                                                        open={(errors?.bunkering && errors.bunkering[index]?.bdn_identification_number) ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-2'>
                                                            <Col>
                                                                <Label className='asteric mb-0' for={`quantity_bunkered-${index}`}>
                                                                    Quantity bunkered<i className='bx bx-info-circle ml-2p' id='quantity_bunkered_msg' />
                                                                </Label>
                                                                <ToolTip target='quantity_bunkered_msg' message={`${TooltipMsg.Bunkering
                                                                    .filter((item: any) => item.target === 'quantity_bunkered_msg').map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <div className="input-group max-width-13">
                                                                    <Field name={`bunkering.${index}.quantity_bunkered`}>
                                                                        {({ field, form }: FieldProps) => (
                                                                            <Input
                                                                                name={field.name}
                                                                                id={`quantity_bunkered-${index}`}
                                                                                type='text'
                                                                                className='form-control text-right'
                                                                                onBlur={(e: any) => {
                                                                                    handleBlur(e);
                                                                                    handleChange(e);
                                                                                    form.setFieldValue(`bunkering.${index}.quantity_remaining`,
                                                                                        e.target.value);
                                                                                }}
                                                                                defaultValue={values?.bunkering[index]?.quantity_bunkered}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text round_border">mt</div>
                                                                </div>
                                                                {errors?.bunkering && touched.bunkering && touched.bunkering[index]?.quantity_bunkered &&
                                                                    errors.bunkering[index]?.quantity_bunkered && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`quantity_bunkered-${index}`}
                                                                        message={errors.bunkering[index]?.quantity_bunkered}
                                                                        open={(errors?.bunkering && errors.bunkering[index]?.quantity_bunkered) ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col>
                                                                <Label className='asteric mb-0' for={`density_${index}`}>
                                                                    Fuel Density<i className='bx bx-info-circle ml-2p' id='density_msg' />
                                                                </Label>
                                                                <ToolTip target='density_msg' message={`${TooltipMsg.Bunkering
                                                                    .filter((item: any) => item.target === 'density_msg')
                                                                    .map((tool: any) => { return tool.message })}`}
                                                                /><div className="input-group max-width-13">
                                                                    {values?.bunkering[index]?.precedence_id === FuelSubTypes.LNGB ?
                                                                        <Row>
                                                                            <Col lg={6}>
                                                                                <Field name={`bunkering.${index}.density`}>
                                                                                    {({ field }: FieldProps) => (
                                                                                        <Input
                                                                                            name={field.name}
                                                                                            id={`density_${index}`}
                                                                                            type='text'
                                                                                            className='form-control text-right'
                                                                                            onBlur={(e: any) => {
                                                                                                handleBlur(e);
                                                                                                handleChange(e);
                                                                                            }}
                                                                                            defaultValue={values?.bunkering[index]?.density}
                                                                                        />)}
                                                                                    <div className="input-group-text round_border">
                                                                                        kg/m<sup>3</sup>
                                                                                    </div>
                                                                                </Field>
                                                                                {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.density &&
                                                                                    errors?.bunkering[index]?.density && env?.form_validation === true &&
                                                                                    <ErrorTooltip
                                                                                        target={`density_${index}`}
                                                                                        message={errors?.bunkering[index]?.density}
                                                                                        open={(errors?.bunkering && errors?.bunkering[index]?.density) ? true : false}
                                                                                    />
                                                                                }
                                                                            </Col>
                                                                            <Col lg={1} className='mt-2'>
                                                                                <strong>@</strong>
                                                                            </Col>
                                                                            <Col lg={4}>
                                                                                <div className="input-group">
                                                                                    <Field
                                                                                        name={`bunkering.${index}.density_temperature`}
                                                                                        id={`bunkering.${index}.density_temperature`}
                                                                                        type='text'
                                                                                        className='form-control text-right'
                                                                                    />
                                                                                    <div className="input-group-text round_border">
                                                                                        <sup>o</sup>C
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row> :
                                                                        <Col>
                                                                            <div className="input-group">
                                                                                <Field name={`bunkering.${index}.density`}>
                                                                                    {({ field }: FieldProps) => (
                                                                                        <Input
                                                                                            name={field.name}
                                                                                            id={`density_${index}`}
                                                                                            type='text'
                                                                                            className='form-control text-right'
                                                                                            onBlur={(e: any) => {
                                                                                                handleBlur(e);
                                                                                                handleChange(e);
                                                                                            }}
                                                                                            defaultValue={values?.bunkering[index]?.density}
                                                                                        />)}
                                                                                </Field>
                                                                                <div className="input-group-text round_border">
                                                                                    kg/m<sup>3</sup>@15<sup>o</sup>C
                                                                                </div>
                                                                            </div>
                                                                            {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.density &&
                                                                                errors?.bunkering[index]?.density && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`density_${index}`}
                                                                                    message={errors?.bunkering[index]?.density}
                                                                                    open={(errors?.bunkering && errors?.bunkering[index]?.density) ? true : false}
                                                                                />
                                                                            }
                                                                        </Col>
                                                                    }</div>
                                                            </Col>
                                                            <Col>
                                                                <Label className='asteric mb-0' for={`sulphur_percentage-${index}`}>
                                                                    Sulphur Percentage<i className='bx bx-info-circle ml-2p' id='sulphur_percentage_msg' />
                                                                </Label>
                                                                <ToolTip target='sulphur_percentage_msg' message={`${TooltipMsg.Bunkering
                                                                    .filter((item: any) => item.target === 'sulphur_percentage_msg')
                                                                    .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <div className="input-group max-width-13">
                                                                    <Field name={`bunkering.${index}.sulphur_percentage`}>
                                                                        {() => (
                                                                            <Input
                                                                                name={`bunkering.${index}.sulphur_percentage`}
                                                                                id={`sulphur_percentage-${index}`}
                                                                                type='text'
                                                                                className='form-control text-right'
                                                                                onBlur={handleBlur}
                                                                                defaultValue={values?.bunkering[index]?.sulphur_percentage}
                                                                                disabled={values?.bunkering[index]?.precedence_id === FuelSubTypes.LNGB ? true : false}
                                                                            />)}</Field>
                                                                    <div className="input-group-text round_border">% m/m</div>
                                                                </div>
                                                                {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.sulphur_percentage &&
                                                                    errors?.bunkering[index]?.sulphur_percentage && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`sulphur_percentage-${index}`}
                                                                        message={errors?.bunkering[index]?.sulphur_percentage}
                                                                        open={(errors?.bunkering && errors?.bunkering[index]?.sulphur_percentage) ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-2'>
                                                            <Col lg={4}>
                                                                <Label className='asteric mb-0' for={`viscosity-${index}`}>
                                                                    Viscosity<i className='bx bx-info-circle ml-2p' id='viscosity_msg' />
                                                                </Label>
                                                                <ToolTip target='viscosity_msg' message={`${TooltipMsg.Bunkering
                                                                    .filter((item: any) => item.target === 'viscosity_msg')
                                                                    .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <div className="input-group max-width-13">
                                                                    <Field name={`bunkering.${index}.viscosity`}>
                                                                        {({ field, form }: FieldProps) => (
                                                                            <Input
                                                                                name={field.name}
                                                                                id={`viscosity-${index}`}
                                                                                type='text'
                                                                                className='form-control text-right'
                                                                                onBlur={(e: any) => {
                                                                                    handleBlur(e);
                                                                                    handleChange(e);
                                                                                    if (values && values.bunkering[index]?.fuel_type_name === FuelTypes.FUEL_OIL) {
                                                                                        form.setFieldValue(`bunkering.${index}.fuel_sub_type`,
                                                                                            e.target.value <= ViscosityConstant ? FuelSubTypes.LFO : FuelSubTypes.HFO
                                                                                        )
                                                                                    }
                                                                                }}
                                                                                defaultValue={values && values.bunkering[index]?.viscosity}
                                                                                disabled={values?.bunkering[index]?.precedence_id === FuelSubTypes.LNGB ? true : false}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text round_border">cst@50<sup>o</sup>C</div>
                                                                </div>
                                                                {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.viscosity &&
                                                                    errors?.bunkering[index]?.viscosity && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`viscosity-${index}`}
                                                                        message={errors?.bunkering[index]?.viscosity}
                                                                        open={(errors?.bunkering && errors?.bunkering[index]?.viscosity) ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col lg={4}>
                                                                <Label for={`date_of_bunkering-${index}`} className="mb-0">
                                                                    Date of bunkering<i className='bx bx-info-circle ml-2p' id='date_of_bunkering_msg' />
                                                                </Label><br />
                                                                <ToolTip target='date_of_bunkering_msg'
                                                                    message={`${TooltipMsg.Bunkering
                                                                        .filter((item: any) => item.target === 'date_of_bunkering_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <Field name={`bunkering.${index}.date_of_bunkering`}>
                                                                    {({ field, form }: FieldProps) => (
                                                                        <Input
                                                                            name={field.name}
                                                                            id={`date_of_bunkering-${index}`}
                                                                            type='date'
                                                                            className='datetimepicker text-uppercase'
                                                                            min={dataTimeFormat(lastRecord?.reporting_time_utc, true)}
                                                                            max={dataTimeFormat(record?.reporting_time_utc, true)}
                                                                            onBlur={handleBlur}
                                                                            onChange={(e: any) =>
                                                                                handleBunkeringDateChanges
                                                                                    (
                                                                                        e,
                                                                                        form,
                                                                                        values,
                                                                                        index,
                                                                                        field.name,
                                                                                        `bunkering.${index}.bunkering_supply_name`,
                                                                                        `bunkering.${index}.status`
                                                                                    )}
                                                                            defaultValue={values?.bunkering[index]?.date_of_bunkering}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.date_of_bunkering &&
                                                                    errors.bunkering[index]?.date_of_bunkering && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`date_of_bunkering-${index}`}
                                                                        message={errors.bunkering[index]?.date_of_bunkering}
                                                                        open={(errors?.bunkering && errors.bunkering[index]?.date_of_bunkering) ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        {index === (values.bunkering.length - 1) &&
                                                            <Label
                                                                className="link_color_blue mt-2 mb-0"
                                                                onClick={() => push({
                                                                    quantity_bunkered: null,
                                                                    bdn_identification_number: null,
                                                                    log_book_upload: null,
                                                                    baplie_file_upload: null,
                                                                    bunkering_supply_name: '',
                                                                    viscosity: null,
                                                                    quantity_remaining: 0,
                                                                    previous_quantity_remaining: 0,
                                                                    status: '',
                                                                    full_consumption_date: null,
                                                                    sulphur_percentage: null,
                                                                    density: null,
                                                                    density_temperature: null,
                                                                    date_of_bunkering: '',
                                                                    vessel_fuel_name: null,
                                                                    fuel_type_name: null,
                                                                    fuel_sub_type: null,
                                                                    port_of_bunkering: null as number,
                                                                    other_port_name: null as string,
                                                                    vessel_name: VesselID,
                                                                    voyage_information: VoyageID,
                                                                    vessel_reporting_information: ReportID
                                                                })}
                                                            >
                                                                Add another bunkering
                                                            </Label>
                                                        }
                                                        {values?.bunkering?.length > 1 &&
                                                            <hr />
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                                <Row>
                                    <Col sm={{ size: 1, offset: 11 }} className='p-2'>
                                        <Button type="submit" color="primary" className="btn_size_cstm justify_right" onClick={() => {
                                            values.bunkering.forEach((bunker: any, idx: number) => {
                                                if (values.bunkering[0]?.port_of_bunkering === 0 || values.bunkering[0]?.port_of_bunkering === null) {
                                                    bunker.port_of_bunkering = port;
                                                } else {
                                                    bunker.port_of_bunkering = values.bunkering[0]?.port_of_bunkering;
                                                }
                                            })
                                        }}>Save</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                            <FormValuesDebug values={[values, errors, touched]} />
                        </Form>
                    )}
                </Formik >
            }
        </>
    )
}

export default BunkeringComponent