import React, { useState } from 'react';
import { CardBody, Card, Col, Row, Input, Label, Button, Form, Modal, FormFeedback, FormGroup } from "reactstrap";
import apiGlobal from '../../global/api.global';
import { useFormik } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { useSelector } from 'react-redux';
import { RootState } from '../..';
import { loadSensorOptions } from '../../VesselMaster/vesselMaster.hooks';
import { successToast } from '../../Components/Toasts';
import { customStyle } from '../../shared/CommonCSS';

const SensorsMaster = () => {
    type vesselSensorListType = {
        vessel_sensor_name: any;
        sensor_name: any;
        vessel_name: any;
    }
    const [vesselSensors] = useState<Array<any>>([]);
    const [sensorList, setSensorList] = useState<Array<any>>([{ vessel_sensor_name: "" }])
    const [combinedSensors] = useState<Array<any>>([])
    const [vesselSensorList] = useState<Array<vesselSensorListType>>([])
    const [modal_xlarge, setmodal_xlarge] = useState(false);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [data, setData] = useState({
        vessel_sensor_name: '',
        vessel_sensor_short_name: '',
        sensor_name: '',
        vessel_sensor_description: '',
    })

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            vessel_sensor_name: '',
            sensor_name: '',
        },
        validationSchema: Yup.object({
            vessel_sensor_name: Yup.string().matches(/^[A-Za-z ]{1,50}$/, "Please enter upto 50 alphabets only").required("Vessel sensor name is required")
        }),
        onSubmit: (values) => {
        }
    })

    function handle_Vessel_Sensor_Textbox_Changes(e: any, index: number) {
        combinedSensors.splice(index, 1)
        combinedSensors.splice(index, 0, e.target.value)
    }

    function handle_Sensor_Select(e: any) {
        let newdata: any = { ...data }
        let value = "";
        value = e.id;
        newdata.sensor_name = value;
        setData(newdata)
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_xlarge() {
        setmodal_xlarge(!modal_xlarge);
        removeBodyCss();
    }

    function handle_Add_Sensor() {
        setSensorList([...sensorList, { vessel_machinery_name: "" }])
    }

    const Sensor_Master_Submit = (e: any) => {
        for (let i = 0; i < combinedSensors.length; i++) {
            vesselSensorList.splice(i, 0, {
                vessel_sensor_name: combinedSensors[i], sensor_name: data.sensor_name, vessel_name: VesselID
            })
        }
        apiGlobal.post(`/vessel_sensor_list/`, vesselSensorList).then(res => {
            successToast("Data saved successfully!");
        })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            <React.Fragment>
                <div className='ele_row'>
                    <h4 className='mb-3'>Vessel Sensor List</h4>
                    <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-3"
                        data-toggle="modal"
                        data-target=".bs-example-modal-xl"
                        onClick={() => {
                            tog_xlarge();
                        }}
                    >
                        <i className="dripicons-plus font-size-16 align-middle me-2"></i>
                        Add New
                    </button>
                    <Modal
                        size="xl"
                        isOpen={modal_xlarge}
                        toggle={() => {
                            tog_xlarge();
                        }}
                    >
                        <div className="modal-header">
                            <h5
                                className="modal-title mt-0"
                                id="myExtraLargeModalLabel"
                            >
                                Add New
                            </h5>
                            <button
                                onClick={() => {
                                    setmodal_xlarge(false);
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Card>
                                <CardBody>
                                    <Form autoComplete="off"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            Sensor_Master_Submit(e)
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <div className='pos-center'>
                                                <FormGroup className="form-floating mb-3">
                                                    <div className="mb-3 ele_row1">
                                                        <Label className='asteric'>Select sensor</Label>
                                                        <AsyncSelect
                                                            name="sensor_name"
                                                            cacheOptions
                                                            defaultOptions
                                                            loadOptions={loadSensorOptions}
                                                            getOptionLabel={(e: any) => e.sensor_name}
                                                            getOptionValue={e => e.id}
                                                            onChange={handle_Sensor_Select}
                                                            className='select-height w-5 ml-2'
                                                            menuPortalTarget={document.querySelector('.MuiDialog-root')}
                                                            styles={customStyle}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </Row>
                                        {sensorList.map((sensor: any, index: number) => (
                                            <Row key={index}>
                                                <Col lg={5}>
                                                    <Row>
                                                        <Col lg={11}>
                                                            <div className="form-floating mb-3">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="sensor_name"
                                                                    name="sensor"
                                                                    placeholder='example'
                                                                    onBlur={(e: any) => handle_Vessel_Sensor_Textbox_Changes(e, index)}
                                                                    invalid={
                                                                        validation.touched.vessel_sensor_name && validation.errors.vessel_sensor_name ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.vessel_sensor_name && validation.errors.vessel_sensor_name ? (
                                                                    <FormFeedback type="invalid">{validation.errors.vessel_sensor_name}</FormFeedback>
                                                                ) : null}
                                                                <Label className="asteric" for="vessel_sensor_name">Vessel sensor name</Label>
                                                            </div>
                                                        </Col>
                                                        {sensorList.length - 1 === index &&
                                                            <Col lg={1}>
                                                                <button type='button' className="btn btn-primary waves-effect waves-light mb-3" onClick={handle_Add_Sensor}><i className="dripicons-plus font-size-16 align-middle"></i>
                                                                </button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Row>
                                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                                <Button type="submit" color="primary" className='btn_size4_5_cstm'>Save</Button>
                                                <Button type="reset" color="danger" className='btn_size4_5_cstm'>Reset</Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Modal>
                </div>
                <div className="table-responsive mb-5">
                    <table className="table">
                        <thead className="table-light">
                            <tr>
                                <th>Sr. No.</th>
                                <th>Vessel sensor name</th>
                                <th>Sensor</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vesselSensors.length > 0 ?
                                vesselSensors.map((vesselSensor: any, index: number) => {
                                    return (
                                        <tr key={vesselSensor.id}>
                                            <td>{index + 1}</td>
                                            <td>{vesselSensor.vessel_sensor_name}</td>
                                            <td>{vesselSensor.sensor}</td>
                                            <td><i className='dripicons-document-edit'></i></td>
                                        </tr>
                                    )
                                })
                                : <tr><td colSpan={4}>Please add sensor to this vessel</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        </>
    )
}

export default SensorsMaster;