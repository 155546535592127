import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import { errorToast, successToast } from 'Components/Toasts';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { errResponse } from 'GenericForms/Helper';
import { loadOtherDetailsObject } from 'VesselMaster/vesselMaster.hooks';
import env from 'environment/env';
import { Field, FieldProps, Formik, Form } from 'formik';
import apiGlobal from 'global/api.global';
import React from 'react';
import { useQuery } from 'react-query';
import { Row, Col, Input, Label, Button, CardBody, CardFooter, CardHeader, Card } from 'reactstrap';
import { ReportingTypeConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from "yup";


interface OtherDetailsComponentType {
    toggleAddTab: any,
    activeAddTab: number,
    toggleTab: any,
    activeTab: number,
    VesselID: number,
    VoyageID: number,
    ReportID: number,
    record: any,
    vessel: any
}

const OtherDetailsComponent = ({
    toggleAddTab,
    activeAddTab,
    toggleTab,
    activeTab,
    VesselID,
    VoyageID,
    ReportID,
    record,
    vessel
}: OtherDetailsComponentType) => {
    let cargoContainer: string = '';

    /** Queries */
    /** Other Details Object used for edit */
    const { data: OtherDetailsObject, isLoading: OtherDetailsObjectLoading, isError: OtherDetailsObjectError } = useQuery(
        [queryKeyes.vessel.OtherDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadOtherDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Assign values to initial object of Other Details */
    const getInitialOtherDetailsValues = () => {
        if (OtherDetailsObject && OtherDetailsObject.length > 0) {
            return OtherDetailsObject[0];
        } else {
            return ({
                if_cargo_damaged_or_contaminated_during_port_stay: false,
                reason_for_cargo_damage_or_contamination_port: '',
                if_cargo_damaged_or_contaminated_since_last_departure: false,
                reason_for_cargo_damage_or_contamination_departure: '',
                time_remaining_to_next_port: 0,
                closest_distance_from_storm_center: 0,
                time_of_cpa: 0,
                vessel_name: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
        }
    }

    /** Other details' formik object */
    const OtherDetailsFormik = {
        initialValues: getInitialOtherDetailsValues(),
        validationSchema: Yup.object({
            time_remaining_to_next_port: Yup.number().nullable(),
            reason_for_cargo_damage_or_contamination_port: Yup.string().nullable(),
            closest_distance_from_storm_center: Yup.string().nullable(),
            time_of_cpa: Yup.string().nullable(),
        }),
    }
    if (vessel.vessel_type === 'Container') {
        cargoContainer = 'container';
    } else {
        cargoContainer = 'cargo';
    }

    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='px-0 py-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Other Details</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {OtherDetailsObjectLoading && <Loading message='Loading required data!' />}
            {OtherDetailsObjectError && getInitialOtherDetailsValues()}
            {!OtherDetailsObjectLoading &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                            }
                        }
                        if (OtherDetailsObject && OtherDetailsObject.length > 0) {
                            apiGlobal.put(`/lfonds_other_details/${values.id}/`, values).then(res =>
                                handleResponse(res)).catch(err => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                })
                        } else {
                            apiGlobal.post(`/lfonds_other_details/`, values).then(res =>
                                handleResponse(res)).catch(err => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                })
                        }
                    }}
                    initialValues={OtherDetailsFormik.initialValues}
                    validationSchema={OtherDetailsFormik.validationSchema}
                >
                    {({ values, errors, handleSubmit, handleChange, setErrors, touched, handleBlur }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any, setErrors: any, touched: any, handleBlur: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='mt-2 engineer-card-body'>
                                <Row className='mb-2'>
                                    {record && record?.reporting_type === "In Port" ?
                                        <>
                                            <Col lg={3}>
                                                <Label>Is {cargoContainer} damaged/contaminated during port stay?</Label>
                                            </Col>
                                            <Col lg={3}>
                                                <Field name={'if_cargo_damaged_or_contaminated_during_port_stay'}>
                                                    {({ field, form }: FieldProps) => (
                                                        <div className="square-switch sqswitch mt-3">
                                                            <Input
                                                                type="checkbox"
                                                                switch="none"
                                                                name={field.name}
                                                                id={field.name}
                                                                onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)}
                                                                defaultChecked={values && values?.if_cargo_damaged_or_contaminated_during_port_stay}
                                                            />
                                                            <Label
                                                                htmlFor={field.name}
                                                                data-on-label='Yes'
                                                                data-off-label='No'>
                                                            </Label>
                                                        </div>
                                                    )}
                                                </Field>
                                            </Col>
                                            {values && values?.if_cargo_damaged_or_contaminated_during_port_stay === true &&
                                                <Col lg={3}>
                                                    <Label className='mb-0'>Reason for cargo damage/contamination</Label>
                                                    <Field
                                                        as="textarea"
                                                        name="reason_for_cargo_damage_or_contamination_port"
                                                        className="form-control mb-0"
                                                        rows={4}
                                                    />
                                                </Col>
                                            }
                                        </> :
                                        <>
                                            <Col lg={3} className='px-0'>
                                                <Label >Is {cargoContainer} damaged/contaminated since last departure?</Label>
                                            </Col>
                                            <Col lg={3}>
                                                <Field name={'if_cargo_damaged_or_contaminated_since_last_departure'}>
                                                    {({ field, form }: FieldProps) => (
                                                        <div className="square-switch sqswitch mt-3">
                                                            <Input
                                                                type="checkbox"
                                                                switch="none"
                                                                name={field.name}
                                                                id={field.name}
                                                                onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)}
                                                                defaultChecked={values && values?.if_cargo_damaged_or_contaminated_since_last_departure}
                                                            />
                                                            <Label
                                                                htmlFor={field.name}
                                                                data-on-label='Yes'
                                                                data-off-label='No'>
                                                            </Label>
                                                        </div>
                                                    )}
                                                </Field>
                                            </Col>
                                            {values && values?.if_cargo_damaged_or_contaminated_since_last_departure === true &&
                                                <Col lg={3}>
                                                    <Label className='mb-0'>Reason for cargo damage/contamination</Label>
                                                    <Field
                                                        as="textarea"
                                                        name="reason_for_cargo_damage_or_contamination_port"
                                                        className="form-control"
                                                        rows={4}
                                                    />
                                                </Col>
                                            }
                                        </>
                                    }
                                </Row>
                                <Row>
                                    <Col lg={3} className='ps-0'>
                                        <div className="mb-3">
                                            <Label className='asteric mb-0' for="time_remaining_to_next_port">
                                                Remaining time to next port
                                                <i
                                                    className='bx bx-info-circle ml-2p'
                                                    id='time_remaining_to_next_port_msg'>
                                                </i>
                                            </Label>
                                            <div className='input-group'>
                                                <Field name='time_remaining_to_next_port'>
                                                    {() => (
                                                        <Input
                                                            type="text"
                                                            className="form-control text-right"
                                                            id="time_remaining_to_next_port"
                                                            name="time_remaining_to_next_port"
                                                            onBlur={handleBlur}
                                                            onChange={(e: any) => handleChange(e)}
                                                            defaultValue={values?.time_remaining_to_next_port}
                                                        />
                                                    )}
                                                </Field>
                                                <div className='input-group-text round_border'>hr</div>
                                                {errors?.time_remaining_to_next_port && touched?.time_remaining_to_next_port && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={`time_remaining_to_next_port`}
                                                        message={errors?.time_remaining_to_next_port}
                                                        open={errors?.time_remaining_to_next_port ? true : false}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} className='px-0'>
                                        <div className="mb-3">
                                            <Label className='mb-0' for="closest_distance_from_storm_center">
                                                Distance to closest storm centre
                                                <i
                                                    className='bx bx-info-circle ml-2p'
                                                    id='closest_distance_from_storm_center_msg'>
                                                </i>
                                            </Label>
                                            <div className='input-group'>
                                                <Field
                                                    type="text"
                                                    className="form-control text-right"
                                                    id="closest_distance_from_storm_center"
                                                    name="closest_distance_from_storm_center"
                                                />
                                                <div className='input-group-text round_border'>nm</div>
                                                {errors?.closest_distance_from_storm_center && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={`closest_distance_from_storm_center`}
                                                        message={errors?.closest_distance_from_storm_center}
                                                        open={errors?.closest_distance_from_storm_center ? true : false}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="mb-3">
                                            <Label className='mb-0' for="time_of_cpa">
                                                Time of CPA
                                                <i
                                                    className='bx bx-info-circle ml-2p'
                                                    id='time_of_cpa_msg'>
                                                </i>
                                            </Label>
                                            <div className='input-group'>
                                                <Field
                                                    type="text"
                                                    className="form-control text-right"
                                                    id="time_of_cpa"
                                                    name="time_of_cpa"
                                                />
                                                <div className='input-group-text round_border'>hr</div>
                                                {errors?.time_of_cpa && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={`time_of_cpa`}
                                                        message={errors?.time_of_cpa}
                                                        open={errors?.time_of_cpa ? true : false}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2 py-3'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                            toggleTab(activeTab + 1);
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                            setErrors({});
                                            record && record?.reporting_type === ReportingTypeConstant.ATSEA ?
                                                toggleTab(activeTab - 1) : toggleAddTab(activeAddTab - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, OtherDetailsFormik.initialValues, touched]} />
                        </Form>
                    )}
                </Formik >
            }
        </Card>
    )
}

export default OtherDetailsComponent