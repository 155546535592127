import { ReactElement, StrictMode } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from './react-query/queryClient';
import { ToastContainer } from 'react-toastify';
import env from './environment/env';
import { BrowserRouter as BRouter } from 'react-router-dom';
import { HashRouter as HRouter } from 'react-router-dom';
//import { HashRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import { configureStore } from './Store';
import React from 'react';
import ErrorBoundary from 'ErrorBoundary';
import ErrorComponent from 'Components/ErrorComponent';


const fallbackRender = (error: any, resetErrorBoundary: any) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = configureStore({});

env?.logger && Sentry.init({
  dsn: "https://efe987f58a00fdf1cda9a7193bc84258@o4507105884045312.ingest.us.sentry.io/4507105962426368",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

root.render(
  <StrictMode>
    <ErrorBoundary
      FallbackComponent={fallbackRender}
      onError={() =>
        <ErrorComponent message="Error occured! Redirecting..." />
      }
    >
      <QueryClientProvider client={queryClient}>
        <Provider store={configureStore({})}>
          {
            env.desktop_app === true ?
              <HRouter basename={env.base_name}>
                <App />
              </HRouter>
              : <BRouter basename={env.base_name}>
                <App />
              </BRouter>
          }
        </Provider>
        <ToastContainer />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>
);
