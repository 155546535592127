import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Input, Label, Button, Form } from "reactstrap";
import AsyncSelect from 'react-select/async';
import apiGlobal from '../global/api.global';
import * as Yup from "yup";
import { Field, FieldProps, Formik } from "formik";
import '../global/GlobalCSS.css';
import Layout from '../HorizontalMenu/Menu';
import Navbar from '../HorizontalMenu/Navbar';
import VesselDetailsHeader from '../Components/VesselDetailsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { VesselState, getVesselsAction, setVesselState } from "../Store/Generic/ReportingSlice";
import VoyageInformation from './VoyageInformation';
import { errorToast, successToast } from '../Components/Toasts';
import { PortConstant, VoyageConstant } from 'shared/constants';
import { loadVoyageObject } from 'VesselMaster/vesselMaster.hooks';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { dataTimeFormat, searchCountries, searchPorts } from 'GenericForms/Helper';
import Loading from 'Components/Loading';
import { queryClient } from 'react-query/queryClient';
import env from 'environment/env';
import ErrorTooltip from 'Components/ErrorTooltip';

const CreateVoyage = (voyageId?: any) => {
    /** State variables */
    const dispatch = useDispatch();
    const { VesselState, VesselID } = useSelector(
        (state: any) => state.Reporting
    );
    const [otherPort, setOtherPort] = useState(false);
    /** State variables end */

    /** useQueries */
    /** Voyage object used for edit */
    const { data: VoyageObject, isLoading: VoyageObjectLoading, isError: VoyageObjectError } = useQuery(
        [queryKeyes.vessel.VoyageObject.key, voyageId.voyageId],
        async () => {
            return await loadVoyageObject(voyageId.voyageId);
        },
        { staleTime: Infinity }
    )
    /** useQueries end */

    /** useEffect start */
    useEffect(() => {
        dispatch(getVesselsAction('vessel_master' as string));
    }, [dispatch]);
    /** useEffect end */

    /** Assigning initial object to voyage's formik object */
    const getInitialValues = () => {
        if (VoyageObject && VoyageObject?.id > 0) {
            return VoyageObject;
        } else {
            return ({
                voyage_number: "",
                voyage_status: VoyageConstant.NEWVOYAGE,
                departure_date_time: null,
                departure_port: null,
                other_port_name: "",
                other_country_name: null,
                vessel_name: VesselID
            })
        }
    }

    /** Voyage Information formik object */
    const VoyageFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            voyage_number: Yup.string().matches(
                /^[A-Za-z0-9-/]{1,50}$/, "Please enter upto 50 alphabets/numbers only"
            ).required(commonValidationMessages.required),
            departure_port: Yup.string().required(commonValidationMessages.required),
            departure_date_time: Yup.string().required(commonValidationMessages.required),
        })
    }

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    /** POST request for Voyage Information */
    const postVoyage = (values: any) => {
        apiGlobal.post(`/voyage_information/`, values).then(res => {
            if (res.status === 201) {
                successToast("Data saved successfully!");
                queryClient.invalidateQueries(queryKeyes.vessel.VoyageObject.key);
                handleVesselState('VOYAGE_REPORTING');
            }
        })
            .catch(err => {
                errorToast(err.response.data.error[0]);
            });
    }

    /** PUT request for Voyage Information */
    const putVoyage = (values: any) => {
        apiGlobal.put(`/voyage_information/${values?.id}/`, values).then(res => {
            if (res.status === 200) {
                successToast("Data saved successfully!");
                queryClient.invalidateQueries(queryKeyes.vessel.VoyageObject.key);
                handleVesselState('VOYAGE_REPORTING');
            }
        })
            .catch(err => {
                errorToast(err.response.data.error[0]);
            });
    }

    /** Voyage submit function */
    const voyageSubmit = (values: any) => {
        let obj: any = {
            port_name: values?.other_port_name,
            country_name: values?.other_country_name
        }
        if (VoyageObject && VoyageObject?.id > 0) {
            if (otherPort === true) {
                apiGlobal.post('port_master/', obj).then(portMaster => {
                    if (portMaster.status === 201) {
                        values.departure_port = portMaster.data?.id;
                        putVoyage(values);
                    }
                })
            } else {
                putVoyage(values);
            }
        } else {
            if (otherPort === true) {
                apiGlobal.post('port_master/', obj).then(portMaster => {
                    if (portMaster.status === 201) {
                        values.departure_port = portMaster.data?.id;
                        postVoyage(values);
                    }
                })
            } else {
                postVoyage(values);
            }
        }
    }

    const customStyle = {
        control: (styles: any) => ({
            ...styles,
            borderColor: '#858484cc'
        })
    }

    return (
        <>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={2}>
                            <button
                                color='primary'
                                className='btn btn-primary mb-3'
                                onClick={() => {
                                    handleVesselState('VOYAGE_REPORTING');
                                }}>
                                <i className="bx bx-chevron-left me-1" />
                                Back
                            </button>
                        </Col>
                        <Col sm={10}>
                            <VesselDetailsHeader />
                        </Col>
                    </Row>
                    <div id='owner_table'>
                        <h4 className='mb-3'>Voyage Reporting</h4>
                        {VoyageObjectLoading && <Loading message="Loading required data!" />}
                        {VoyageObjectError && getInitialValues()}
                        {!VoyageObjectLoading &&
                            <Formik
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    voyageSubmit(values);
                                }}
                                initialValues={VoyageFormik.initialValues}
                                validationSchema={VoyageFormik.validationSchema}
                            >
                                {(props: any) => (
                                    <Form onSubmit={props?.handleSubmit} autoComplete='off' noValidate>
                                        <Row>
                                            <Col sm={3}>
                                                <Label for="voyage_number" className='required_field asteric mb-0'>Voyage number</Label>
                                                <Field
                                                    type="text"
                                                    className="form-control"
                                                    id="voyage_number"
                                                    name="voyage_number"
                                                />
                                                {props?.errors?.voyage_number && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={'voyage_number'}
                                                        message={props?.errors?.voyage_number}
                                                        open={props?.errors?.voyage_number ? true : false}
                                                    />
                                                }
                                            </Col>
                                            <Col sm={3}>
                                                <Label className='asteric mb-0' for='departure_port'>Depature port name</Label>
                                                <Field name='departure_port'>
                                                    {({ field, form }: FieldProps) => (
                                                        <AsyncSelect
                                                            name={field.name}
                                                            inputId={'departure_port'}
                                                            cacheOptions
                                                            defaultOptions
                                                            loadOptions={(e: any) => searchPorts(e)}
                                                            getOptionLabel={(e: any) => e.port_name}
                                                            getOptionValue={(e: any) => e.id}
                                                            onChange={(e: any) => {
                                                                form.setFieldValue(field.name, e?.id);
                                                                if (e?.precedence_id === PortConstant.OTHER) {
                                                                    setOtherPort(true);
                                                                } else {
                                                                    setOtherPort(false);
                                                                }
                                                            }}
                                                            defaultValue={{
                                                                id: props?.values?.departure_port,
                                                                port_name: props?.values?.departure_port_name
                                                            }}
                                                            menuPortalTarget={document.body}
                                                            styles={customStyle}
                                                            noOptionsMessage={(e: any) => {
                                                                if (e?.inputValue?.toString()?.length > 2) {
                                                                    return 'Please select the Other option and enter the port name in the textbox provided'
                                                                }
                                                                return 'Please enter the first 3 characters of port name';
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                {props?.errors?.departure_port && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={'departure_port'}
                                                        message={props?.errors?.departure_port}
                                                        open={props?.errors?.departure_port ? true : false}
                                                    />
                                                }
                                            </Col>
                                            <Col sm={3}>
                                                <Label className='asteric mb-0' for='departure_date_time'>Departure date & time(UTC)</Label><br />
                                                <Field name='departure_date_time'>
                                                    {({ field }: FieldProps) => (
                                                        <Input
                                                            type="datetime-local"
                                                            id='departure_date_time'
                                                            name={field.name}
                                                            onChange={(e: any) => props?.handleChange(e)}
                                                            className='datetimepicker text-uppercase mt-0 max-width-15'
                                                            defaultValue={dataTimeFormat(props?.values?.departure_date_time, false, true)}
                                                        />
                                                    )}
                                                </Field>
                                                {props?.errors?.departure_date_time && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={'departure_date_time'}
                                                        message={props?.errors?.departure_date_time}
                                                        open={props?.errors?.departure_date_time ? true : false}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                        {otherPort === true &&
                                            <Row>
                                                <Col sm={3}>
                                                    <Label for="other_port_name" className='mb-0'>Other port name</Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control mb-3"
                                                        id="other_port_name"
                                                        name="other_port_name"
                                                    />
                                                </Col>
                                                <Col sm={3}>
                                                    <Label for="other_country_name" className='mb-0'>Other country name</Label>
                                                    <Field name='other_country_name'>
                                                        {({ field, form }: FieldProps) => (
                                                            <AsyncSelect
                                                                name={field.name}
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={(e: any) => searchCountries(e)}
                                                                getOptionLabel={(e: any) => e.country_name}
                                                                getOptionValue={(e: any) => e.id}
                                                                onChange={(e: any) => {
                                                                    form.setFieldValue(field.name, e?.id);
                                                                }}
                                                                menuPortalTarget={document.body}
                                                                styles={customStyle}
                                                                noOptionsMessage={(e: any) => {
                                                                    return 'Please enter the first 3 characters of country name';
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                                <Button type="submit" color="primary" className='btn_size4_5_cstm'>Save</Button>
                                                <Button type="reset" color="danger" className='btn_size4_5_cstm' onClick={() => {
                                                    props?.resetForm();
                                                }}>Reset</Button>
                                            </div>
                                        </Row>
                                        <FormValuesDebug values={[props?.values, props?.errors, VoyageFormik.initialValues]} />
                                    </Form>
                                )}
                            </Formik>
                        }
                    </div>
                </Container>
            </div>
            {VesselState === 'VOYAGE_REPORTING' && <VoyageInformation />}
        </>
    )
}

export default CreateVoyage