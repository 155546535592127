import React, { useEffect } from 'react';
import { Col, Row, Button, Card, CardBody, Form, CardFooter, Input } from "reactstrap";
import { useSelector } from 'react-redux';
import { RootState } from '../../';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadFuelSettings, loadMachineryFuelGroup, loadVesselFuelTypes } from 'VesselMaster/vesselMaster.hooks';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';

const Fuels = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    /** Queries */
    /** Load Vessel Fuel Types */
    const { data: FuelTypes, isLoading: FuelTypesLoading, isError: FuelTypesError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.vesselFuelType.key, VesselID],
            async () => { return await loadVesselFuelTypes(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /** Load FC machinery */
    const { data: VesselMachinery, isLoading: VesselMachineryLoading, isError: VesselMachineryError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.MachinaryFuelGroup.key, VesselID],
            async () => { return await loadMachineryFuelGroup(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /** Load Fuel setting */
    const { data: FuelSetting, isLoading: FuelSettingLoading, isError: FuelSettingError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.FuelSettings.key, VesselID],
            async () => { return await loadFuelSettings(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /** Assign initial values to fuel consumptiom formik object */
    const getFuelInitialValues = () => {
        let FuelConsumption: any[] = [];
        if (FuelSetting && FuelSetting[0]?.id > 0) {
            return FuelSetting;
        } else if (FuelConsumption !== undefined &&
            VesselMachinery !== undefined &&
            FuelTypes !== undefined &&
            FuelConsumption?.length <= VesselMachinery?.length * FuelTypes?.length) {
            VesselMachinery.flatMap((machine: any, index: number) => {
                FuelTypes.map((fuel: any) => {
                    FuelConsumption.push({
                        vessel_name: VesselID,
                        vessel_machinery_fc_group: machine.id,
                        vessel_machinery_fc_group_name: machine.machinery_name,
                        fuel_name: fuel.fuel_type,
                    });
                    return fuel;
                });
                return machine;
            });
            return FuelConsumption;
        }
    }

    /** General Settings Formik Object */
    const FuelFormik = {
        initialValues: {
            fuel: getFuelInitialValues()
        },
        validationSchema: {
        }
    }

    /** useEffect */
    useEffect(() => {
        FuelFormik.initialValues.fuel = getFuelInitialValues();
    }, [FuelTypes, VesselMachinery, FuelSetting])
    /** useEffect end */

    return (
        <>
            <Card className='border-0'>
                {(FuelTypesLoading || VesselMachineryLoading || FuelSettingLoading) && <Loading message="Loading required data!" />}
                {(FuelTypesError || VesselMachineryError) && <ErrorComponent message="Error loading required data!" />}
                {FuelSettingError && getFuelInitialValues()}
                {!(FuelTypesLoading || VesselMachineryLoading || FuelSettingLoading) &&
                    !(FuelTypesError || VesselMachineryError) &&
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false)
                            if (FuelSetting.length > 0) {
                                values.fuel.map((val: any) => {
                                    apiGlobal.put(`/vessel_fuel_setting/${val.id}/`, val)
                                        .then(res => {
                                            if (res.status === 200) {
                                                successToast("Data saved successfully!");
                                            }
                                        })
                                        .catch(err => {
                                            if (errResponse.includes(err?.response?.status)) {
                                                errorToast("Internal error occured, please contact the admin");
                                            }
                                        });
                                    return '';
                                })
                            } else {
                                apiGlobal.post(`/vessel_fuel_setting/`, values.fuel)
                                    .then(res => {
                                        if (res.status === 201) {
                                            successToast("Data saved successfully!");
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    })
                            }


                        }}
                        initialValues={FuelFormik.initialValues}
                    >
                        {props => (
                            <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                                <CardBody className='p-0'>
                                    <Row>
                                        <Col md={12} className='px-0'>
                                            <div className="table-responsive p-0">
                                                <table className="table mb-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className='p-2 align-middle sr-no-width'>#</th>
                                                            <th className='p-2 align-middle'>Machinery name</th>
                                                            {FuelTypes.length > 0 &&
                                                                FuelTypes.map((fuel_name: any, index: number) => {
                                                                    return (
                                                                        <th key={index} className='p-2 align-middle text-center' style={{ width: '18%' }}>{fuel_name.fuel_type_name}</th>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name='fuel'>
                                                            {() => (
                                                                <>
                                                                    {
                                                                        props?.values &&
                                                                        props?.values?.fuel?.map((vesselMachinery: any, index: number) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                                    <td className='p-2 align-middle'>{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                                                                    {FuelTypes.length > 0 &&
                                                                                        FuelTypes.map((fuelType: any, fuelIndex: number) => {
                                                                                            const fieldName = `fuel.${index}.selected`;
                                                                                            return (
                                                                                                <td key={fuelIndex} className='p-2 align-middle text-center'>
                                                                                                    <Field name={fieldName}>
                                                                                                        {({ field }: FieldProps) => (
                                                                                                            <Input
                                                                                                                type='checkbox'
                                                                                                                id={`${vesselMachinery.id}_${fuelType.id}`}
                                                                                                                name={field.name}
                                                                                                                defaultChecked={vesselMachinery.selected || false}
                                                                                                                onChange={(e: any) => {
                                                                                                                    props.handleChange(e);
                                                                                                                }}
                                                                                                                defaultValue={props.values?.fuel[index]?.fuelType}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                </td>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className='p-2 py-3 mb-3'>
                                    <Button type="submit" color='primary' className='justify_right'>Save</Button>
                                </CardFooter>
                                <Row className='mt-2'>
                                    <FormValuesDebug values={[props?.values, props?.errors, FuelFormik.initialValues]} />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }
            </Card>
        </>
    )

}
export default Fuels;
